import React, { useState } from "react";
import { Box, TextField } from "@material-ui/core";
import {
  MobileAutocompleteOptions,
  ActionButton,
  InfoIcon,
  Icon,
  IconName,
  RecentlySearchedList,
} from "halifax";
import clsx from "clsx";

import "./styles.scss";
import * as textConstants from "./textConstants";
import { LocationSearchConnectorProps } from "./container";
import * as H from "history";
import { PATH_HOME } from "../../../../../../utils/paths";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RecentHotelSearch } from "redmond";
export interface ILocationSearchProps extends LocationSearchConnectorProps {
  onComplete?: (value?: any) => void;
  onEditDates?: boolean;
  history: H.History;
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
}
export const LocationSearch = (props: ILocationSearchProps) => {
  const {
    location,
    loading,
    onComplete,
    setLocation,
    fetchLocationCategories,
    locationCategories,
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    recentSearches,
    onRecentSearchClick,
  } = props;

  const [locationSearchString, setLocationSearchString] = useState<string>(
    location?.label || ""
  );
  const [labelToDisplay, setLabelToDisplay] = useState<string>(
    textConstants.LOCATION_SEARCH_PREFIX
  );

  React.useEffect(() => {
    setLocationSearchString(location?.label ?? "");
  }, [location]);

  const onLocationSearch = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    hasLocationAutocompleteError && setLocationAutocompleteError(false);

    setLocation(null);

    setLocationSearchString(event.target.value);
    if (event.target.value) {
      fetchLocationCategories(event.target.value);
    }
  };

  const onLocationSelected = (value: any) => {
    setLocationSearchString(value.label);
    setLocation(value);
    onContinue(value);
  };

  const onContinue = (value?: any) => {
    if (onComplete) {
      onComplete(value);
    }
  };

  const handleFocus = () => {
    setLabelToDisplay(textConstants.WHERE_TO);
  };

  const handleBlur = () => {
    setLabelToDisplay(textConstants.LOCATION_SEARCH_PREFIX);
  };

  const onClearLocationField = () => {
    setLocation(null);
    setLocationSearchString("");
    fetchLocationCategories("");
  };

  return (
    <Box className="mobile-hotel-search-location redesign-v3">
      <Box className="mobile-location-pickers">
        <Box className="mobile-hotel-search-location-input-field">
          <Icon name={IconName.B2BMapPin} />
          <TextField
            className={clsx("origin-auto-complete", "mobile")}
            onChange={(value) => onLocationSearch(value)}
            value={locationSearchString}
            label={labelToDisplay}
            InputProps={{
              disableUnderline: true,
            }}
            onFocus={handleFocus}
            onBlur={!locationSearchString ? handleBlur : undefined}
          />
          {/* Note: Instead of conditionally rendering this icon component, a className of 'hidden' is conditionally used so it can take the style "visibility:hidden"
            This style will allocate space for the icon even when it's not shown - this prevents the sibling elements from changing when it's shown or hidden */}
          <FontAwesomeIcon
            className={clsx("icon", { hidden: !locationSearchString })}
            icon={faTimesCircle}
            onClick={onClearLocationField}
          />
        </Box>
        {hasLocationAutocompleteError && (
          <Box className="autocomplete-error-container">
            <InfoIcon />
            <span className="autocomplete-error-text">
              Error fetching results.{" "}
            </span>
            <span
              onClick={() => {
                history.push(PATH_HOME);
                hasLocationAutocompleteError &&
                  setLocationAutocompleteError(false);
              }}
              className="try-again-text"
            >
              Try again.
            </span>
          </Box>
        )}
      </Box>

      <MobileAutocompleteOptions
        value={null}
        valueCategories={locationCategories}
        setValue={(value: any) => onLocationSelected(value)}
        loading={loading}
        loadingText={textConstants.LOADING_TEXT}
      />
      {!loading &&
        locationSearchString.length === 0 &&
        (locationCategories.length === 0 ||
          locationCategories[0]?.results.length === 0) &&
        !!recentSearches &&
        recentSearches.length > 0 &&
        !!onRecentSearchClick && (
          <div
            className={clsx("mobile-recently-searched-hotels-container", {
              "has-results": locationCategories.length > 0,
            })}
          >
            <RecentlySearchedList
              recentSearches={recentSearches}
              onRecentSearchClick={(recentSearch) =>
                onRecentSearchClick(recentSearch as RecentHotelSearch)
              }
            />
          </div>
        )}
      {!!location && (
        <ActionButton
          className="mobile-autocomplete-continue-button"
          message={textConstants.CONTINUE}
          onClick={() => {
            onContinue(location);
          }}
        />
      )}
    </Box>
  );
};
