import React from "react";
import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import { ActionLink, Icon, IconName } from "halifax";
import styles from "./styles.module.scss";
import { HomeRoomsAndBeds as VRRoomsAndBeds } from "redmond";
export interface VacationRentalRoomsAndBedsSelectionProps {
  className?: string;
  vrRoomsAndBeds?: VRRoomsAndBeds;
  setVrRoomsAndBeds: (vrRoomsAndBeds?: VRRoomsAndBeds) => void;
  variant: "filter-modal" | "filter-dropdown-popover";
}

export const VacationRentalRoomsAndBedsSelection = ({
  className,
  vrRoomsAndBeds,
  setVrRoomsAndBeds,
  variant,
}: VacationRentalRoomsAndBedsSelectionProps) => {
  const bedrooms = vrRoomsAndBeds?.numberOfBedrooms || 0;
  const beds = vrRoomsAndBeds?.numberOfBeds || 0;
  const bathrooms = vrRoomsAndBeds?.numberOfBathrooms || 0;

  const setBedrooms = (bedrooms: number) => {
    setVrRoomsAndBeds({
      ...vrRoomsAndBeds,
      numberOfBedrooms: bedrooms,
    });
  };

  const setBeds = (beds: number) => {
    setVrRoomsAndBeds({
      ...vrRoomsAndBeds,
      numberOfBeds: beds,
    });
  };

  const setBathooms = (bathrooms: number) => {
    setVrRoomsAndBeds({
      ...vrRoomsAndBeds,
      numberOfBathrooms: bathrooms,
    });
  };

  return (
    <Box className={clsx(styles["container"], className)}>
      {variant === "filter-modal" && (
        <Box className={styles["header-container"]}>
          <Icon name={IconName.RoomBedFilter} /> Rooms and beds
        </Box>
      )}
      <Box className={styles["selection-container"]}>
        <Box className={styles["selection-row"]}>
          <Typography className={styles["selection-label"]}>
            Bedrooms
          </Typography>
          <CounterControl
            count={bedrooms}
            setCount={setBedrooms}
            label="bedrooms"
            maximum={10}
          />
        </Box>
        <Box className={styles["selection-row"]}>
          <Typography className={styles["selection-label"]}>Beds</Typography>
          <CounterControl
            count={beds}
            setCount={setBeds}
            label="beds"
            maximum={10}
          />
        </Box>
        <Box className={styles["selection-row"]}>
          <Typography className={styles["selection-label"]}>
            Bathrooms
          </Typography>
          <CounterControl
            count={bathrooms}
            setCount={setBathooms}
            label="bathrooms"
            maximum={10}
          />
        </Box>
      </Box>
    </Box>
  );
};

interface CounterControlProps {
  count: number;
  setCount: (count: number) => void;
  incrementAmount?: number;
  label: string;
  maximum: number;
}

const CounterControl = ({
  count,
  setCount,
  label,
  maximum,
  incrementAmount = 1,
}: CounterControlProps) => {
  const isMinusDisabled = count <= 0;
  const isPlusDisabled = count >= maximum;

  return (
    <Box className={styles["counter-control-container"]}>
      <ActionLink
        label={`Decrease ${label}`}
        className={styles["counter-button"]}
        onClick={() => {
          if (!isMinusDisabled) {
            setCount(count - incrementAmount);
          }
        }}
        content={
          <Box
            className={clsx(styles["counter-icon-container"], {
              [styles["counter-icon-container-disabled"]]: isMinusDisabled,
            })}
          >
            <Icon
              name={IconName.MinusIcon}
              className={clsx(styles["counter-icon"], {
                [styles["counter-icon-disabled"]]: isMinusDisabled,
              })}
            />
          </Box>
        }
      />
      <Box className={styles["count-value"]}>{count <= 0 ? "Any" : count}</Box>
      <ActionLink
        label={`Increase ${label}`}
        className={styles["counter-button"]}
        onClick={() => {
          if (!isPlusDisabled) {
            setCount(count + incrementAmount);
          }
        }}
        content={
          <Box
            className={clsx(styles["counter-icon-container"], {
              [styles["counter-icon-container-disabled"]]: isPlusDisabled,
            })}
          >
            <Icon
              name={IconName.PlusIcon}
              className={clsx(styles["counter-icon"], {
                [styles["counter-icon-disabled"]]: isPlusDisabled,
              })}
            />
          </Box>
        }
      />
    </Box>
  );
};
