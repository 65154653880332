import React, { useEffect, useState, useMemo } from "react";
import {
  DesktopPopupModal,
  IconName,
  StarRatingsSelection,
  useDeviceTypes,
  HotelPriceSelection,
  FreeCancelFilter,
  ActionLink,
  ActionButton,
  MobileFloatingButton,
  MobilePopoverCard,
  CloseButtonIcon,
  //   HotelAvailabilitySort,
  IHotelAvailabilitySortBaseProps,
} from "halifax";
import clsx from "clsx";
import { Box, Divider, Typography } from "@material-ui/core";
import { VacationRentalAmenitiesSelection } from "./components/VacationRentalAmenitiesSelection";
import { VacationRentalRoomsAndBedsSelection } from "./components/VacationRentalRoomsAndBedsSelection";
import {
  HotelStarRatingEnum,
  IPriceRange,
  HomeRoomsAndBeds,
  VacationRentalAmenityKindEnum as VRAmenity,
} from "redmond";
import { StayType } from "redmond/hotels-module/interfaces";
import isEqual from "lodash-es/isEqual";

export interface VacationRentalAvailabilityFilterProps
  extends IHotelAvailabilitySortBaseProps {
  open: boolean;
  onClose: () => void;
  readyToReset: boolean;
  // Star ratings filter
  starRatings: HotelStarRatingEnum[];
  setStarRatings: (starRatings: HotelStarRatingEnum[]) => void;
  // Amenities filter
  vrAmenities: VRAmenity[];
  setVrAmenitiesFilter: (amenities: VRAmenity[]) => void;
  // Rooms and Beds filter
  vrRoomsAndBeds?: HomeRoomsAndBeds;
  setVrRoomsAndBedsFilter: (roomsAndBeds?: HomeRoomsAndBeds) => void;
  // Price filter
  currencySymbol: string;
  maxPrice: number;
  minMaxPriceRange: IPriceRange | null;
  setMaxPriceFilter: (value: number) => void;
  // Free cancellation filter
  hasFreeCancelFilter: boolean;
  setFreeCancelFilter: (freeCancel: boolean) => void;
}

export const VacationRentalAvailabilityFilter = ({
  open,
  onClose,
  readyToReset,
  starRatings,
  vrAmenities,
  setVrAmenitiesFilter,
  setStarRatings,
  vrRoomsAndBeds,
  setVrRoomsAndBedsFilter,
  currencySymbol,
  maxPrice,
  minMaxPriceRange,
  setMaxPriceFilter,
  hasFreeCancelFilter,
  setFreeCancelFilter,
}: //   sortOptions,
//   selectedSortOption,
//   setSelectedSortOption,
VacationRentalAvailabilityFilterProps) => {
  const { matchesMobile } = useDeviceTypes();

  const [curAmenities, setCurAmenities] = useState<VRAmenity[]>(vrAmenities);

  const [currRoomsAndBeds, setCurrRoomsAndBeds] = useState<
    HomeRoomsAndBeds | undefined
  >(vrRoomsAndBeds);

  const [curStarRatings, setCurStarRatings] =
    useState<HotelStarRatingEnum[]>(starRatings);

  const [curMaxPrice, setCurMaxPrice] = useState<number>(maxPrice);

  const [curHasFreeCancelFilter, setCurHasFreeCancelFilter] =
    useState<boolean>(hasFreeCancelFilter);

  useEffect(() => {
    setCurAmenities(vrAmenities);
  }, [vrAmenities, open]);

  useEffect(() => {
    setCurrRoomsAndBeds(vrRoomsAndBeds);
  }, [vrRoomsAndBeds, open]);

  useEffect(() => {
    setCurStarRatings(starRatings);
  }, [starRatings, open]);

  useEffect(() => {
    setCurMaxPrice(maxPrice);
  }, [maxPrice, open]);

  useEffect(() => {
    setCurHasFreeCancelFilter(hasFreeCancelFilter);
  }, [hasFreeCancelFilter, open]);

  const handleApply = () => {
    setVrAmenitiesFilter(curAmenities);
    if (!isEqual(starRatings, curStarRatings)) {
      setStarRatings(curStarRatings);
    }
    setMaxPriceFilter(curMaxPrice);
    setFreeCancelFilter(curHasFreeCancelFilter);
    setVrRoomsAndBedsFilter(currRoomsAndBeds);
  };

  const handleReset = () => {
    setCurAmenities([]);
    setCurStarRatings([]);
    setCurMaxPrice(0);
    setCurHasFreeCancelFilter(false);
  };

  const optionsChanged = useMemo(
    () =>
      !isEqual(vrAmenities, curAmenities) ||
      !isEqual(starRatings, curStarRatings) ||
      maxPrice !== curMaxPrice ||
      hasFreeCancelFilter !== curHasFreeCancelFilter,
    [
      vrAmenities,
      curAmenities,
      starRatings,
      curStarRatings,
      maxPrice,
      curMaxPrice,
      hasFreeCancelFilter,
      curHasFreeCancelFilter,
    ]
  );

  const renderFilterButtons = () => (
    <Box
      className={clsx("hotel-availability-filter-bottom-buttons-container", {
        mobile: matchesMobile,
      })}
    >
      {!matchesMobile && (
        <>
          <ActionLink
            className={clsx("reset-filters-link", "filter-button", {
              inactive: !readyToReset && !optionsChanged,
            })}
            onClick={handleReset}
            content={"Clear all"}
          />
          <ActionButton
            className={clsx("apply-filters-button", "filter-button")}
            onClick={() => {
              handleApply();
              onClose();
            }}
            defaultStyle="h4r-primary"
            disabled={!optionsChanged}
            message={"Apply filters"}
          />
        </>
      )}
      {matchesMobile && (
        <MobileFloatingButton
          className="apply-filters-floating-button"
          wrapperClassName="apply-filters-floating-button-container"
          disabled={!optionsChanged}
          onClick={() => {
            handleApply();
            onClose();
          }}
        >
          Apply Filters
        </MobileFloatingButton>
      )}
    </Box>
  );

  const filterContent = (
    <>
      <StarRatingsSelection
        className="left-area"
        title={"Vacation rental rating"}
        starRatings={curStarRatings}
        setStarRatings={setCurStarRatings}
        icon={IconName.StarOutline}
        isMobile={matchesMobile}
      />
      <Divider />
      <VacationRentalAmenitiesSelection
        isMobile={matchesMobile}
        variant="filter-modal"
        vrAmenities={curAmenities}
        setVrAmenities={setCurAmenities}
      />
      <Divider />
      <VacationRentalRoomsAndBedsSelection
        vrRoomsAndBeds={currRoomsAndBeds}
        setVrRoomsAndBeds={setCurrRoomsAndBeds}
        variant="filter-modal"
      />
      <Divider />
      <HotelPriceSelection
        className="desktop-selection-content"
        title="Max price"
        subtitle="Per night"
        currencySymbol={currencySymbol}
        type="max"
        alwaysShowTooltip
        hotelPriceRange={minMaxPriceRange || { min: 0, max: 0 }}
        reset={() => setCurMaxPrice(0)}
        hotelMaxPrice={curMaxPrice}
        setHotelMaxPrice={setCurMaxPrice}
        showMinLabel
        showClearButton={curMaxPrice !== 0}
        icon={IconName.MoneyOutlineThin}
      />
      <Divider />
      <FreeCancelFilter
        icon={IconName.NotAllowedSign}
        className="desktop-selection-content"
        title="Free cancellation"
        hasFreeCancelFilter={curHasFreeCancelFilter}
        setFreeCancelFilter={setCurHasFreeCancelFilter}
        stayTypes={[StayType.Homes]}
      />
    </>
  );

  return (
    <>
      {matchesMobile && (
        <MobilePopoverCard
          open={open}
          onClose={onClose}
          fullScreen
          className={clsx("mobile-hotel-availability-filter-root")}
          contentClassName="mobile-hotel-availability-filter-content-wrapper"
          bottomButton={renderFilterButtons()}
          topRightButton={
            <ActionLink
              className="filter-close-button"
              content={<CloseButtonIcon />}
              label="Close"
              onClick={onClose}
            />
          }
          topLeftButton={
            readyToReset ? (
              <ActionLink content="Clear All Filters" onClick={handleReset} />
            ) : undefined
          }
        >
          <Box className="mobile-hotel-availability-filter-content-container">
            <>
              {/* 
                TODO: Fix typing for sortOptions
                <HotelAvailabilitySort
                sortOptions={sortOptions}
                selectedSortOption={selectedSortOption}
                setSelectedSortOption={setSelectedSortOption}
                title={"Sort by"}
                icon={IconName.Sort}
              /> */}
              <Divider />
              {filterContent}
            </>
          </Box>
        </MobilePopoverCard>
      )}
      {!matchesMobile && (
        <DesktopPopupModal
          open={open}
          className={clsx("desktop-hotel-availability-filter-root")}
          contentClassName="desktop-hotel-availability-filter-content-wrapper"
          onClose={onClose}
          bottomButton={renderFilterButtons()}
          invisibleBackdrop={false}
        >
          <Box className="desktop-hotel-availability-filter-content-root">
            <Box className="desktop-hotel-availability-filter-content-container">
              <>
                <Typography variant="h2" className="filters-title">
                  Vacation rental filters
                </Typography>
                <hr />
                {filterContent}
              </>
            </Box>
          </Box>
        </DesktopPopupModal>
      )}
    </>
  );
};
