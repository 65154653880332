import { actions } from "../actions";
import { CallState } from "redmond";
import {
  FETCH_HOTEL_SHOP,
  SET_HOTEL_SHOP_RESULTS,
  SELECT_ROOM_TYPE,
  ACKNOWLEDGE_HOTEL_SHOP_FAILURE,
  SELECT_LODGING,
  RESET_LODGING,
  SET_DATE_RANGE,
  SET_HOTEL_SHOP_CALL_STATE_FAILED,
  SET_HOTEL_SHOP_PROGRESS,
  SET_SIMILAR_HOTELS,
  SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE,
  SET_DISTANCE_FROM_LOCATION,
  FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  SET_DISTANCE_FROM_LOCATION_CATEGORIES,
  SET_HOTEL_SHOP_ENTRY_POINT,
  FETCH_NEARBY_LODGINGS,
  SET_NEARBY_LODGINGS,
  SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE,
  SET_SELECTED_ROOM_ID,
  SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES,
  SET_HOTEL_DETAILS_ENTRY_SOURCE,
  SET_CACHE_LODGING_TOKEN_RESPONSE,
  SET_HOTEL_DETAILS_SORT_ORDER,
} from "../actions/constants";
import { HotelShopCallState, HotelShopStep, IHotelShopState } from "./state";

const initialState: IHotelShopState = {
  selectedLodging: null,
  dateRange: null,
  roomInfoProducts: [],
  hotelShopTrackingPropertiesV2: null,
  chosenRoomInfoIndex: null,
  chosenProductIndex: null,
  hotelShopCallState: HotelShopCallState.NotCalled,
  hotelShopCallError: null,
  progress: HotelShopStep.HotelInfo,
  similarHotels: [],
  similarHotelsAvailabilityCallState: CallState.NotCalled,
  similarHotelsAvailabilityTrackingProperties: null,
  nearbyLodgings: [],
  nearbyLodgingsAvailabilityCallState: CallState.NotCalled,
  distanceFromLocation: null,
  distanceFromLocationCategories: [],
  distanceFromLocationCategoriesLoading: null,
  cancellationSummary: null,
  sortOrder: undefined,
};

export const reducer = (
  state: IHotelShopState = initialState,
  action: actions.HotelShopActions
): IHotelShopState => {
  switch (action.type) {
    case FETCH_HOTEL_SHOP:
      return {
        ...state,
        roomInfoProducts: [],
        hotelShopCallState: HotelShopCallState.InProcess,
        hotelShopCallError: null,
      };

    case SET_HOTEL_SHOP_RESULTS:
      const {
        roomInfoProducts,
        opaqueCfarQuotesRequest,
        trackingPropertiesV2,
        cancellationSummary,
      } = action.payload ?? {};

      return {
        ...state,
        chosenRoomInfoIndex: null,
        chosenProductIndex: null,
        hotelShopCallState: action.hotelShopCallState,
        hotelShopCallError: action.hotelShopCallError,
        roomInfoProducts: roomInfoProducts ?? state.roomInfoProducts,
        hotelShopTrackingPropertiesV2:
          trackingPropertiesV2 ?? state.hotelShopTrackingPropertiesV2,
        hotelOpaqueCfarQuotesRequest: opaqueCfarQuotesRequest ?? null,
        cancellationSummary: cancellationSummary || null,
      };

    case SET_HOTEL_SHOP_CALL_STATE_FAILED:
      return {
        ...state,
        hotelShopCallState: HotelShopCallState.Failed,
        hotelShopCallError: action.hotelShopCallError,
      };

    case SELECT_ROOM_TYPE:
      return {
        ...state,
        chosenRoomInfoIndex: action.roomInfoIndex,
        chosenProductIndex: action.productIndex,
        chosenRateId: action.rateId,
      };

    case SELECT_LODGING:
      return {
        ...state,
        selectedLodging: action.lodging,
      };

    case RESET_LODGING:
      return {
        ...state,
        selectedLodging: null,
      };

    case SET_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };

    case ACKNOWLEDGE_HOTEL_SHOP_FAILURE:
      return {
        ...state,
        hotelShopCallState: HotelShopCallState.FailureAcknowledged,
      };
    case SET_HOTEL_SHOP_PROGRESS: {
      return {
        ...state,
        progress: action.progress,
      };
    }
    case SET_SIMILAR_HOTELS: {
      return {
        ...state,
        similarHotels: action.hotels,
        similarHotelsAvailabilityCallState: CallState.Success,
        similarHotelsAvailabilityTrackingProperties: action.trackingProperties,
      };
    }
    case SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE: {
      return {
        ...state,
        similarHotelsAvailabilityCallState: action.callState,
      };
    }
    case FETCH_NEARBY_LODGINGS: {
      return {
        ...state,
        nearbyLodgingsAvailabilityCallState: CallState.InProcess,
      };
    }
    case SET_NEARBY_LODGINGS: {
      return {
        ...state,
        nearbyLodgings: action.hotels,
        nearbyLodgingsAvailabilityCallState: CallState.Success,
      };
    }
    case SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE: {
      return {
        ...state,
        nearbyLodgingsAvailabilityCallState: action.callState,
      };
    }
    case SET_DISTANCE_FROM_LOCATION:
      const { location } = action;

      return {
        ...state,
        distanceFromLocation: location ? { ...location } : null,
        distanceFromLocationCategoriesLoading: false,
      };

    case SET_DISTANCE_FROM_LOCATION_CATEGORIES:
      const { categories } = action;
      return {
        ...state,
        distanceFromLocationCategories: categories,
        distanceFromLocationCategoriesLoading: false,
      };

    case FETCH_DISTANCE_FROM_LOCATION_CATEGORIES:
      return {
        ...state,
        distanceFromLocationCategories: [],
        distanceFromLocationCategoriesLoading: true,
      };
    case SET_HOTEL_SHOP_ENTRY_POINT:
      return { ...state, entryPoint: action.entryPoint };
    case SET_HOTEL_DETAILS_ENTRY_SOURCE:
      return {
        ...state,
        hotelDetailsEntrySource: action.hotelDetailsEntrySource,
      };
    case SET_SELECTED_ROOM_ID:
      return { ...state, selectedRoomId: action.selectedRoomId };
    case SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES:
      return {
        ...state,
        recommendedBasedOnPreferences: action.recommendedBasedOnPreferences,
      };
    case SET_CACHE_LODGING_TOKEN_RESPONSE:
      return {
        ...state,
        cacheLodgingTokenResponse: action.lodgingTokenResponse,
      };
    case SET_HOTEL_DETAILS_SORT_ORDER:
      return {
        ...state,
        sortOrder: action.sortOrder,
      };
    default:
      return { ...state };
  }
};

export * from "./selectors";
