import React, { useEffect } from "react";
import clsx from "clsx";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@material-ui/core";
import { ActionLink, Icon, IconName } from "halifax";
import { VacationRentalAmenityKindEnum as VRAmenityKind } from "redmond";
import styles from "./styles.module.scss";
import { VRAmenityDisplayNames } from "../../textConstants";

export interface VacationRentalAmenitiesSelectionProps {
  isMobile: boolean;
  className?: string;
  vrAmenities: VRAmenityKind[];
  setVrAmenities: (vrAmenities: VRAmenityKind[]) => void;
  variant: "filter-modal" | "filter-dropdown-popover";
}

export const VacationRentalAmenitiesSelection = ({
  isMobile,
  className,
  vrAmenities,
  setVrAmenities,
  variant = "filter-modal",
}: VacationRentalAmenitiesSelectionProps) => {
  const [showMoreLessVisible, setShowMoreLessVisible] =
    React.useState<boolean>(false);
  const [showMore, setShowMore] = React.useState<boolean>(false);

  useEffect(() => {
    setShowMoreLessVisible(variant === "filter-modal");
    if (variant === "filter-dropdown-popover") {
      setShowMore(true);
    }
  }, [variant]);

  useEffect(() => {
    if (variant === "filter-modal" && vrAmenities.length > 0) {
      setShowMore(true);
    }
  }, [vrAmenities]);

  const isChecked = (amenityKind: VRAmenityKind): boolean => {
    return vrAmenities.includes(amenityKind);
  };

  const handleChange = (amenityKind: VRAmenityKind) => {
    if (isChecked(amenityKind)) {
      const selectedAfterRemove = vrAmenities.filter(
        (selectedAmenityKind) => selectedAmenityKind !== amenityKind
      );
      setVrAmenities(selectedAfterRemove);
    } else {
      const newSelected = [...vrAmenities, amenityKind];
      setVrAmenities(newSelected);
    }
  };

  const amenitySectionsToRender = showMore
    ? amenitySections
    : amenitySections.slice(0, 1);

  return (
    <Box
      className={clsx(
        styles["container"],
        { [styles["mobile"]]: isMobile },
        className
      )}
    >
      <Box>
        {variant === "filter-modal" && (
          <Box className="header-container">
            <Icon name={IconName.KnifeFork} /> Vacation rental amenities
          </Box>
        )}
        <Box
          className={clsx(styles["selection-container"], {
            [styles["filter-dropdown-popover"]]:
              variant === "filter-dropdown-popover",
          })}
        >
          <>
            {amenitySectionsToRender.map((section) => (
              <AmenitySection
                label={section.label}
                amenities={section.amenities}
                isChecked={isChecked}
                onChange={(amenityKind) => {
                  handleChange(amenityKind);
                }}
              />
            ))}
          </>
          {showMoreLessVisible && (
            <ActionLink
              className={styles["show-more-button"]}
              onClick={() => setShowMore(!showMore)}
              content={showMore ? "View less" : `View more amenities`}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

interface AmenitySectionProps {
  label: string;
  amenities: VRAmenityKind[];
  isChecked: (amenity: VRAmenityKind) => boolean;
  onChange: (amenity: VRAmenityKind) => void;
}

const AmenitySection = ({
  label,
  amenities,
  isChecked,
  onChange,
}: AmenitySectionProps) => {
  return (
    <Box>
      <Typography
        style={{
          fontSize: "12px",
          fontWeight: 600,
          marginBottom: "16px",
        }}
      >
        {label}
      </Typography>
      <FormGroup className={styles["amenity-group"]}>
        {amenities.map((amenity) => (
          <FormControlLabel
            className={styles["amenity-control-label"]}
            label={VRAmenityDisplayNames[amenity] || amenity}
            control={
              <Checkbox
                className={styles["checkbox"]}
                value={amenity}
                checked={isChecked(amenity)}
                onChange={() => {
                  onChange(amenity);
                }}
              />
            }
          />
        ))}
      </FormGroup>
    </Box>
  );
};
const popularAmenities: VRAmenityKind[] = [
  VRAmenityKind.Pool,
  VRAmenityKind.Dishwasher,
  VRAmenityKind.Parking,
  VRAmenityKind.Wifi,
  VRAmenityKind.AirConditioning,
  VRAmenityKind.PetFriendly,
];

const essentialAmenities: VRAmenityKind[] = [
  VRAmenityKind.Kitchen,
  VRAmenityKind.Dryer,
  VRAmenityKind.Heating,
  VRAmenityKind.DedicatedWorkspace,
  VRAmenityKind.Hdtv,
  VRAmenityKind.HairDryer,
  VRAmenityKind.Iron,
];

const featuresAmenities: VRAmenityKind[] = [
  VRAmenityKind.HotTub,
  VRAmenityKind.EvCharger,
  VRAmenityKind.Crib,
  VRAmenityKind.Gym,
  VRAmenityKind.BarbecueGrill,
  VRAmenityKind.Fireplace,
];

const amenitySections = [
  { label: "Popular", amenities: popularAmenities },
  { label: "Essentials", amenities: essentialAmenities },
  { label: "Features", amenities: featuresAmenities },
];
