import { IStoreState } from "../../../../reducers/types";
import { createSelector } from "reselect";
import dayjs from "dayjs";
import {
  CalendarPriceBucket,
  IResult,
} from "redmond";

export const getLocation = (state: IStoreState): IResult | null =>
  state.hotelSearch.location;

export const getLocationCategories = (state: IStoreState) =>
  state.hotelSearch.locationCategories;

export const getLocationCategoriesLoading = (state: IStoreState) =>
  state.hotelSearch.locationCategoriesLoading;

export const getFromDate = (state: IStoreState) => state.hotelSearch.fromDate;

export const getUntilDate = (state: IStoreState) => state.hotelSearch.untilDate;

export const getAdultsCount = (state: IStoreState) =>
  state.hotelSearch.adultsCount;

export const getChildrenCount = (state: IStoreState) =>
  state.hotelSearch.children.length;

export const getRoomsCount = (state: IStoreState) =>
  state.hotelSearch.roomsCount;

export const getPetsCount = (state: IStoreState) => state.hotelSearch.petsCount;

export const getChildren = (state: IStoreState) => state.hotelSearch.children;

export const getNightCount = createSelector(
  getFromDate,
  getUntilDate,
  (fromDate, untilDate): number | null => {
    if (fromDate && untilDate) {
      return dayjs(untilDate).diff(dayjs(fromDate), "day");
    } else {
      return null;
    }
  }
);

export const getApplyUserHotelPreferences = (state: IStoreState) =>
  state.hotelSearch.applyUserHotelPreferences;

export const getHasLocationAutocompleteError = (state: IStoreState) =>
  state.hotelSearch.hasLocationAutocompleteError;

export const getCalendarPriceBuckets = (state: IStoreState) =>
  state.hotelSearch.calendarPriceBuckets;

export const getCalendarPriceRangeLegends = createSelector(
  [getCalendarPriceBuckets],
  (calendarPriceBuckets) => {
    const legends = calendarPriceBuckets
      ? calendarPriceBuckets.map((bucket: CalendarPriceBucket) =>
          bucket.legend.toString()
        )
      : [];
    return legends;
  }
);

export const getCalendarPriceMonths = (state: IStoreState) =>
  state.hotelSearch.calendarPriceMonths;
