import { isCorpTenant } from "@capone/common";
import { Tenant } from "redmond";

export const INPUT_LABEL = (adults: number, children: number[]) => {
  const numTravelers = adults + children.length;
  return numTravelers === 1
    ? `${numTravelers} Traveler`
    : `${numTravelers} Travelers`;
};

export const INPUT_LABEL_WITH_ROOMS = (
  adults: number,
  children: number[],
  rooms: number,
  showTotalTravelers?: boolean
) => {
  const numTravelers = adults + children.length;
  const adultsCount = adults === 1 ? `${adults} Adult` : `${adults} Adults`;
  const childrenCount =
    children.length === 1
      ? `${children.length} Child`
      : `${children.length} Children`;
  const roomCount = rooms === 1 ? `${rooms} Room` : `${rooms} Rooms`;
  if (showTotalTravelers) {
    return numTravelers === 1
      ? `<span class="adults-count">${numTravelers} Traveler,  ${roomCount}</span>`
      : `<span class="adults-count">${numTravelers} Travelers,  ${roomCount}</span>`;
  }
  return `<span class="adults-count">${adultsCount}</span><span class="children-count">${childrenCount}</span><span class="rooms-count"> ${roomCount}</span>`;
};

export const MODAL_TITLES = {
  modalTitle: "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  childrenSubtitle: "",
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
};

export const MODAL_TITLES_WITH_ROOMS = (
  tenant: Tenant,
  maximumRooms: number
) => ({
  modalTitle: isCorpTenant(tenant)
    ? "Edit travelers and rooms"
    : "Edit Travelers",
  adultTitle: "Adults",
  adultSubtitle: "",
  childrenTitle: "Children",
  childrenSubtitle: "Under 18",
  roomsTitle: "Rooms",
  roomsSubtitle: `Max ${maximumRooms}`,
  childAgeInputSubtitle:
    "To ensure the most accurate rates, please enter the exact age of the children on this booking.",
});

export const PETS_TITLE = "Traveling with pets?";
export const PETS_SUBTITLE = "Additional fee may apply";
export const PETS_TOOLTIP =
  "If you’re traveling with a pet, you need to book a pet-friendly stay. Adding a pet will display only pet-friendly stays in your search.<br/> <br/>If you’d like to bring more than 1 pet, please discuss with your stay after booking. Service animals do not count as pets—you don’t need to add them to your guest count, and you can inform your stay provider after booking.";
export const PETS_MODAL_HEADER = "Pets";
