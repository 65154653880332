import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Typography, useScrollTrigger } from "@material-ui/core";
import clsx from "clsx";
import dayjs from "dayjs";
import {
  ActionLink,
  CloseButtonIcon,
  Icon,
  IconName,
  MobileFloatingButton,
  MobilePopoverCard,
} from "halifax";
import queryStringParser from "query-string";
import React, { useEffect, useMemo } from "react";
import { RouteComponentProps } from "react-router";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  CONTROL,
  GLOBAL_MOBILE_NAV_EXPERIMENT,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import {
  MonthAndDatePicker,
  MonthAndDatePickerType,
} from "../../../search/components/HotelSearchControl/components/MonthAndDatePicker";
import { HotelShopStep } from "../../reducer/state";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { shouldTrackLodgingLocation } from "../../utils/shouldTrackLodgingLocation";
import { MobileShopHeaderConnectorProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";

export interface IMobileShopHeaderProps
  extends RouteComponentProps,
    MobileShopHeaderConnectorProps {
  onClickBackButton?: () => void;
  title?: string;
  // note: the value for `variant` cannot change, because it can affect hooks at runtime
  variant?: "custom" | "shop";
}

export const MobileShopHeader = (props: IMobileShopHeaderProps) => {
  const {
    title,
    fromDate,
    untilDate,
    adultsCount,
    children,
    selectedLodging,
    fetchHotelShop,
    setFromDate,
    setUntilDate,
    history,
    hotelShopStep,
    setHotelShopProgress,
    onClickBackButton,
    variant = "shop",
    roomsCount,
    petsCount,
  } = props;
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [hasChangedDates, setHasChangedDates] = React.useState(false);
  const [from, setFrom] = React.useState<Date | null>(fromDate);
  const [until, setUntil] = React.useState<Date | null>(untilDate);

  const expState = useExperiments();

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_RECENTLY_VIEWED_HOTELS,
    MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const globalMobileNavExperimentVariant = getExperimentVariant(
    expState.experiments,
    GLOBAL_MOBILE_NAV_EXPERIMENT
  );
  const isGlobalMobileNavExperiment = React.useMemo(
    () => globalMobileNavExperimentVariant === AVAILABLE,
    [globalMobileNavExperimentVariant]
  );

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const scrollTrigger = useScrollTrigger({ disableHysteresis: true });

  const onClose = () => setOpenModal(false);

  const returnToAvailability = () => {
    goToAvailability({
      history,
      lodging: selectedLodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      roomsCount,
      petsCount,
    });
  };

  if (variant === "shop") {
    useEffect(() => {
      setFrom(fromDate);
    }, [fromDate]);

    useEffect(() => {
      setUntil(untilDate);
    }, [untilDate]);

    useEffect(() => {
      const differentDates = from !== fromDate || until !== untilDate;
      const setBothDates = fromDate && untilDate;

      setHasChangedDates(differentDates && !!setBothDates);
    }, [fromDate, untilDate, from, until]);
  }

  const defaultOnClickBackButton = useMemo(() => {
    const { recommended } = queryStringParser.parse(history?.location?.search);

    if (hotelShopStep === HotelShopStep.ChooseRoom) {
      return () => setHotelShopProgress(HotelShopStep.HotelInfo);
    }

    if (recommended) {
      return () => history.goBack();
    }

    return returnToAvailability;
  }, [hotelShopStep]);

  const MiddleSection = () => {
    switch (variant) {
      case "custom":
        return (
          <Box className={clsx("title-section", "header-middle-section")}>
            <Typography variant="body1" className="title">
              {title}
            </Typography>
          </Box>
        );
      case "shop":
      default:
        return (
          <>
            <Box
              className={clsx(
                "locations-and-dates-section",
                "header-middle-section"
              )}
            >
              {hotelShopStep === HotelShopStep.HotelInfo ? (
                <>
                  <Typography variant="body1" className="locations-section">
                    {selectedLodging?.lodging.name}
                  </Typography>
                  <Box className="dates-and-occupancy-count-section">
                    <Typography variant="body2" className="dates-section">
                      {fromDate &&
                        untilDate &&
                        `${dayjs(fromDate).format("MMM DD")} - ${dayjs(
                          untilDate
                        ).format("MMM DD")}, `}
                      {adultsCount + children.length > 1
                        ? `${adultsCount + children.length} Travelers`
                        : `${adultsCount + children.length} Traveler`}
                    </Typography>
                  </Box>
                </>
              ) : (
                textConstants.CHOOSE_ROOM
              )}
            </Box>
            {hotelShopStep === HotelShopStep.HotelInfo && (
              <Box className="edit-location-and-date-button-section">
                <ActionLink
                  className="edit-location-and-date-button"
                  onClick={() => {
                    setOpenModal(true);
                  }}
                  content={
                    <Icon name={IconName.B2BEditPencil} className="edit-icon" />
                  }
                />
              </Box>
            )}
          </>
        );
    }
  };

  return (
    <>
      <Box
        className={clsx("hotel-shop-progress-header-root", {
          "global-mobile-nav": isGlobalMobileNavExperiment,
          scrolled: scrollTrigger,
        })}
      >
        <Box className="hotel-shop-progress-header-container">
          <Box className="go-back-button-section">
            <ActionLink
              className={clsx("go-back-button")}
              onClick={
                variant === "shop"
                  ? defaultOnClickBackButton
                  : onClickBackButton
              }
              content={<FontAwesomeIcon icon={faChevronLeft} />}
            />
          </Box>
          <MiddleSection />
        </Box>
      </Box>
      {variant === "shop" && (
        <MobilePopoverCard
          open={openModal}
          onClose={onClose}
          fullScreen={true}
          className="mobile-hotel-shop-header-root"
          contentClassName="mobile-shop-header-content-wrapper"
          headerElement={
            <Box className="modal-header-container">
              <Typography className="header-title">
                {textConstants.CHOOSE_DATES}
              </Typography>
              <ActionLink
                className="shop-header-close-button"
                content={<CloseButtonIcon />}
                label="Close"
                onClick={onClose}
              />
            </Box>
          }
        >
          <Box className="mobile-shop-header-content-container">
            <Box className="hotel-mobile-calendar-picker-root">
              <MonthAndDatePicker
                viewType={MonthAndDatePickerType.Column}
                from={from}
                until={until}
                setFromDate={setFrom}
                setUntilDate={setUntil}
                className="b2b"
              />
              {hasChangedDates && (
                <MobileFloatingButton
                  className="mobile-calendar-picker-search-button"
                  disabled={!(!!from && !!until)}
                  onClick={() => {
                    setFromDate(from);
                    setUntilDate(until);
                    fetchHotelShop(history, {
                      forceCallHotelAvailability: true,
                      fetchSimilarHotels: similarHotelsVariant !== CONTROL,
                      includeLocationSearchTerm:
                        isRecentlyViewedV1Experiment &&
                        recentlyViewedMobileP0Variant !== CONTROL &&
                        shouldTrackLodgingLocation(history.location.search),
                      fetchLodgingToken: cacheHotelTokenEnabled,
                    });
                    setOpenModal(false);
                  }}
                  wrapperClassName="b2b"
                >
                  Search dates
                </MobileFloatingButton>
              )}
            </Box>
          </Box>
        </MobilePopoverCard>
      )}
    </>
  );
};
