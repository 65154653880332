import React from "react";

export const fetchingAvailabilityText = (isStays: boolean) =>
  isStays ? "for stays" : "for hotels";
export const fetchingAvailabilitySecondaryText = (isStays: boolean) => {
  return `We match your ${
    isStays ? "stay" : "hotel"
  } prices before and after you book so you don't overpay.`;
};

export const CORP_FETCHING_AVAILABILITY_SECONDARY_TEXT =
  "With the right room, work travel doesn't have to feel like work.";
export const PARADISE_SUBTITLE =
  "Book with flexibility and start your next journey here.";

export const VIEWING_TEXT = (
  locationName: string,
  isLodging?: boolean,
  isSearchTermPoint?: boolean,
  isMapView?: boolean,
  isStays?: boolean
) =>
  `Viewing ${isStays ? "stays" : "hotels"} ${
    isMapView
      ? `in map area`
      : `${isLodging || isSearchTermPoint ? `near` : `in`} ${locationName}`
  }`;
export const DATES_TEXT = (fromDate: string, untilDate: string) =>
  `${fromDate} - ${untilDate}`;

export const EARN_ENHANCEMENT_SUBTITLE = (
  hotelsEarnRate: string | number,
  homesEarnRate: string | number,
  productDisplayName: string,
  currency: string
) => {
  const multiplier = currency.includes("cash") ? "%" : "X";
  let earnEnhancementTitle;

  if (hotelsEarnRate == homesEarnRate) {
    earnEnhancementTitle = (
      <>
        <span className="font-bold">
          Earn {hotelsEarnRate}
          {multiplier} {currency} on stays
        </span>{" "}
        with your {productDisplayName} account.
      </>
    );
  } else if (hotelsEarnRate && homesEarnRate) {
    earnEnhancementTitle = (
      <>
        <span className="font-bold">
          Earn {hotelsEarnRate}
          {multiplier} {currency} on hotels
        </span>{" "}
        and{" "}
        <span className="font-bold">
          {homesEarnRate}
          {multiplier} {currency} on vacation rentals{" "}
        </span>
        with your {productDisplayName} account.
      </>
    );
  } else {
    earnEnhancementTitle = (
      <>
        <span className="font-bold">
          Earn {hotelsEarnRate}
          {multiplier} {currency} on hotels
        </span>{" "}
        with your {productDisplayName} account.
      </>
    );
  }

  return earnEnhancementTitle;
};

export const TRAVEL_SALES_EVENT_ACTIVE_SUBTITLE =
  "Save up to $200 on select hotels with our first-ever travel sale.";
export const TRAVEL_SALES_EVENT_ACTIVE_CTA = "Explore destinations on sale";
