import { HotelAvailabilitySortOption } from "../../reducer/state";

export const CHANGE_LOCATION = "Change Location";

export const RECOMMENDED_TEXT = "Recommended";
export const RATING_TEXT = "Star Rating (High to Low)";
export const PRICE_PER_NIGHT_TEXT = "Price (Low to High)";

export const sortOptionLabel: {
  [key in HotelAvailabilitySortOption]: string;
} = {
  Recommended: RECOMMENDED_TEXT,
  StarRating: RATING_TEXT,
  Pricing: PRICE_PER_NIGHT_TEXT,
};

export const PRICE_FILTER_TITLE = "Max Price";
export const PRICE_FILTER_SUBTITLE = "Per Night";

export const FILTER_MODAL_TITLES = {
  resetFiltersText: "Clear All Filters",
  applyFiltersText: (count: number) =>
    `Apply Filters${count ? ` (${count})` : ""}`,
  applyText: "Apply",
  amenitiesText: "All Amenities",
  starRatingsText: "Star Rating",
  premiumStaysTitleText: "Premium stays",
  lifestyleStaysTitleText: "Lifestyle Collection",
  priceRangeText: PRICE_FILTER_TITLE,
  priceRangeSubtitleText: PRICE_FILTER_SUBTITLE,
  hotelNameTitleText: "Hotel Name",
  cancellationTitleText: "Cancellation",
  hotelsOnSaleTitleText: "Limited-time promotions",
  hotelNameSubTitleText:
    "Looking for a specific hotel? Enter the name of the hotel you’d like to stay at in this area.",
  hotelNamePlaceholderText: "What hotel are you looking for?",
  sortText: "Sort by",
  policyText: "Policy Status",
  loyaltyProgramsText: "Loyalty Programs",
  stayTypeFiltersText: "Property Type",
  mealPlanTypeFiltersText: "Meal Plan",
};
