import { Box } from "@material-ui/core";
import {
  HotelShopRoomTypePickerEnum,
  HotelShopRoomTypePickerVariant,
  RefundableRoomDetails,
  TrackingEventControlType,
  useDeviceTypes,
  useTrackingEventControl,
} from "halifax";
import queryStringParser from "query-string";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { RouteComponentProps } from "react-router-dom";

import dayjs from "dayjs";
import {
  CallState,
  CfarQuoteResultEnum,
  HotelBookType,
  QuoteId,
  RoomInfoProductsWithTransformedIndexes,
  RoomProductType,
  RoomProductVariant,
  RoomProductWithTransformedIndexes,
  SELECT_HOTEL_ROOM,
  VIEWED_HOTEL_DETAILS,
  getNthNightTrackingProperty,
  getSelectHotelRoomHotelCfarOfferFacts,
  getViewedHotelDetailsHotelCfarOfferFacts,
} from "redmond";
import { ClientContext } from "../../App";
import { trackEvent } from "../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  CONTROL,
  DESKTOP_RECENTLY_VIEWED_HOTELS,
  DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS,
  MOBILE_RECENTLY_VIEWED_HOTELS,
  MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS,
  RECENTLY_VIEWED_MASTER_V1,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../context/experiments";
import { PORTAL_TITLE, SELECT_ROOM_TITLE } from "../../lang/textConstants";
import { CfarDetails } from "../ancillary/components";
import { DesktopShop } from "./components/DesktopShop";
import { MobileShop } from "./components/MobileShop";
import { HotelShopConnectorProps } from "./container";
import { HotelShopCallState } from "./reducer/state";
import "./styles.scss";
import { handlePageRedirectOnSelectRoomProduct } from "./utils/queryStringHelpers";
import { shouldTrackLodgingLocation } from "./utils/shouldTrackLodgingLocation";

export interface IHotelShopProps
  extends HotelShopConnectorProps,
    RouteComponentProps {}

export const HotelShop = (props: IHotelShopProps) => {
  const {
    fromDate,
    untilDate,
    selectedLodging,
    fetchHotelShop,
    setSelectedAccount,
    history,
    rewardsAccounts,
    viewedHotelDetailsProperties,
    fetchTravelWalletDetails,
    fetchCfarQuotes,
    hotelShopShopRequestId,
    isHotelCfarEnabled,
    isAddOnOptionAvailable,
    roomInfoProducts,
    roomInfoProductsWithTransformedIndexes,
    hasSelectedRefundableRoom,
    setHasSelectedRefundableRoom,
    setSelectedCfarId,
    selectRoomType,
    refundableRoomProductWithSelectedCfarId,
    refundableRoomProductCorrespondingToChosenRoom,
    getHotelCfarOfferChoiceProperties,
    fetchTravelWalletCreditHistory,
    hotelShopCallState,
    fetchHotelPriceFreezeOffers,
    fetchCfarQuotesCallState,
    isHotelPriceFreezeEnabled,
    isHotelCfarRefundDisplayShowAmount,
    isHotelCfarRefundDisplayShowAmountAdditionalPlaces,
    hotelPriceFreezeOfferProperties,
    isFintechHotelUpdatedUxEnabled,
    isHotelCfarRefundDisplayNonRefPartialEnabled,
    hotelShopDateRange,
    hotelOpaqueCfarQuotesRequest,
    setSelectedRoomId,
    setHasAddedCfarFromModal,
    listPaymentMethods,
  } = props;
  const { matchesDesktop, matchesMobile } = useDeviceTypes();
  const clientContext = useContext(ClientContext);
  const { isAgentPortal } = clientContext;
  const [isReadyToRedirect, setIsReadyToRedirect] = useState<boolean>(false);
  const [openRefundableRoomDetails, setOpenRefundableRoomDetails] =
    useState<boolean>(false);
  const [refundableRoomProduct, setRefundableRoomProduct] =
    useState<RoomProductWithTransformedIndexes>();
  const [roomInfoProduct, setRoomInfoProduct] =
    useState<RoomInfoProductsWithTransformedIndexes>();
  const [roomProductIndex, setRoomProductIndex] = useState<number>();
  const {
    trackingEventControl,
    updateTrackingEventControl,
    removeEventFromTrackingEventControl,
  } = useTrackingEventControl();
  const expState = useExperiments();

  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const travelCreditHistoryExperiment = getExperimentVariant(
    expState.experiments,
    TRAVEL_CREDIT_HISTORY_EXPERIMENT
  );
  const isTravelCreditHistoryExperiment = useMemo(() => {
    return travelCreditHistoryExperiment === AVAILABLE;
  }, [travelCreditHistoryExperiment]);

  const recentlyViewedExperiment = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_MASTER_V1
  );
  const isRecentlyViewedV1Experiment = useMemo(() => {
    return recentlyViewedExperiment === AVAILABLE;
  }, [recentlyViewedExperiment]);

  const recentlyViewedDesktopP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    DESKTOP_RECENTLY_VIEWED_HOTELS,
    DESKTOP_RECENTLY_VIEWED_HOTELS_VARIANTS
  );

  const recentlyViewedMobileP0Variant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_RECENTLY_VIEWED_HOTELS,
    MOBILE_RECENTLY_VIEWED_HOTELS_VARIANTS
  );
  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const queryString = useMemo(
    () => queryStringParser.parse(history.location.search),
    [history.location.search]
  );

  const handleReadyToRedirect = (eventControl?: TrackingEventControlType) => {
    // note: it needs to wait for `selectRoomType` to be executed before proceeding with the page redirect
    setTimeout(() => {
      if (eventControl) {
        updateTrackingEventControl(eventControl);
      }
      setIsReadyToRedirect(true);
    });
  };

  const roomInfoProductsType: HotelShopRoomTypePickerVariant = {
    roomInfoProductsWithTransformedIndexes,
    customComponents: {
      RefundableRoomDetails: (props) => (
        <RefundableRoomDetails
          {...props}
          setRefundableRoomProduct={setRefundableRoomProduct}
          setOpenRefundableRoomDetails={setOpenRefundableRoomDetails}
          isMobile={matchesMobile}
          checkInDate={hotelShopDateRange?.from}
          showCfarCoverageAmount={
            isHotelCfarRefundDisplayShowAmount ||
            isHotelCfarRefundDisplayShowAmountAdditionalPlaces
          }
          variant={
            isFintechHotelUpdatedUxEnabled ? "refundable-room-ux" : "default"
          }
          isHotelCfarRefundDisplayNonRefPartialEnabled={
            isHotelCfarRefundDisplayNonRefPartialEnabled
          }
          excludeTaxesAndFeesCopy={
            selectedLodging?.price?.displayTaxesAndFeesIncluded
          }
        />
      ),
    },
    customStates: {
      // note: refundable room is disabled on agent portal
      isRefundableRoomDisabled: isAgentPortal,
      hasSelectedRefundableRoom,
      setHasSelectedRefundableRoom: (
        hasSelectedRefundableRoom: boolean,
        quoteId?: QuoteId
      ) => {
        setHasSelectedRefundableRoom(hasSelectedRefundableRoom);
        setSelectedCfarId(
          hasSelectedRefundableRoom && !!quoteId ? quoteId : null
        );
      },
    },
    variant: HotelShopRoomTypePickerEnum.WithTransformedIndexes,
  };

  useEffect(() => {
    if (selectedLodging) {
      document.title = selectedLodging.lodging.name;
    }
  }, [selectedLodging]);

  useEffect(() => {
    fetchTravelWalletDetails();
    listPaymentMethods();
    document.title = SELECT_ROOM_TITLE;
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (
      hotelShopCallState === HotelShopCallState.Success &&
      (!isHotelCfarEnabled ||
        fetchCfarQuotesCallState === CallState.Success ||
        fetchCfarQuotesCallState === CallState.Failed)
    ) {
      const { properties, encryptedProperties } = viewedHotelDetailsProperties;
      const offer = selectedLodging?.bestOfferThisLodging;
      const offerProperties = {
        viewed_travel_offer: !!offer,
      };
      trackEvent({
        eventName: VIEWED_HOTEL_DETAILS,
        properties: {
          ...properties,
          ...getViewedHotelDetailsHotelCfarOfferFacts(
            refundableRoomProductWithSelectedCfarId
          ),
          ...offerProperties,
          ...hotelPriceFreezeOfferProperties,
          nth_night_offer: getNthNightTrackingProperty(selectedLodging),
        },
        encryptedProperties,
      });
    }
  }, [hotelShopCallState, fetchCfarQuotesCallState, isHotelCfarEnabled]);

  useEffect(() => {
    if (
      [CallState.Success, CallState.Failed].includes(expState.callState) ||
      expState.experiments.length > 0
    ) {
      fetchHotelShop(history, {
        overrideStateByQueryParams: true,
        fetchSimilarHotels: similarHotelsVariant !== CONTROL,
        includeLocationSearchTerm:
          (matchesMobile
            ? recentlyViewedMobileP0Variant !== CONTROL
            : recentlyViewedDesktopP0Variant !== CONTROL) &&
          isRecentlyViewedV1Experiment &&
          shouldTrackLodgingLocation(history.location.search),
        fetchLodgingToken: cacheHotelTokenEnabled,
      });
      setTimeout(() => window.scrollTo(0, 0), 0);
    }
  }, [expState.callState, queryString.lodgingId]);

  // @FLEX-TODO: Remove when FLEX-5037 is rolled out to V2
  useEffect(() => {
    if (
      isHotelCfarEnabled &&
      hotelShopShopRequestId &&
      hotelOpaqueCfarQuotesRequest !== undefined
    ) {
      fetchCfarQuotes({
        opaqueRequest: hotelShopShopRequestId,
        opaqueCfarQuotesRequest: hotelOpaqueCfarQuotesRequest,
      });
    }
  }, [hotelShopShopRequestId, hotelOpaqueCfarQuotesRequest]);

  useEffect(() => {
    const { selectedAccountIndex } = queryStringParser.parse(
      history.location.search
    );

    if (selectedAccountIndex && rewardsAccounts.length > 0) {
      setSelectedAccount(
        rewardsAccounts[Number(selectedAccountIndex)]?.accountReferenceId
      );
    }
  }, [rewardsAccounts]);

  /*
    note: selectRoomType (the action that updates indexes in redux state) is called within a halifax component as a callback;
    it's executed at the same time as when `onClickContinue` is handled, which means that some states are not yet updated.
  */
  useEffect(() => {
    if (isReadyToRedirect) {
      // delayed firing of SELECT_HOTEL_ROOM
      if (trackingEventControl[SELECT_HOTEL_ROOM]) {
        const { properties, encryptedProperties } =
          viewedHotelDetailsProperties;
        trackEvent({
          eventName: SELECT_HOTEL_ROOM,
          properties: {
            ...properties,
            ...trackingEventControl[SELECT_HOTEL_ROOM].properties,
            ...getSelectHotelRoomHotelCfarOfferFacts({
              refundableRoomProduct:
                refundableRoomProductCorrespondingToChosenRoom,
              ...getHotelCfarOfferChoiceProperties("shop"),
            }),
          },
          encryptedProperties,
        });
        removeEventFromTrackingEventControl(SELECT_HOTEL_ROOM);
      }

      handlePageRedirectOnSelectRoomProduct({
        history,
        isAddOnOptionAvailable,
        hotelBookType: HotelBookType.DEFAULT,
      });
      setIsReadyToRedirect(false);
    }
  }, [
    history,
    isAddOnOptionAvailable,
    isReadyToRedirect,
    trackingEventControl,
  ]);

  useEffect(() => {
    if (isTravelCreditHistoryExperiment) {
      fetchTravelWalletCreditHistory();
    }
  }, [isTravelCreditHistoryExperiment]);

  useEffect(() => {
    if (
      isHotelPriceFreezeEnabled &&
      hotelShopCallState === HotelShopCallState.Success
    ) {
      const lodgingId = selectedLodging?.lodging.id;
      let rates = roomInfoProducts.map((roomInfoProduct) => {
        const products = roomInfoProduct.products;
        const productRates = products.map((product) => {
          return {
            basePrice: {
              amount: product?.baseRate.fiat.value,
              currency: product?.baseRate.fiat.currencyCode,
            },
            lodgingId: lodgingId!,
            checkIn: dayjs(fromDate).format("YYYY-MM-DD"),
            checkOut: dayjs(untilDate).format("YYYY-MM-DD"),
          };
        });
        return productRates;
      });
      const flattenRates = rates.flat(2);
      fetchHotelPriceFreezeOffers({
        rates: flattenRates,
      });
    }
  }, [hotelShopCallState]);

  return (
    <>
      <Box className={"hotel-shop-root"}>
        {matchesDesktop && (
          <DesktopShop
            handleReadyToRedirect={handleReadyToRedirect}
            roomInfoProductsType={roomInfoProductsType}
            setRoomInfoProduct={setRoomInfoProduct}
            setRoomProductIndex={setRoomProductIndex}
          />
        )}
        {matchesMobile && (
          <MobileShop
            handleReadyToRedirect={handleReadyToRedirect}
            roomInfoProductsType={roomInfoProductsType}
          />
        )}
      </Box>
      {!!refundableRoomProduct?.roomProductVariant &&
        refundableRoomProduct.roomProductVariant.variant ===
          RoomProductType.RefundableRoom && (
          <CfarDetails
            openCfarDetails={openRefundableRoomDetails}
            onClose={() => setOpenRefundableRoomDetails(false)}
            onContinue={() => {
              // note: refundable room is disabled on agent portal
              if (isAgentPortal) {
                return;
              }

              setHasAddedCfarFromModal(true);
              const quoteId = getQuoteId(
                refundableRoomProduct.roomProductVariant
              );
              const baseProduct =
                refundableRoomProduct.roomProductVariant?.baseProduct;

              if (quoteId != null) {
                setHasSelectedRefundableRoom(true);
                setSelectedCfarId(quoteId);
              }
              if (roomInfoProduct) {
                setSelectedRoomId(roomInfoProduct.roomInfo.roomId);
              }

              if (baseProduct != null) {
                selectRoomType(
                  baseProduct.roomIndex,
                  baseProduct.productIndex,
                  baseProduct.rateId?.value
                );
                handleReadyToRedirect({
                  [SELECT_HOTEL_ROOM]: {
                    properties: {
                      room_type:
                        roomInfoProducts[baseProduct.roomIndex]?.roomInfo.name,
                      rate_provider:
                        roomInfoProducts[baseProduct.roomIndex]?.products[
                          baseProduct.productIndex
                        ]?.providerName,
                      provider_name:
                        roomInfoProducts[baseProduct.roomIndex]?.products[
                          baseProduct.productIndex
                        ]?.providerName,
                    },
                  },
                });
              }
            }}
            currentHotelCfarQuote={{
              id: refundableRoomProduct.roomProductVariant.quoteId,
              premiumPrices:
                refundableRoomProduct.roomProductVariant.premiumPrices,
              premiumPricesPerNight:
                refundableRoomProduct.roomProductVariant.premiumPricesPerNight,
              coverageAmount:
                refundableRoomProduct.roomProductVariant.coverageAmount,
              coveragePercentage:
                refundableRoomProduct.roomProductVariant.coveragePercentage,
              CfarQuoteResult: CfarQuoteResultEnum.HotelCfarQuote,
            }}
            currentCancellationPolicy={
              refundableRoomProduct.roomProductVariant.cancellationPolicy
            }
            currentAdditionalInfo={
              refundableRoomProduct.roomProductVariant.additionalInfo
            }
            cardContentProps={{ hideRadioButtons: true }}
            contentOnly={false}
            modalType="refundable-room"
            contentClassName="hotel-refundable-room-details-modal"
            showCoverageAmount={
              isHotelCfarRefundDisplayShowAmountAdditionalPlaces
            }
            roomInfoProduct={roomInfoProduct}
            roomProductIndex={roomProductIndex}
          />
        )}
    </>
  );
};

const getQuoteId = (roomProductVariant: RoomProductVariant | undefined) =>
  roomProductVariant?.variant === RoomProductType.RefundableRoom
    ? roomProductVariant.quoteId
    : undefined;
