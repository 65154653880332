import { connect, ConnectedProps } from "react-redux";

import {
  fetchInitialHotelAvailability,
  IFetchHotelAvailability,
  ISetMapBound,
  ISetViewHotelsNearLocation,
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setMapBound,
  setMaxPriceFilter,
  setStarRatingsFilter,
  setStayTypeFilter,
  setMealPlanTypeFilter,
  setViewHotelsNearLocation,
} from "../../actions/actions";
import { AvailabilitySearchControl } from "./component";
import { IStoreState } from "../../../../reducers/types";
import {
  getLocation,
  getFromDate,
  getUntilDate,
  getAdultsCount,
  getRoomsCount,
  getChildren,
  getApplyUserHotelPreferences,
  getPetsCount,
} from "../../../search/reducer/index";
import {
  areRequestParametersChanged,
  getMapBound,
  initialFilterState,
} from "../../reducer";
import { BoundingBox, IResult } from "redmond";
import { Dispatch } from "@reduxjs/toolkit";
import H from "history";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";

interface IStateProps {
  location: IResult | null;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  mapBounds: BoundingBox | null;
  areRequestParametersChanged: boolean;
  roomsCount: number;
  petsCount: number;
  shouldApplyUserHotelPreferences: boolean;
}
const mapStateToProps = (state: IStoreState) => {
  return {
    location: getLocation(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    mapBounds: getMapBound(state),
    areRequestParametersChanged: areRequestParametersChanged(state),
    roomsCount: getRoomsCount(state),
    petsCount: getPetsCount(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
  };
};

interface IDispatchProps {
  fetchInitialHotelAvailability: (
    history: H.History,
    searchFromMap?: boolean,
    searchHotelsNear?: boolean,
    includesHomes?: boolean,
  ) => IFetchHotelAvailability;
  setMapBound: (mapBound: BoundingBox | null) => ISetMapBound;
  resetFilters: () => void;
  setViewHotelsNearLocation: (
    location: IResult | null
  ) => ISetViewHotelsNearLocation;
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    fetchInitialHotelAvailability: (
      history: any,
      searchFromMap?: boolean,
      searchHotelsNear?: boolean,
      includeHomes?: boolean,
    ) =>
      dispatch(
        fetchInitialHotelAvailability({
          history,
          searchFromMap,
          searchHotelsNear,
          includeHomes
        })
      ),
    setMapBound: (mapBound: BoundingBox | null) =>
      dispatch(setMapBound(mapBound)),
    setViewHotelsNearLocation: (location: IResult | null) =>
      dispatch(setViewHotelsNearLocation(location)),
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setApplyUserHotelPreferences(false));
      dispatch(setStayTypeFilter(initialFilterState.stayTypes));
      dispatch(setMealPlanTypeFilter(initialFilterState.mealPlanTypes));
    },
  };
};

const mergeProps = (stateProps: IStateProps, dispatchProps: IDispatchProps) => {
  return {
    ...stateProps,
    ...dispatchProps,
  };
};
const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type AvailabilitySearchControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilitySearchControl = connector(
  AvailabilitySearchControl
);
