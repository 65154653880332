import { StayType } from "redmond/hotels-module/interfaces";

export type StayTypeFilterValue = "All" | StayType.Hotels | StayType.Homes;

export const StayTypeFilterOptions: Array<{
  value: StayTypeFilterValue;
  label: string;
}> = [
  {
    value: "All",
    label: "All stays",
  },
  {
    value: StayType.Hotels,
    label: "Hotels",
  },
  {
    value: StayType.Homes,
    label: "Vacation rentals",
  },
];
