import { Dispatch } from "@reduxjs/toolkit";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import { RouterProps } from "react-router-dom";
import { IPriceRange } from "redmond";

import { IStoreState } from "../../../../reducers/types";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";
import { actions } from "../../actions";
import { setOpenDatesModal } from "../../actions/actions";
import {
  getHotelAvailabilityMinMaxPriceRange,
  getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching,
  getIsUnfilteredHotelAvailabilityLodgingsEmpty,
  getOpenDatesModal,
  initialFilterState,
} from "../../reducer/index";
import { AvailabilityNoResults } from "./component";

interface IStateProps {
  minMaxPriceRange: IPriceRange | null;
  unfilteredLodgingsEmpty: boolean;
  datesModalOpen: boolean;
  isFilteredHotelAvailabilityLodgingsEmpty: boolean;
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    unfilteredLodgingsEmpty:
      getIsUnfilteredHotelAvailabilityLodgingsEmpty(state),
    datesModalOpen: getOpenDatesModal(state),
    isFilteredHotelAvailabilityLodgingsEmpty:
      getIsFilteredHotelAvailabilityLodgingsEmptyAndDoneSearching(state),
  };
};

interface IDispatchProps {
  resetFilters: () => void;
  openDatesModal: () => void;
  setOpenDatesModal: (openDatesModal: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    resetFilters: () => {
      dispatch(actions.setAmenitiesFilter(initialFilterState.amenities));
      dispatch(actions.setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(actions.setHotelNameFilter(initialFilterState.hotelName));
      dispatch(actions.setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(
        actions.setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly)
      );
      dispatch(actions.setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setApplyUserHotelPreferences(false));
      dispatch(actions.setPolicyFilter(initialFilterState.isInPolicy));
      dispatch(
        actions.setPremiumStaysOnly(initialFilterState.showPremiumStaysOnly)
      );
      dispatch(
        actions.setLifestyleStaysOnly(initialFilterState.showLifestyleStaysOnly)
      );
    },
    openDatesModal: () => dispatch(actions.setOpenDatesModal(true)),
    setOpenDatesModal: (openDatesModal: boolean) =>
      dispatch(setOpenDatesModal(openDatesModal)),
  };
};

interface OwnProps extends RouterProps {}

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: OwnProps
) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
    resetFilters: () => dispatchProps.resetFilters(),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type AvailabilityNoResultsConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedAvailabilityNoResults = withRouter(
  connector(AvailabilityNoResults)
);
