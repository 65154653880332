import { History } from "history";
import queryStringParser from "query-string";

export interface IHotelParsedQuery {
  locationName: string;
  adultsCount?: number;
  children?: number[];
  roomsCount?: number;
  pets?: number;
  entryStep?: string;
}

export const parseQueryFromString = (
  queryString: string
): IHotelParsedQuery => {
  const {
    locationName,
    adultsCount,
    children,
    roomsCount,
    petsCount,
    entryStep,
  } = queryStringParser.parse(queryString);

  const childrenArray = Array.isArray(children)
    ? children.map((age) => parseInt(age, 10))
    : [...(children ? [parseInt(children, 10)] : [])];
  return {
    locationName: locationName as string,
    adultsCount: adultsCount ? parseInt(adultsCount as string, 10) : undefined,
    children: childrenArray,
    roomsCount: parseInt(roomsCount as string, 10),
    pets: parseInt(petsCount as string, 10),
    entryStep: entryStep as string,
  };
};

export const parseQueryString = (
  history: History
): IHotelParsedQuery | null => {
  const queryString = history?.location?.search || "";
  return queryString ? parseQueryFromString(queryString) : null;
};
