import { isCorpTenant } from "@capone/common";
import { Dispatch } from "@reduxjs/toolkit";
import H from "history";
import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  BoundingBox,
  IPriceRange,
  IResult,
  ITrackingProperties,
  Lodging,
  RewardsAccount,
  TravelWalletCredit,
  TravelWalletOffer,
  UserHotelPreferencesPayload,
  ViewedHotelListProperties,
} from "redmond";

import { config } from "../../../../api/config";
import { IStoreState } from "../../../../reducers/types";
import {
  getRewardsAccounts,
  getRewardsAccountWithLargestEarnForBanner,
  getSelectedAccount,
  getSelectedAccountReferenceIdIfRedemptionEnabled,
  getUserIsPrimaryCardHolder,
} from "../../../rewards/reducer";
import {
  getAdultsCount,
  getApplyUserHotelPreferences,
  getChildren,
  getFromDate,
  getRoomsCount,
  getUntilDate,
} from "../../../search/reducer";
import { getTravelWalletCredit } from "../../../travel-wallet/reducer";
import {
  fetchMoreHotelAvailability,
  ISetMapBound,
  ISetViewHotelsNearLocation,
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHasViewedUnavailableHotel,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setIsLifestyleCollectionEnabled,
  setIsPremierCollectionEnabled,
  setLifestyleStaysOnly,
  setLodgingIdHovered,
  setLodgingIdInFocus,
  setMapBound,
  setMaxPriceFilter,
  setMealPlanTypeFilter,
  setOpenDatesModal,
  setPolicyFilter,
  setPremiumStaysOnly,
  setSelectedLodgingIndex,
  setStarRatingsFilter,
  setStayTypeFilter,
  setViewHotelsNearLocation,
} from "../../actions/actions";
import {
  getApplicableHotelOffer,
  getFilteredAndSortedHotelAvailabilityLodgings,
  getFilteredHotelAvailabilityLodgingsContainPremierCollection,
  getHasUserSetHotelPreferences,
  getHasViewedUnavailableHotel,
  getHotelAvailabilityCallState,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
  getHotelAvailabilityMinMaxPriceRange,
  getHotelAvailabilityRoomsCount,
  getHotelAvailabilitySearchLocationResult,
  getHotelAvailabilitySortOption,
  getHotelQueryParams,
  getHotelsAvailabilityPetsCount,
  getIsFilteredHotelAvailabilityLodgingsEmpty,
  getMapBound,
  getOpenDatesModal,
  getSearchedNightCount,
  getShowPremiumStaysOnlyFilter,
  getTotalPropertyCount,
  getUserHotelPreferences,
  getViewedHotelListProperties,
  getViewHotelsNearLocation,
  initialFilterState,
} from "../../reducer";
import { HotelAvailabilityCallState } from "../../reducer/state";
import { AvailabilityList } from "./component";

export enum FilterKey {
  Amenities = "Amenities",
  StarRatings = "StarRatings",
  PriceRange = "PriceRange",
  HotelName = "HotelName",
  PremierCollection = "PremierCollection",
}

interface IOwnProps {
  isHidden?: boolean;
  setOpenPriceDropProtectionBannerModal: (arg: boolean) => void;
}

interface IStateProps {
  lodgings: Lodging[];
  nightCount: number | null;
  searchedLocation: IResult | null;
  hotelAvailabilityCallState: HotelAvailabilityCallState;
  minMaxPriceRange: IPriceRange | null;
  accountReferenceId: string | null;
  hotelQueryParams: any;
  viewedHotelListProperties: ITrackingProperties<ViewedHotelListProperties>;
  isFilteredHotelAvailabilityLodgingsEmpty: boolean;
  hasViewedUnavailableHotel: boolean;
  openDatesModal: boolean;
  fromDate: Date | null;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  mapBounds: BoundingBox | null;
  roomsCount: number;
  searchedRoomsCount: number;
  searchedPetsCount: number;
  credit?: TravelWalletCredit;
  isVentureX: boolean;
  selectedAccount: RewardsAccount | undefined;
  largestValueAccount: RewardsAccount;
  isSearchingMap: boolean;
  totalPropertyCount: number | null;
  filteredHotelAvailabilityLodgingsContainPremierCollection: boolean;
  shouldApplyUserHotelPreferences: boolean;
  userHasSetHotelPreferences: boolean;
  userHotelPreferences?: UserHotelPreferencesPayload;
  canEarnRewards: boolean;
  showPremiumStaysOnly: boolean;
  bestOverallOffer?: TravelWalletOffer;
  sortOrder: string;
  availabilityResultsHasHomesAndHotels: boolean;
}

const mapStateToProps = (state: IStoreState): IStateProps => {
  return {
    totalPropertyCount: getTotalPropertyCount(state),
    lodgings: getFilteredAndSortedHotelAvailabilityLodgings(state),
    nightCount: getSearchedNightCount(state),
    searchedLocation: getHotelAvailabilitySearchLocationResult(state),
    hotelAvailabilityCallState: getHotelAvailabilityCallState(state),
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    accountReferenceId: getSelectedAccountReferenceIdIfRedemptionEnabled(state),
    hotelQueryParams: getHotelQueryParams(state),
    viewedHotelListProperties: getViewedHotelListProperties(state),
    isFilteredHotelAvailabilityLodgingsEmpty:
      getIsFilteredHotelAvailabilityLodgingsEmpty(state),
    hasViewedUnavailableHotel: getHasViewedUnavailableHotel(state),
    openDatesModal: getOpenDatesModal(state),
    fromDate: getFromDate(state),
    untilDate: getUntilDate(state),
    adultsCount: getAdultsCount(state),
    children: getChildren(state),
    mapBounds: getMapBound(state),
    roomsCount: getRoomsCount(state),
    searchedRoomsCount: getHotelAvailabilityRoomsCount(state),
    searchedPetsCount: getHotelsAvailabilityPetsCount(state),
    credit: getTravelWalletCredit(state),
    isVentureX: !!getRewardsAccounts(state).find((acc) =>
      acc.productDisplayName.includes("Venture X")
    ),
    selectedAccount: getSelectedAccount(state),
    largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
    isSearchingMap: !!getViewHotelsNearLocation(state) || !!getMapBound(state),
    filteredHotelAvailabilityLodgingsContainPremierCollection:
      getFilteredHotelAvailabilityLodgingsContainPremierCollection(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHasSetHotelPreferences: getHasUserSetHotelPreferences(state),
    userHotelPreferences: getUserHotelPreferences(state),
    canEarnRewards:
      !isCorpTenant(config.TENANT) || getUserIsPrimaryCardHolder(state),
    showPremiumStaysOnly: getShowPremiumStaysOnlyFilter(state),
    bestOverallOffer: getApplicableHotelOffer(state),
    sortOrder: getHotelAvailabilitySortOption(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
  };
};

interface IDispatchProps {
  setLodgingIdHovered: (lodgingId: string | null) => void;
  setLodgingIdInFocus: (lodgingId: string | null) => void;
  fetchMoreHotelAvailability: (
    history: H.History,
    includeHomes?: boolean
  ) => void;
  setOpenDatesModal: (openDatesModal: boolean) => void;
  setHasViewedUnavailableHotel: () => void;
  setMapBound: (mapBound: BoundingBox | null) => ISetMapBound;
  setViewHotelsNearLocation: (
    location: IResult | null
  ) => ISetViewHotelsNearLocation;
  setSelectedLodgingIndex: (index: number) => void;
  setIsPremierCollectionEnabled: (isEnabled: boolean) => void;
  setIsLifestyleCollectionEnabled: (isEnabled: boolean) => void;
  resetFilters: () => void;
}

const mapDispatchToProps = (dispatch: Dispatch): IDispatchProps => {
  return {
    setLodgingIdHovered: (lodgingId: string | null) =>
      dispatch(setLodgingIdHovered(lodgingId)),
    setLodgingIdInFocus: (lodgingId: string | null) =>
      dispatch(setLodgingIdInFocus(lodgingId)),
    fetchMoreHotelAvailability: (history: H.History, includeHomes) =>
      dispatch(fetchMoreHotelAvailability(history, includeHomes)),
    setOpenDatesModal: (openDatesModal: boolean) =>
      dispatch(setOpenDatesModal(openDatesModal)),
    setHasViewedUnavailableHotel: () =>
      dispatch(setHasViewedUnavailableHotel()),
    setMapBound: (mapBound: BoundingBox | null) =>
      dispatch(setMapBound(mapBound)),
    setSelectedLodgingIndex: (index) =>
      dispatch(setSelectedLodgingIndex(index)),
    setViewHotelsNearLocation: (location: IResult | null) =>
      dispatch(setViewHotelsNearLocation(location)),
    setIsPremierCollectionEnabled: (isEnabled: boolean) =>
      dispatch(setIsPremierCollectionEnabled(isEnabled)),
    setIsLifestyleCollectionEnabled: (isEnabled: boolean) =>
      dispatch(setIsLifestyleCollectionEnabled(isEnabled)),
    resetFilters: () => {
      dispatch(setAmenitiesFilter(initialFilterState.amenities));
      dispatch(setStarRatingsFilter(initialFilterState.starRatings));
      dispatch(setHotelNameFilter(initialFilterState.hotelName));
      dispatch(setFreeCancelFilter(initialFilterState.freeCancel));
      dispatch(setHotelsOnSaleFilter(initialFilterState.hotelsOnSaleOnly));
      dispatch(setMaxPriceFilter(initialFilterState.maxPrice));
      dispatch(setPolicyFilter(initialFilterState.isInPolicy));
      dispatch(setPremiumStaysOnly(initialFilterState.showPremiumStaysOnly));
      dispatch(
        setLifestyleStaysOnly(initialFilterState.showLifestyleStaysOnly)
      );
      dispatch(setStayTypeFilter(initialFilterState.stayTypes));
      dispatch(setMealPlanTypeFilter(initialFilterState.mealPlanTypes));
    },
  };
};

const mergeProps = (
  stateProps: IStateProps,
  dispatchProps: IDispatchProps,
  ownProps: IOwnProps
) => {
  return {
    ...ownProps,
    ...stateProps,
    ...dispatchProps,
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps, mergeProps);

export type AvailabilityListConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityList = connector(
  withRouter(AvailabilityList)
);
