import React from "react";
import { CloseButtonIcon, Header, ActionLink } from "halifax";
import { Box, Typography } from "@material-ui/core";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RouteComponentProps } from "react-router";
import clsx from "clsx";
import { MobileHotelSearchV3ConnectorProps } from "./container";
import "./styles.scss";
import { LocationSearch, MobileCalendarPicker } from "./components";
import * as textConstants from "./textConstants";
import { OccupancySelection } from "../MobileHotelSearchControl/components";
import { LocationQueryEnum, RecentHotelSearch } from "redmond";
import {
  AVAILABLE,
  getExperimentVariant,
  STAYS_HOMEPAGE,
  STAYS_SEARCH,
  useExperiments,
} from "../../../../context/experiments";
import { PATH_HOME } from "../../../../utils/paths";
import { fetchLocationAutocomplete } from "../../../../api/v0/search/fetchLocationAutocomplete";
import {
  IHotelParsedQuery,
  parseQueryString,
} from "../../utils/parseQueryString";

enum MobileHotelSearchStep {
  LocationSearch,
  CalendarPicker,
  Complete,
}

export interface IMobileHotelSearchControlV3Props
  extends MobileHotelSearchV3ConnectorProps,
    RouteComponentProps {
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  onSelectLocation?: (value: any) => void;
  onSelectDates?: (from: Date | null, until: Date | null) => void;
  isCustomerProfileExperiment?: boolean;
  showCalendarPricingColors?: boolean;
  showCalendarPricing?: boolean;
}

export const MobileHotelSearchControlV3 = (
  props: IMobileHotelSearchControlV3Props
) => {
  const {
    history,
    hasLocationAutocompleteError,
    setLocationAutocompleteError,
    onSelectLocation,
    onSelectDates,
    fetchCalendarBuckets,
    hasLocation,
    showCalendarPricingColors,
    showCalendarPricing,
    setLocationCategories,
    setOccupancyCounts,
    setRoomsCount,
    setLocation,
    setPetsCount,
  } = props;

  const [currentStep, setCurrentStep] = React.useState<MobileHotelSearchStep>(
    MobileHotelSearchStep.LocationSearch
  );

  const expState = useExperiments();
  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const handleGoBack = () => {
    setCurrentStep((step) =>
      step > MobileHotelSearchStep.LocationSearch ? step - 1 : step
    );
  };

  const parsedQueryString = parseQueryString(history);

  const populateFieldsFromQueryString = async ({
    locationName,
    adultsCount,
    children,
    roomsCount,
    pets,
  }: IHotelParsedQuery) => {
    if (locationName) {
      const { categories } = await fetchLocationAutocomplete({
        LocationQuery: LocationQueryEnum.Label,
        l: locationName,
      });
      setLocationCategories(categories);
      const correspondingLocations = categories.flatMap((category) =>
        category.results.find((result) =>
          result.label.toLowerCase().includes(locationName.toLowerCase())
        )
      );
      const allLocations = categories.flatMap((category) => category.results);
      const locationToUse =
        correspondingLocations.length > 0
          ? correspondingLocations[0]
          : allLocations[0];
      setLocation(locationToUse ?? null);
      if (showCalendarPricingColors) {
        fetchCalendarBuckets();
      }
    }

    if (adultsCount || children?.length) {
      setOccupancyCounts({
        adults: adultsCount ?? 0,
        children: children ?? [],
      });
    }
    if (roomsCount) {
      setRoomsCount(roomsCount);
    }
    if (pets) {
      setPetsCount(pets);
    }
  };

  React.useEffect(() => {
    if (parsedQueryString) {
      (async () => {
        await populateFieldsFromQueryString(parsedQueryString);
      })();
    }
    if (parsedQueryString?.entryStep === "calendar") {
      setCurrentStep(MobileHotelSearchStep.CalendarPicker);
    } else if (parsedQueryString?.entryStep === "location") {
      setCurrentStep(MobileHotelSearchStep.LocationSearch);
    }
  }, []);

  React.useEffect(() => {
    setTimeout(() => window.scrollTo(0, 0), 0);
  }, [currentStep]);

  return (
    <Box
      className={clsx("mobile-hotel-search-v3", {
        "date-range-picker":
          currentStep === MobileHotelSearchStep.CalendarPicker,
        "has-location": hasLocation,
      })}
    >
      <Header
        className="homepage"
        center={<HeaderCenterSection currentStep={currentStep} />}
        left={
          <ActionLink
            className={clsx("mobile-hotel-search-header-go-back", {
              hidden: currentStep === MobileHotelSearchStep.LocationSearch,
            })}
            onClick={handleGoBack}
            content={<FontAwesomeIcon icon={faChevronLeft} />}
          />
        }
        right={
          <ActionLink
            className="mobile-hotel-search-header-close"
            onClick={() => {
              history.push(PATH_HOME);
              hasLocationAutocompleteError &&
                setLocationAutocompleteError(false);
            }}
            content={<CloseButtonIcon />}
            label="Close"
          />
        }
        isMobile
        fullWidth
      />
      {currentStep === MobileHotelSearchStep.LocationSearch && (
        <>
          <OccupancySelection
            showTotalTravelers
            autoFocus={false}
            showPets={staysSearchEnabled}
          />
          <LocationSearch
            onComplete={(value) => {
              setCurrentStep(MobileHotelSearchStep.CalendarPicker);
              onSelectLocation?.(value);
              if (showCalendarPricingColors) {
                fetchCalendarBuckets();
              }
            }}
            history={history}
            recentSearches={props.recentSearches}
            onRecentSearchClick={props.onRecentSearchClick}
          />
        </>
      )}
      {currentStep === MobileHotelSearchStep.CalendarPicker && (
        <MobileCalendarPicker
          onComplete={onSelectDates}
          showPricing={showCalendarPricing}
        />
      )}
    </Box>
  );
};

interface IHeaderCenterSectionProps {
  currentStep: number;
}

const HeaderCenterSection = ({ currentStep }: IHeaderCenterSectionProps) => {
  const expState = useExperiments();

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const headerText =
    currentStep === MobileHotelSearchStep.CalendarPicker
      ? textConstants.UPDATE_CALENDAR_TEXT
      : staysHomepageEnabled
      ? textConstants.STAYS_HEADER
      : textConstants.HOTELS_HEADER;
  return (
    <Box className="header-center-section">
      {headerText === textConstants.UPDATE_CALENDAR_TEXT ? (
        <Box className="calendar-header">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      ) : (
        <Box className="mobile-hotel-search-location-label">
          <Typography variant="body1">{headerText}</Typography>
        </Box>
      )}
    </Box>
  );
};
