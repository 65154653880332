import { Box } from "@material-ui/core";
import { ButtonWrap, CloseButtonIcon } from "halifax";
import React from "react";
import "./styles.scss";

export const MobileStayTypeInfoPopper = (props: {
  isOpen: boolean;
  handleClose: () => void;
}) => {
  const { isOpen, handleClose } = props;

  return (
    <>
      {isOpen && (
        <Box className="mobile-stay-type-filter-pop-up">
          <Box className="mobile-stay-type-tooltip-header">
            <Box>
              <Box className="mobile-stay-type-new-badge">New</Box>
            </Box>
            <ButtonWrap
              className="mobile-stay-type-tooltip-close-button"
              aria-label="Close"
              onClick={handleClose}
            >
              <CloseButtonIcon className="mobile-stay-type-tooltip-close-icon" />
            </ButtonWrap>
          </Box>
          <Box className="mobile-stay-type-tooltip-text-box">
            <span className="mobile-stay-type-tooltip-text">
              We've added{" "}
              <span className="mobile-stay-type-tooltip-text-bolded">
                professionally managed vacation rentals across the U.S.
              </span>{" "}
              View by property type to see vacation rentals, hotels or both.
            </span>
          </Box>
          <ButtonWrap
            className="mobile-stay-type-tooltip-button"
            onClick={handleClose}
          >
            Got it!
          </ButtonWrap>
        </Box>
      )}
    </>
  );
};
