import { LatLngBounds } from "google-maps-react-markers";
import {
  BoundingBox,
  CorpLodging,
  ExperimentStateWithCallState,
  MapFunnelEnum,
  getLodgingTrackingProperties,
  HotelDetailsEntrySourceEnum,
  IIdLodgings,
  IResult,
  ITrackingProperties,
  Lodging,
  LodgingCollectionEnum,
  LodgingSelectionEnum,
  SELECTED_HOTEL_FROM_MAP,
  ViewedHotelListProperties,
} from "redmond";
import { StayType, LodgingPrice } from "redmond/hotels-module/interfaces";
import { v4 as uuidv4 } from "uuid";
import { localCache } from "@capone/common";
import { ZOOM_BOUNDS_OFFSETS } from "./const";
import { transformToStringifiedQuery } from "../../../shop/utils/queryStringHelpers";
import {
  PATH_VACATION_RENTAL_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
  PATH_SHOP,
} from "../../../../utils/paths";
import { HotelAvailabilitySortOption } from "../../reducer/state";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  getExperimentVariant,
  STAYS_SEARCH,
} from "../../../../context/experiments";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";

// Instead of only fetching the markers for the current viewport, we artificially increase
// the viewport bounds in order to fetch additional markers to prevent the map from flickering
// new markers and to give a smoother user experience.
export const getOffsetBounds = (zoom: number, bounds: LatLngBounds) => {
  const ne = bounds.getNorthEast();
  const sw = bounds.getSouthWest();

  const offset = ZOOM_BOUNDS_OFFSETS[zoom] || ZOOM_BOUNDS_OFFSETS[1];

  const offsetBounds = {
    sw: { lng: sw.lng() - offset.sw.lng, lat: sw.lat() - offset.sw.lat },
    ne: { lng: ne.lng() + offset.ne.lng, lat: ne.lat() + offset.ne.lat },
  };

  return offsetBounds;
};

// *** Copied from Hotels ***
export const openHotelDetailsPage = (
  lodging: Lodging | CorpLodging,
  expState: ExperimentStateWithCallState,
  viewedHotelListProperties: ITrackingProperties<ViewedHotelListProperties>,
  mapBounds?: BoundingBox | null,
  searchLocationResult?: IResult | null,
  roomsCount?: number,
  hotelQueryParams?: any, // TODO: Add typing
  sortOrder?: HotelAvailabilitySortOption
) => {
  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  const staysSearchEnabled =
    getExperimentVariant(expState.experiments, STAYS_SEARCH) === AVAILABLE;

  const isHome = lodging.stayType === StayType.Homes;

  let params = transformToStringifiedQuery({
    lodgingId: lodging.lodging.id,
    lodgingSelection: mapBounds
      ? {
          LodgingSelection: LodgingSelectionEnum.Location,
          descriptor: mapBounds,
        }
      : (searchLocationResult?.id as IIdLodgings).lodgingSelection,
    ...hotelQueryParams,
    roomsCount,
    fromHotelAvailability:
      lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
      lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
        ? true
        : undefined,
    hotelDetailsEntrySource: HotelDetailsEntrySourceEnum.MAP,
    shopToken: cacheHotelTokenEnabled
      ? lodging.price?.opaqueShopRequest
      : localCache.set(uuidv4(), lodging.price?.opaqueShopRequest || "").key,
    sortOrder,
  });

  if (isHome && staysSearchEnabled) {
    // Vacation Rentals (Homes) uses listingId instead of lodgingId in the shop URL
    params = params.replace("lodgingId", "listingId");
  }

  trackEvent({
    eventName: SELECTED_HOTEL_FROM_MAP,
    properties: {
      map_funnel: MapFunnelEnum.STAYS,
      ...getLodgingTrackingProperties(lodging).properties,
      ...lodging?.trackingPropertiesV2?.properties,
      ...viewedHotelListProperties.properties,
      is_preferred_cot: lodging.isPreferred,
    },
    encryptedProperties: [
      ...getLodgingTrackingProperties(lodging).encryptedProperties,
      lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
      ...viewedHotelListProperties.encryptedProperties,
    ],
  });

  const path =
    isHome && staysSearchEnabled
      ? PATH_VACATION_RENTAL_SHOP
      : lodging.lodgingCollection === LodgingCollectionEnum.Premier ||
        lodging.lodgingCollection === LodgingCollectionEnum.Lifestyle
      ? PREMIER_COLLECTION_PATH_SHOP
      : PATH_SHOP;

  window.open(`${path}${params}`, "_blank");
};

export const getMarkerPrice = (
  roomsCount: number,
  lodgingPrice?: LodgingPrice
) => {
  const price =
    lodgingPrice?.displayTaxesAndFeesIncludedNightlyPrice &&
    (lodgingPrice?.nightlyDiscountAwareTaxesAndFeesIncluded
      ?.priceWithUnmanagedDiscounts.fiat ||
      lodgingPrice?.nightlyPriceTaxesAndFeesIncluded?.fiat)
      ? lodgingPrice?.nightlyDiscountAwareTaxesAndFeesIncluded
          ?.priceWithUnmanagedDiscounts.fiat ||
        lodgingPrice?.nightlyPriceTaxesAndFeesIncluded?.fiat
      : lodgingPrice?.nightlyDiscountAware?.priceWithUnmanagedDiscounts.fiat ||
        lodgingPrice?.nightlyPrice.fiat;

  if (!price) {
    return null;
  }

  return {
    ...price,
    value: price.value / roomsCount,
  };
};
