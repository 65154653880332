export const MAP_STYLE = [
  {
    featureType: "administrative.locality",
    elementType: "labels.text.fill",
    stylers: [
      {
        lightness: "0",
      },
      {
        saturation: "10",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry.fill",
    stylers: [
      {
        hue: "#26ff00",
      },
      {
        saturation: "40",
      },
      {
        lightness: "-5",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "labels.icon",
    stylers: [
      {
        color: "#b0b0b0",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#999999",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: "-100",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "geometry",
    stylers: [
      {
        lightness: "20",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "10",
      },
    ],
  },
  {
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.fill",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "100",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.text.fill",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "30",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: "30",
      },
      {
        lightness: "-10",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "labels.icon",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.fill",
    stylers: [
      {
        lightness: "50",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry.stroke",
    stylers: [
      {
        weight: "0.50",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry.fill",
    stylers: [
      {
        weight: "0.50",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "geometry.stroke",
    stylers: [
      {
        saturation: "-50",
      },
      {
        lightness: "40",
      },
    ],
  },
  {
    featureType: "transit.line",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    elementType: "all",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.bus",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit.station.rail",
    elementType: "labels.icon",
    stylers: [
      {
        saturation: "-100",
      },
      {
        lightness: "50",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "geometry.fill",
    stylers: [
      {
        color: "#a2ddfa",
      },
    ],
  },
  {
    featureType: "water",
    elementType: "labels.text",
    stylers: [
      {
        visibility: "simplified",
      },
    ],
  },
];

interface IBounds {
  ne: { lng: number; lat: number };
  sw: { lng: number; lat: number };
}

export const ZOOM_BOUNDS_OFFSETS: { [key: string]: IBounds } = {
  1: {
    ne: { lng: 0, lat: 0 },
    sw: { lng: 0, lat: 0 },
  },
  13: {
    ne: { lng: 0.05, lat: 0.05 },
    sw: { lng: 0.05, lat: 0.05 },
  },
  14: {
    ne: { lng: 0.04, lat: 0.04 },
    sw: { lng: 0.04, lat: 0.04 },
  },
  15: {
    ne: { lng: 0.04, lat: 0.04 },
    sw: { lng: 0.04, lat: 0.04 },
  },
  16: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  17: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  18: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  19: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  20: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  21: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
  22: {
    ne: { lng: 0.02, lat: 0.02 },
    sw: { lng: 0.02, lat: 0.02 },
  },
};
