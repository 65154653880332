// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4ud89vEZHk7lZBmhQWgMWw\\=\\={display:flex;flex-direction:column;width:100%}", "",{"version":3,"sources":["webpack://./../../cap1-modules/hotels-module/src/modules/availability/components/StayTypeFilterControl/styles.module.scss"],"names":[],"mappings":"AAAA,6BACE,YAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_4ud89vEZHk7lZBmhQWgMWw=="
};
export default ___CSS_LOADER_EXPORT___;
