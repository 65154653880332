import { select, putResolve, put, call } from "redux-saga/effects";
import dayjs from "dayjs";
import * as H from "history";
import {
  IIdLodgings,
  ILocationQueryLabel,
  IResponse,
  LocationQueryEnum,
  AvailabilityRequestEnum,
  AvailabilityRequest,
  AvailabilityResponse,
  PlatformEnum,
  LodgingSelectionEnum,
  HOTEL_ENTRY,
  HotelEntryProperties,
  IResult,
  ITrackingProperties,
  HotelEntryTypeEnum,
  Initial,
  VIEWED_HOTEL_LIST,
  Lodging,
} from "redmond";
import queryStringParser from "query-string";

import { fetchHotelAvailability as fetchAvailability } from "../../../api/v0/availability/fetchHotelAvailability";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { PATH_HOME } from "../../../utils/paths";
import { isMobile } from "../../../utils/userAgent";
import { actions } from "../actions";
import { actions as searchActions } from "../../search/actions";
import { actions as bookActions } from "../../book/actions";
import {
  getHotelAvailabilityNextPageToken,
  getViewedHotelListProperties,
} from "../reducer";
import { IStoreState } from "../../../reducers/types";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getLocation,
  getRoomsCount,
  getPetsCount,
  getUntilDate,
} from "../../search/reducer";
import { getHotelEntryProperties } from "../../availability/reducer";
import Logger from "../../../utils/logger";
import { trackEvent } from "../../../api/v0/analytics/trackEvent";
import { trackEngagementEvent } from "../../../api/v0/engagement-data/trackEngagementEvent";
import { fetchStaysAvailability } from "../../../api/v0/availability/fetchStaysAvailability";
import { getRewardsAccountsReferenceIds } from "../../rewards/reducer";

export function* fetchHotelAvailabilitySaga(
  action: actions.IFetchHotelAvailability
) {
  try {
    let requestBody: AvailabilityRequest;
    const state: IStoreState = yield select();
    yield put(bookActions.resetBook());

    const requestData: {
      fromDate: Date;
      untilDate: Date;
      location: IResult;
      adultsCount: number;
      children: number[];
      roomsCount: number;
      pets: number;
    } = yield call(getHotelAvailabilityRequestParameters, action);
    const {
      fromDate,
      untilDate,
      location,
      adultsCount,
      children,
      roomsCount,
      pets,
    } = requestData;

    switch (action.requestType) {
      case AvailabilityRequestEnum.InitialSearch: {
        // If we received empty request data, we are redirecting and should not make the request.
        if (JSON.stringify(requestData) === "{}") return;
        if (action.searchFromMap || action.searchHotelsNear) {
          yield putResolve(searchActions.setLocation(null));
        }
        if (roomsCount) {
          requestBody = {
            lodgingSelection: action.searchFromMap
              ? {
                  LodgingSelection: LodgingSelectionEnum.Location,
                  descriptor: state.hotelAvailability.mapBound,
                }
              : action.searchHotelsNear &&
                state.hotelAvailability.viewHotelsNearLocation
              ? (
                  state.hotelAvailability.viewHotelsNearLocation!
                    .id as IIdLodgings
                ).lodgingSelection
              : (location.id as IIdLodgings).lodgingSelection,
            stayDates: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            guests: {
              adults: adultsCount,
              children,
              pets: pets,
            },
            platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
            rooms: { numberOfRooms: roomsCount },
          };
        } else {
          requestBody = {
            lodgingSelection: action.searchFromMap
              ? {
                  LodgingSelection: LodgingSelectionEnum.Location,
                  descriptor: state.hotelAvailability.mapBound,
                }
              : action.searchHotelsNear &&
                state.hotelAvailability.viewHotelsNearLocation
              ? (
                  state.hotelAvailability.viewHotelsNearLocation!
                    .id as IIdLodgings
                ).lodgingSelection
              : (location.id as IIdLodgings).lodgingSelection,
            stayDates: {
              from: dayjs(fromDate).format("YYYY-MM-DD"),
              until: dayjs(untilDate).format("YYYY-MM-DD"),
            },
            guests: {
              adults: adultsCount,
              children,
              pets: pets,
            },
            platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
            progressiveConfig: {},
            AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
          };
        }
        break;
      }
      case AvailabilityRequestEnum.FollowUpSearch: {
        const nextPageToken = getHotelAvailabilityNextPageToken(state);

        if (!nextPageToken) return;

        requestBody = {
          moreToken: nextPageToken,
          progressiveConfig: {},
          AvailabilityRequest: AvailabilityRequestEnum.FollowUpSearch,
        };

        break;
      }
    }
    let availabilityResponse: AvailabilityResponse;

    if (action.includeHomes) {
      availabilityResponse = yield fetchStaysAvailability(requestBody);
    } else {
      availabilityResponse = yield fetchAvailability(requestBody);
    }

    yield put(
      actions.setHotelAvailabilityResults({
        payload: availabilityResponse,
      })
    );

    const properties: ITrackingProperties<HotelEntryProperties> = yield select(
      getHotelEntryProperties
    );
    const viewedHotelListProperties: ITrackingProperties<HotelEntryProperties> =
      yield select(getViewedHotelListProperties);

    const accountReferenceIds: string[] = getRewardsAccountsReferenceIds(state);

    if (action.requestType == AvailabilityRequestEnum.InitialSearch) {
      trackEvent({
        eventName: HOTEL_ENTRY,
        properties: {
          ...properties.properties,
          number_of_properties:
            (availabilityResponse as Initial)?.totalPropertyCount ||
            availabilityResponse.lodgings.length,
        },
        encryptedProperties: properties.encryptedProperties,
      });
      trackEvent({
        eventName: VIEWED_HOTEL_LIST,
        properties: {
          ...viewedHotelListProperties.properties,
          number_of_properties:
            (availabilityResponse as Initial)?.totalPropertyCount ||
            availabilityResponse.lodgings.length,
        },
        encryptedProperties: viewedHotelListProperties.encryptedProperties,
      });

      const initialLowestPricedLodging = availabilityResponse.lodgings.reduce(
        (prev, curr) => {
          const available =
            typeof curr.available === "undefined" ? true : curr.available;
          if (available && curr.price) {
            if (
              prev.price &&
              curr.price.nightlyPrice.fiat.value >
                prev?.price.nightlyPrice.fiat.value
            ) {
              if (
                curr.price.nightlyPrice.fiat.value >
                prev?.price.nightlyPrice.fiat.value
              ) {
                return prev;
              } else {
                return curr;
              }
            } else {
              return curr;
            }
          }
          return prev;
        },
        {} as Lodging
      );

      trackEngagementEvent({
        event: {
          event_type: "hotel_search",
          lowest_hotel_price:
            initialLowestPricedLodging.price?.nightlyPrice.fiat.value ??
            undefined,
          search_term: location.label,
          check_in_date_timestamp: dayjs(fromDate).valueOf(),
          check_out_date_timestamp: dayjs(untilDate).valueOf(),
          account_reference_ids: accountReferenceIds,
        },
      });
    }
  } catch (e) {
    yield put(actions.setHotelAvailabilityCallStateFailed());
    Logger.debug(e);
  }
}
export interface IHotelAvailabilityParsedQuery {
  locationName: string;
  fromDate: Date;
  untilDate: Date | null;
  adultsCount: number;
  children: number[];
  roomsCount?: number;
  entryPoint?: HotelEntryTypeEnum;
  pets?: number;
}

const getExistingStateVariables = (state: IStoreState) => ({
  fromDate: getFromDate(state),
  untilDate: getUntilDate(state),
  location: getLocation(state),
  adultsCount: getAdultsCount(state),
  children: getChildren(state),
  roomsCount: getRoomsCount(state),
  pets: getPetsCount(state),
});

const shouldRedirect = ({ fromDate, untilDate, location }: any) =>
  !fromDate || !untilDate || !location;

const parseQueryString = (
  history: H.History
): IHotelAvailabilityParsedQuery => {
  const queryString = history?.location?.search || "";

  const {
    locationName,
    fromDate,
    untilDate,
    adultsCount,
    children,
    roomsCount,
    entryPoint,
    petsCount,
  } = queryStringParser.parse(queryString);

  const childrenArray = Array.isArray(children)
    ? children.map((age) => parseInt(age, 10))
    : children
    ? [parseInt(children, 10)]
    : [];

  return {
    locationName: locationName as string,
    fromDate: dayjs(fromDate as string).toDate(),
    untilDate: dayjs(untilDate as string).toDate(),
    adultsCount: parseInt(adultsCount as string),
    children: childrenArray,
    roomsCount: parseInt(roomsCount as string),
    entryPoint: entryPoint as HotelEntryTypeEnum,
    pets: parseInt(petsCount as string),
  };
};

function* getHotelAvailabilityRequestParameters(
  fetchHotelAvailability: actions.IFetchHotelAvailability
) {
  const state: IStoreState = yield select();
  const history = fetchHotelAvailability.history;

  let { fromDate, untilDate, location, adultsCount, children, pets } =
    getExistingStateVariables(state);

  const parsedQueryString = parseQueryString(history);

  let locationToSearch: IResult | null = location;
  if (
    !location ||
    (location?.id as IIdLodgings).lodgingSelection.searchTerm !==
      parsedQueryString.locationName
  ) {
    const { correspondingLocation } = yield fetchLocation(parsedQueryString);
    locationToSearch = correspondingLocation;
  }

  [fromDate, untilDate, location, adultsCount, children, pets] = [
    parsedQueryString.fromDate,
    parsedQueryString.untilDate,
    locationToSearch,
    parsedQueryString.adultsCount || 2,
    parsedQueryString.children,
    parsedQueryString.pets || 0,
  ];

  // If we know we are missing the data we should redirect the user home rather than make a bad request.
  // TODO: this should probably be monitored in the future. If we get a lot of these something is likely wrong.
  if (
    shouldRedirect({
      fromDate,
      untilDate,
      location,
      adultsCount,
    })
  ) {
    fetchHotelAvailability.history.push(PATH_HOME);
    return {};
  }

  if (
    fetchHotelAvailability.requestType === AvailabilityRequestEnum.InitialSearch
  ) {
    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));
    yield putResolve(searchActions.setLocation(location));
    yield putResolve(searchActions.setPetsCount(pets));
    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: adultsCount,
        children,
      })
    );

    yield put(actions.setSearchedDates(fromDate!, untilDate!));
    yield put(actions.setSearchedLocationResult(location));
    yield put(
      actions.setSearchedOccupancyCounts({
        adults: adultsCount,
        children,
      })
    );
    yield put(actions.setSearchedPetsCount(pets));
  }

  if (parsedQueryString.entryPoint) {
    yield putResolve(
      actions.setHotelAvailEntryPoint(parsedQueryString.entryPoint)
    );
  } else {
    yield putResolve(actions.setHotelAvailEntryPoint(undefined));
  }

  if (parsedQueryString.roomsCount) {
    const roomsCount = parsedQueryString.roomsCount;
    yield put(searchActions.setRoomsCount(roomsCount));
    yield put(actions.setSearchedRoomsCount(roomsCount));
    return {
      fromDate,
      untilDate,
      location,
      adultsCount,
      children,
      pets,
      roomsCount,
    };
  } else {
    return {
      fromDate,
      untilDate,
      location,
      adultsCount,
      children,
      pets,
    };
  }
}

export function* fetchLocation(
  parsedQueryString: IHotelAvailabilityParsedQuery
) {
  if (!parsedQueryString.locationName)
    return { correspondingLocation: undefined };

  const location = decodeURIComponent(parsedQueryString.locationName);

  const locationRequestBody: ILocationQueryLabel = {
    LocationQuery: LocationQueryEnum.Label,
    l: location,
  };

  const { categories: locationCategories }: IResponse =
    yield fetchLocationAutocomplete(locationRequestBody);
  const correspondingLocations = locationCategories.flatMap((category) =>
    category.results.find((result) =>
      result.label.toLowerCase().includes(location.toLowerCase())
    )
  );
  const allLocations = locationCategories.flatMap(
    (category) => category.results
  );

  return {
    correspondingLocation:
      correspondingLocations.length > 0
        ? correspondingLocations[0]
        : allLocations[0],
  };
}
