import React, { useContext } from "react";

// types, constants
import {
  IResult,
  IIdLodgings,
  RecentHotelSearch,
  ModalScreens,
  POLICY_MODAL,
  VIEWED_POLICY_MODAL,
} from "redmond";
import * as textConstants from "./textConstants";
import { HotelSearchControlConnectorProps } from "./container";

// styles, components
import "./styles.scss";
import { Box } from "@material-ui/core";
import { CalendarPickerButton } from "./components/CalendarPickerButton";
import { LocationAutocomplete } from "./components/LocationAutocomplete";
import { OccupancySelection } from "./components/OccupancySelection";
import { HotelSearchButton } from "./components/SearchButton";
import {
  BannerSeverity,
  Icon,
  IconName,
  NotificationBanner,
  PolicyDetailsModal,
  PolicyModalButton,
} from "halifax";

// helpers, utils
import {
  PATH_AVAILABILITY,
  PATH_STAYS_AVAILABILITY,
} from "../../../../utils/paths";
import { usePrevious } from "../../../../hooks/usePrevious";
import { transformToStringifiedAvailabilityQuery } from "../../../shop/utils/queryStringHelpers";
import clsx from "clsx";
import H from "history";

// experiments
import {
  AVAILABLE,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  HOTEL_COLOR_CALENDAR,
  HOTEL_COLOR_CALENDAR_VARIANTS,
  HOTEL_COLOR_CALENDAR_WITH_PRICING,
  STAYS_HOMEPAGE,
  useExperiments,
} from "../../../../context/experiments";
import { ClientContext } from "../../../../App";
import { useExperimentIsVariant } from "@capone/experiments";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  SEARCH_HOTELS,
  SEARCH_STAYS,
} from "../MobileHotelSearchControlV2/textConstants";

export interface IHotelSearchControlProps
  extends HotelSearchControlConnectorProps {
  recentSearches?: RecentHotelSearch[];
  onRecentSearchClick?: (search: RecentHotelSearch) => void;
  showPreferencesBanner?: boolean;
}

export const HotelSearchControl = (props: IHotelSearchControlProps) => {
  const {
    className,
    onSearch,
    displaySearchOnChange = false,
    location,
    fromDate,
    untilDate,
    adultsCount,
    children,
    showTravelerSelection = true,
    showSearchButton = true,
    saveDatesOnClose,
    resetFilters,
    roomsCount,
    petsCount,
    hideCalendarSeparator = false,
    showTotalTravelers,
    recentSearches,
    onRecentSearchClick,
    isViewHotelsNear,
    isSearchMap,
  } = props;
  const [hasDatesChanged, setHasDatesChanged] = React.useState<boolean>(false);
  const [hasLocationChanged, setHasLocationChanged] =
    React.useState<boolean>(false);
  const [hasMissingSearchInfoError, setHasMissingSearchInfoError] =
    React.useState(false);
  const [isPolicyModalOpen, setIsPolicyModalOpen] = React.useState(false);

  const { policies } = useContext(ClientContext);

  const expState = useExperiments();

  const isFirstUpdate = React.useRef<boolean>(true);
  const prevFrom = usePrevious(fromDate);
  const prevUntil = usePrevious(untilDate);
  const prevLocation = usePrevious(location);

  const getSelectedOption = (option: IResult | null, value: IResult | null) => {
    const selection = value ? (value.id as IIdLodgings) : null;
    const opt = option ? (option.id as IIdLodgings) : null;
    return (
      !!selection &&
      !!opt &&
      selection.lodgingSelection.placeId === opt.lodgingSelection.placeId
    );
  };

  const colorCalendarExperimentVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    HOTEL_COLOR_CALENDAR,
    HOTEL_COLOR_CALENDAR_VARIANTS
  );

  const isPolicyDescriptorsEnabled = useExperimentIsVariant(
    "corp-admin-policy-descriptors",
    "available"
  );

  const staysHomepageEnabled =
    getExperimentVariant(expState.experiments, STAYS_HOMEPAGE) === AVAILABLE;

  const searchButtonMessage = () => {
    return staysHomepageEnabled ? SEARCH_STAYS : SEARCH_HOTELS;
  };

  React.useEffect(() => {
    if (location && fromDate && untilDate) {
      const hasChangedDates = fromDate !== prevFrom && untilDate !== prevUntil;
      const hasChangedLocation =
        (prevLocation?.id as IIdLodgings)?.lodgingSelection?.placeId !==
        (location?.id as IIdLodgings)?.lodgingSelection?.placeId;
      // skip the first update
      if (isFirstUpdate.current) {
        isFirstUpdate.current = false;
      } else if (hasChangedDates) {
        setHasDatesChanged(true);
      } else if (hasChangedLocation) {
        setHasLocationChanged(true);
      }
    }
  }, [location, fromDate, untilDate, adultsCount, children]);

  React.useEffect(() => {
    setHasLocationChanged(false);
  }, [isViewHotelsNear, isSearchMap]);

  const isReadyToSearch = !!location && !!fromDate && !!adultsCount;

  const handleSearch = (history: H.History) => {
    const path = staysHomepageEnabled
      ? PATH_STAYS_AVAILABILITY
      : PATH_AVAILABILITY;

    history.push(
      `${path}${transformToStringifiedAvailabilityQuery(
        (location?.id as IIdLodgings).lodgingSelection.searchTerm,
        fromDate,
        untilDate,
        adultsCount,
        children,
        roomsCount,
        petsCount
      )}`
    );

    onSearch && onSearch(history, false);
    setHasDatesChanged(false);
    setHasLocationChanged(false);
    resetFilters();
  };
  const handleSearchClick = (history: H.History) => {
    isReadyToSearch
      ? handleSearch(history)
      : setHasMissingSearchInfoError(true);
  };

  React.useEffect(() => {
    if (isReadyToSearch) {
      setHasMissingSearchInfoError(false);
    }
  }, [fromDate, untilDate, adultsCount]);

  const onShowPolicyDetailsModal = () => {
    setIsPolicyModalOpen(true);
    trackEvent({
      eventName: VIEWED_POLICY_MODAL,
      properties: {
        type: POLICY_MODAL,
        entry_point: ModalScreens.HOTELS_SEARCH,
        funnel: "hotels",
      },
    });
  };

  return (
    <Box className={clsx("hotel-search-control-root", className, "multiroom")}>
      <Box className={"hotel-search-control-row"}>
        <Box className="hotel-search-inputs">
          {isPolicyDescriptorsEnabled && showSearchButton && (
            <>
              <PolicyModalButton
                policies={policies}
                onClick={onShowPolicyDetailsModal}
              />
              <PolicyDetailsModal
                policies={policies}
                isOpen={isPolicyModalOpen}
                setIsOpen={setIsPolicyModalOpen}
                productType="hotel"
              />
            </>
          )}
          <Box className={"hotel-search-input-row"}>
            <LocationAutocomplete
              className={clsx("destination-auto-complete", "b2b")}
              label={textConstants.LOCATION_AUTOCOMPLETE_PLACEHOLDER}
              getOptionSelected={getSelectedOption}
              customIcon={
                <Icon
                  name={IconName.B2BMapPin}
                  ariaLabel=""
                  aria-hidden={true}
                />
              }
              hasMissingSearchInfoError={hasMissingSearchInfoError && !location}
              recentSearches={recentSearches}
              onRecentSearchClick={
                onRecentSearchClick
                  ? (recentSearch) =>
                      onRecentSearchClick(recentSearch as RecentHotelSearch)
                  : undefined
              }
            />
            <Box className={"date-input"}>
              <CalendarPickerButton
                saveDatesOnClose={saveDatesOnClose}
                hasMissingSearchInfoError={
                  hasMissingSearchInfoError && !fromDate && !untilDate
                }
                hideSeparator={hideCalendarSeparator}
                showCalenderPricingColors={
                  colorCalendarExperimentVariant !== CONTROL
                }
                showCalendarPricing={
                  colorCalendarExperimentVariant ===
                  HOTEL_COLOR_CALENDAR_WITH_PRICING
                }
              />
            </Box>
            {showTravelerSelection && (
              <>
                <div className={"occupancy-selection"}>
                  <OccupancySelection
                    showTotalTravelers={showTotalTravelers}
                    showPets={staysHomepageEnabled}
                  />
                </div>
              </>
            )}
          </Box>
        </Box>
        {showSearchButton &&
          (!displaySearchOnChange || hasLocationChanged || hasDatesChanged) && (
            <HotelSearchButton
              className={clsx("hotel-search-control-button", "b2b")}
              message={searchButtonMessage()}
              onClick={(history) => {
                handleSearchClick(history);
              }}
              enabled={true}
            />
          )}
      </Box>
      {hasMissingSearchInfoError && (
        <Box className="missing-info-search-error-container">
          <NotificationBanner
            className={clsx("missing-info-search-error-banner")}
            label={textConstants.MISSING_INFO_SEARCH_ERROR}
            severity={BannerSeverity.ERROR}
            icon={<Icon name={IconName.WarningAlert} />}
          />
        </Box>
      )}
    </Box>
  );
};
