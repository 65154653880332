import { Cfar } from "redmond";
import { getTotalPriceText } from "halifax";

export const BOOK_BUTTON_TEXT = "Confirm and Book";
export const REQUIRED_DETAILS_TEXT = "Enter all required details";
export const CONTACT_INFO_TITLE = "Step 2: Contact Information";
export const CONTACT_INFO_TITLE_NO_STEP = (isAmadeusMultiroom?: boolean) =>
  isAmadeusMultiroom
    ? "Primary contact information"
    : "Your Contact Information";
export const CONTACT_INFO_SUBTITLE =
  "This information is used to notify you about any updates or changes to your trip.";
export const CONTACT_INFO_SAVE = "Save Contact Information";
export const CONTACT_INFO_HELPER_TEXT =
  "You must add one traveler and enter your contact information to continue.";
export const REVIEW_MY_TRIP_TEXT = "Review my Trip";
export const NEED_TO_KNOW_TITLE = "Need to know";
export const NEED_TO_KNOW_DETAILS =
  "All rooms are guaranteed to accommodate 2 guests. Additional guests are at the discretion of the hotel and may be subject to extra fees. At check-in, the hotel may place a temporary hold on your credit card for incidentals.";

export const getCfarSecondaryText = (cfarCancellation: Cfar) =>
  `Refund amount: ${getTotalPriceText({
    price: cfarCancellation.refundAmount.fiat,
  })}`;
export const TRAVELER_INFO_TITLE = "Step 1: Traveler Information";

export const TREES_MODAL_TITLE = "How it works";
export const TREES_MODAL_SUBTITLE =
  "For every <span class='font-bold'>flight, hotel</span> or <span class='font-bold'>car rental</span> you book, together with our partners, we <span class='font-bold'>plant 4 trees</span> to help with forest restoration programs.";
export const TREES_MODAL_HEADER =
  "When you book, <span class='font-regular'>you plant trees.</span>";
export const TREES_MODAL_LOCATION = "Banff, Alberta";
export const TREES_MODAL_CTA_TEXT = "will be planted for this booking! ";
export const TREES_BOLDED_MODAL_CTA_TEXT = "4 trees ";
export const AGENT_DETAILS_MODAL_TEXT = "View Hotel Information";
