import { put, putResolve, select, call } from "redux-saga/effects";
import {
  Lodging,
  ShopRequest,
  AvailabilityRequestEnum,
  AvailabilityRequest,
  PlatformEnum,
  LodgingSelection,
  LodgingSelectionEnum,
  ShopResponseEnum,
  IShopResponseBase,
  IShopResponseAvailable,
  AvailabilityResponseEnum,
  AvailabilityResponse,
  CallState,
  HotelEntryTypeEnum,
  LocationQueryEnum,
  IResponse,
  ILocationQueryLabel,
  IResult,
  HotelDetailsEntrySourceEnum,
  LodgingTokenResponse,
  LodgingCollectionEnum,
  LodgingTokenRequest,
} from "redmond";
import dayjs from "dayjs";
import queryStringParser from "query-string";

import { IStoreState } from "../../../reducers/types";
import Logger from "../../../utils/logger";
import { fetchHotelShop } from "../../../api/v0/shop/fetchHotelShop";
import { HotelShopCallState, HotelShopCallError } from "../reducer/state";
import { getHotelShopSelectedLodging } from "../reducer/selectors";
import {
  getAdultsCount,
  getChildren,
  getFromDate,
  getLocation,
  getRoomsCount,
  getUntilDate,
} from "../../search/reducer";
import { actions as searchActions } from "../../search/actions";
import { actions as availabilityActions } from "../../availability/actions";
import { actions } from "../actions";
import { isMobile } from "../../../utils/userAgent";
import { IHotelShopParsedQuery } from "../utils/queryStringHelpers";
import { fetchHotelAvailability } from "../../../api/v0/availability/fetchHotelAvailability";
import { fetchLocationAutocomplete } from "../../../api/v0/search/fetchLocationAutocomplete";
import { getSelectedLodgingIndex } from "../../availability/reducer";
import { hotelStarRatingToNumber } from "../utils/hotelStarRatingToNumber";
import { trackEngagementEvent } from "../../../api/v0/engagement-data/trackEngagementEvent";
import { getRewardsAccountsReferenceIds } from "../../rewards/reducer";
import { fetchCacheLodgingToken } from "../../../api/v0/shop/fetchCacheLodgingToken";
import { localCache } from "@capone/common";

const DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY = 1;
const DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN = 2;

export default function* fetchHotelShopSaga(
  fetchHotelShopAction: actions.IFetchHotelShop
) {
  try {
    const {
      selectedAvailability,
      locationSearched,
      shopToken,
    }: {
      selectedAvailability?: Lodging;
      locationSearched?: string;
      shopToken?: string;
    } = yield call(setUpHotelShopParameters, fetchHotelShopAction);
    const shopRequestId = selectedAvailability?.price?.opaqueShopRequest;
    const state: IStoreState = yield select();

    const fromDate = getFromDate(state);
    const untilDate = getUntilDate(state);

    if (!selectedAvailability?.available) {
      yield put(
        actions.setHotelShopCallStateFailed({
          hotelShopCallError: HotelShopCallError.NoAvailability,
        })
      );
      return;
    }

    if (!shopRequestId && !shopToken) {
      throw new Error("Shop Request Id must be present.");
    }

    const locationSearchTerm =
      !!fetchHotelShopAction.options?.includeLocationSearchTerm &&
      !!locationSearched
        ? locationSearched
        : undefined;

    const requestBody: ShopRequest = {
      opaqueRequest: shopToken ? shopToken : shopRequestId!,
      locationSearchTerm:
        typeof locationSearchTerm === "string" ? locationSearchTerm : undefined,
    };

    const response: IShopResponseBase = yield fetchHotelShop(requestBody);

    if (response.ShopResponse === ShopResponseEnum.Unavailable) {
      yield put(
        actions.setHotelShopCallStateFailed({
          hotelShopCallError: HotelShopCallError.NoAvailability,
        })
      );
      return;
    }
    if (response.ShopResponse === ShopResponseEnum.Failure) {
      yield put(
        actions.setHotelShopCallStateFailed({
          hotelShopCallError: HotelShopCallError.Unknown,
        })
      );
      return;
    }
    const availableResponse = response as IShopResponseAvailable;
    yield putResolve(
      actions.setHotelShopResults({
        hotelShopCallState: HotelShopCallState.Success,
        payload: availableResponse,
      })
    );
    const accountReferenceIds: string[] = getRewardsAccountsReferenceIds(state);

    trackEngagementEvent({
      event: {
        event_type: "hotel_viewed",
        lodging_id: availableResponse.lodgingId,
        lodging_name: selectedAvailability.lodging.name,
        lowest_room_price:
          availableResponse.roomInfoProducts[0].products[0].nightlyDiscountAware
            .priceWithUnmanagedDiscounts.fiat.value ??
          availableResponse.roomInfoProducts[0].products[0].perNightSellRate
            .fiat.value,
        lodging_country: selectedAvailability.lodging.country,
        lodging_city: selectedAvailability.lodging.city,
        check_in_date_timestamp: dayjs(fromDate).valueOf(),
        check_out_date_timestamp: dayjs(untilDate).valueOf(),
        coordinates: selectedAvailability.lodging.location.coordinates,
        account_reference_ids: accountReferenceIds,
      },
    });

    if (fetchHotelShopAction.options?.fetchSimilarHotels) {
      const {
        parsedQueryStringFromDate,
        parsedQueryStringUntilDate,
        parsedQueryStringAdultsCount,
        parsedQueryStringChildren,
        parsedQueryStringLodgingSelection,
        parsedQueryStringRoomsCount,
      } = parseQueryString(fetchHotelShopAction, dayjs());

      if (parsedQueryStringLodgingSelection) {
        let similarHotelsRequestBody: AvailabilityRequest = {
          lodgingSelection: parsedQueryStringLodgingSelection,
          stayDates: {
            from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
            until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
          },
          guests: {
            adults: parsedQueryStringAdultsCount,
            children: parsedQueryStringChildren,
          },
          platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
          progressiveConfig: {
            pageSize: 60,
          },
          AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        };
        if (parsedQueryStringRoomsCount) {
          similarHotelsRequestBody = {
            lodgingSelection: parsedQueryStringLodgingSelection,
            stayDates: {
              from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
              until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
            },
            guests: {
              adults: parsedQueryStringAdultsCount,
              children: parsedQueryStringChildren,
            },
            platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
            progressiveConfig: {
              pageSize: 60,
            },
            AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
            rooms: {
              numberOfRooms: parsedQueryStringRoomsCount,
            },
          };
        }

        yield put(
          actions.setSimilarHotelsAvailabilityCallState(CallState.InProcess)
        );

        const similarHotelsAvailabilityResponse:
          | AvailabilityResponse
          | undefined = yield fetchHotelAvailability(similarHotelsRequestBody);

        const similarHotels = similarHotelsAvailabilityResponse?.lodgings
          .filter((lodging) => {
            if (
              (lodging.available !== undefined && !lodging.available) ||
              !lodging.price
            ) {
              return false;
            }

            const isNotTheSameLodging =
              lodging.lodging.id !== selectedAvailability?.lodging.id;

            const isSameOrHigherStarRating =
              hotelStarRatingToNumber(lodging.lodging.starRating) >=
              hotelStarRatingToNumber(selectedAvailability?.lodging.starRating);

            const isSameOrLowerPricePerNight =
              (lodging.price?.nightlyDiscountAware?.priceWithUnmanagedDiscounts
                ?.fiat?.value || 0) <=
              (selectedAvailability?.price?.nightlyDiscountAware
                ?.priceWithUnmanagedDiscounts?.fiat?.value || 0);

            return (
              isNotTheSameLodging &&
              isSameOrHigherStarRating &&
              isSameOrLowerPricePerNight
            );
          })
          .slice(0, 5);

        yield put(
          actions.setSimilarHotels(
            similarHotels || [],
            similarHotelsAvailabilityResponse?.trackingPropertiesV2 || null
          )
        );
      }
    }
  } catch (e) {
    Logger.debug(e);
    yield put(
      actions.setHotelShopCallStateFailed({
        hotelShopCallError: HotelShopCallError.Unknown,
      })
    );
  }
}

function sanitizeSortOrder(sortOrder: string | undefined): string | undefined {
  switch (sortOrder) {
    case "Recommended":
      return "Recommended";
    case "Pricing":
      return "Pricing";
    case "StarRating":
      return "StarRating";
    default:
      return undefined;
  }
}

function parseQueryString(
  fetchHotelShopAction: actions.IFetchHotelShop,
  now: dayjs.Dayjs
) {
  const queryString = fetchHotelShopAction.history.location.search;
  const parsedQueryStringPrimitive = queryStringParser.parse(queryString);

  const childrenArray = Array.isArray(parsedQueryStringPrimitive.children)
    ? parsedQueryStringPrimitive.children.map((age) => parseInt(age, 10))
    : parsedQueryStringPrimitive.children
    ? [parseInt(parsedQueryStringPrimitive.children, 10)]
    : [];

  const parsedQueryString: IHotelShopParsedQuery = {
    lodgingId: parsedQueryStringPrimitive.lodgingId as string,
    fromDate: parsedQueryStringPrimitive.fromDate as string,
    untilDate: parsedQueryStringPrimitive.untilDate as string,
    adultsCount: Number(parsedQueryStringPrimitive.adultsCount),
    children: childrenArray,
    selectedLodgingIndex: Number(
      parsedQueryStringPrimitive.selectedLodgingIndex
    ),
    roomsCount: Number(parsedQueryStringPrimitive.roomsCount),
    entryPoint: parsedQueryStringPrimitive.entryPoint as HotelEntryTypeEnum,
    preferencesRecommended:
      parsedQueryStringPrimitive.preferencesRecommended === "true",
    hotelDetailsEntrySource:
      parsedQueryStringPrimitive.hotelDetailsEntrySource as HotelDetailsEntrySourceEnum,
    shopToken: parsedQueryStringPrimitive.shopToken
      ? (parsedQueryStringPrimitive.shopToken as string)
      : undefined,
    sortOrder: parsedQueryStringPrimitive.sortOrder as string,
  };

  try {
    parsedQueryString.lodgingSelection = JSON.parse(
      decodeURIComponent(parsedQueryStringPrimitive.lodgingSelection as string)
    ) as LodgingSelection;
  } catch (e) {
    Logger.debug(e);
  }

  const currentDate = dayjs().toDate();
  currentDate.setHours(0);
  currentDate.setMinutes(0);
  currentDate.setSeconds(0);
  currentDate.setMilliseconds(0);

  const parsedQueryStringFromDate: Date =
    parsedQueryString.fromDate &&
    dayjs(parsedQueryString.fromDate).toDate() >= currentDate
      ? dayjs(parsedQueryString.fromDate).toDate()
      : now
          .add(DEFAULT_CHECKIN_DATE_OFFSET_IN_DAYS_FROM_TODAY, "days")
          .startOf("day")
          .toDate();
  const parsedQueryStringUntilDate: Date =
    parsedQueryString.untilDate &&
    dayjs(parsedQueryString.untilDate).toDate() > parsedQueryStringFromDate
      ? dayjs(parsedQueryString.untilDate).toDate()
      : dayjs(parsedQueryStringFromDate)
          .add(DEFAULT_CHECKOUT_DATE_OFFSET_IN_DAYS_FROM_CHECKIN, "days")
          .toDate();
  const getParsedSearchTerm = () => {
    try {
      return JSON.parse(parsedQueryStringPrimitive.lodgingSelection as string)
        .searchTerm;
    } catch {
      return "";
    }
  };

  const parsedQuerySortOrder: string | undefined = sanitizeSortOrder(
    parsedQueryString.sortOrder
  );

  return {
    parsedQueryString,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount: parsedQueryString.adultsCount,
    parsedQueryStringChildren: parsedQueryString.children,
    parsedQueryStringSelectedLodgingIndex:
      parsedQueryString.selectedLodgingIndex,
    parsedQueryStringLodgingSelection: parsedQueryString.lodgingSelection,
    parsedQueryStringRoomsCount: parsedQueryString.roomsCount,
    parsedSearchTerm: getParsedSearchTerm(),
    parsedQueryStringShopToken: parsedQueryString.shopToken,
    parsedQuerySortOrder: parsedQuerySortOrder,
  };
}

function* setUpHotelShopParameters(
  fetchHotelShopAction: actions.IFetchHotelShop
) {
  const state: IStoreState = yield select();

  let selectedAvailability: Lodging = yield getHotelShopSelectedLodging(state);
  let locationSearched: string = yield getLocation(state);
  let fromDate: Date = yield getFromDate(state);
  let untilDate: Date = yield getUntilDate(state);
  const adultsCount: number = yield getAdultsCount(state);
  const children: number = yield getChildren(state);
  const selectedLodgingIndex: number = yield getSelectedLodgingIndex(state);
  const roomsCount: number = yield getRoomsCount(state);
  let lodgingToken: string | undefined;

  const { history } = fetchHotelShopAction;
  const now = dayjs();
  let {
    parsedQueryString,
    parsedQueryStringFromDate,
    parsedQueryStringUntilDate,
    parsedQueryStringAdultsCount,
    parsedQueryStringChildren,
    parsedQueryStringSelectedLodgingIndex,
    parsedQueryStringLodgingSelection,
    parsedQueryStringRoomsCount,
    parsedSearchTerm,
    parsedQueryStringShopToken,
    parsedQuerySortOrder,
  } = parseQueryString(fetchHotelShopAction, now);

  if (!fetchHotelShopAction.options?.overrideStateByQueryParams) {
    if (
      selectedAvailability &&
      fromDate &&
      untilDate &&
      (parsedQueryString.lodgingId !== selectedAvailability.lodging.id ||
        parsedQueryStringFromDate !== fromDate ||
        parsedQueryStringUntilDate !== untilDate)
    ) {
      if (parsedQueryStringRoomsCount) {
        history.replace({
          ...history.location,
          search: queryStringParser.stringify({
            lodgingId: selectedAvailability.lodging.id,
            fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
            untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
            adultsCount,
            children,
            selectedLodgingIndex,
            lodgingSelection: encodeURIComponent(
              JSON.stringify(parsedQueryStringLodgingSelection)
            ),
            roomsCount,
            ...(parsedQueryStringShopToken
              ? { shopToken: parsedQueryStringShopToken }
              : {}),
          }),
        });
      } else {
        history.replace({
          ...history.location,
          search: queryStringParser.stringify({
            lodgingId: selectedAvailability.lodging.id,
            fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
            untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
            adultsCount,
            children,
            selectedLodgingIndex,
            lodgingSelection: encodeURIComponent(
              JSON.stringify(parsedQueryStringLodgingSelection)
            ),
            ...(parsedQueryStringShopToken
              ? { shopToken: parsedQueryStringShopToken }
              : {}),
          }),
        });
      }

      ({
        parsedQueryString,
        parsedQueryStringFromDate,
        parsedQueryStringUntilDate,
        parsedQueryStringAdultsCount,
        parsedQueryStringChildren,
        parsedQueryStringSelectedLodgingIndex,
        parsedQueryStringRoomsCount,
        parsedQueryStringShopToken,
        parsedQuerySortOrder,
      } = parseQueryString(fetchHotelShopAction, now));
    }
  }

  if (
    !selectedAvailability ||
    !fromDate ||
    !untilDate ||
    fetchHotelShopAction.options?.overrideStateByQueryParams ||
    fetchHotelShopAction.options?.forceCallHotelAvailability ||
    (!locationSearched &&
      fetchHotelShopAction.options?.includeLocationSearchTerm)
  ) {
    const lodgingSelection: LodgingSelection = {
      lodgingIds: [parsedQueryString.lodgingId],
      preserveOrder: true,
      LodgingSelection: LodgingSelectionEnum.LodgingIds,
    };

    let availabilityRequestBody: AvailabilityRequest = {
      lodgingSelection,
      stayDates: {
        from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
        until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
      },
      guests: {
        adults: parsedQueryStringAdultsCount,
        children: parsedQueryStringChildren,
      },
      progressiveConfig: {
        pageSize: 2,
      },
      AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
      platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
    };

    if (parsedQueryStringRoomsCount) {
      availabilityRequestBody = {
        lodgingSelection,
        stayDates: {
          from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
          until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
        },
        guests: {
          adults: parsedQueryStringAdultsCount,
          children: parsedQueryStringChildren,
        },
        progressiveConfig: {
          pageSize: 2,
        },
        AvailabilityRequest: AvailabilityRequestEnum.InitialSearch,
        platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,
        rooms: {
          numberOfRooms: parsedQueryStringRoomsCount,
        },
      };
      yield putResolve(
        searchActions.setRoomsCount(parsedQueryStringRoomsCount)
      );
      yield putResolve(
        availabilityActions.setSearchedRoomsCount(parsedQueryStringRoomsCount)
      );
    }

    fromDate = parsedQueryStringFromDate;
    untilDate = parsedQueryStringUntilDate;

    yield putResolve(
      availabilityActions.setSelectedLodgingIndex(
        parsedQueryStringSelectedLodgingIndex != null
          ? parsedQueryStringSelectedLodgingIndex
          : selectedLodgingIndex
      )
    );
    yield putResolve(searchActions.setFromDate(fromDate));
    yield putResolve(searchActions.setUntilDate(untilDate));
    yield putResolve(actions.setHotelDetailsSortOrder(parsedQuerySortOrder));
    yield putResolve(
      searchActions.setOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children: parsedQueryStringChildren,
      })
    );

    yield putResolve(availabilityActions.setSearchedDates(fromDate, untilDate));
    yield putResolve(
      availabilityActions.setSearchedOccupancyCounts({
        adults: parsedQueryStringAdultsCount,
        children: parsedQueryStringChildren,
      })
    );
    if (parsedQueryString.entryPoint) {
      yield putResolve(
        actions.setHotelShopEntryPoint(parsedQueryString.entryPoint)
      );
    }

    if (parsedQueryString.hotelDetailsEntrySource) {
      yield putResolve(
        actions.setHotelDetailsEntrySource(
          parsedQueryString.hotelDetailsEntrySource
        )
      );
    }

    yield putResolve(
      actions.setHotelShopRecommendedBasedOnPreferences(
        !!parsedQueryString.preferencesRecommended
      )
    );

    const availabilityResponse: AvailabilityResponse = yield call(
      fetchHotelAvailability,
      availabilityRequestBody
    );

    [selectedAvailability] = availabilityResponse.lodgings;
    yield putResolve(actions.selectLodging(selectedAvailability));

    if (
      availabilityResponse.AvailabilityResponse ===
      AvailabilityResponseEnum.Initial
    ) {
      yield putResolve(actions.setDateRange(availabilityResponse.dates));
    }

    if (parsedSearchTerm) {
      const locationRequestBody = {
        l: parsedSearchTerm as string,
        LocationQuery: LocationQueryEnum.Label,
      };

      const { categories: locationCategories }: IResponse =
        yield fetchLocationAutocomplete(
          locationRequestBody as ILocationQueryLabel
        );
      const correspondingLocations = locationCategories.flatMap((category) =>
        category.results.find((result) =>
          result.label.toLowerCase().includes(parsedSearchTerm.toLowerCase())
        )
      );
      const allLocations = locationCategories.flatMap(
        (category) => category.results
      );

      const updatedLocation =
        correspondingLocations.length > 0
          ? correspondingLocations[0]
          : allLocations[0];
      locationSearched = (updatedLocation as IResult)?.label;
      yield putResolve(searchActions.setLocation(updatedLocation as IResult));
    }

    if (
      parsedQueryStringShopToken &&
      fetchHotelShopAction.options?.forceCallHotelAvailability &&
      selectedAvailability.price?.opaqueShopRequest
    ) {
      history.replace({
        ...history.location,
        search: queryStringParser.stringify({
          lodgingId: selectedAvailability.lodging.id,
          fromDate: dayjs(fromDate).format("YYYY-MM-DD"),
          untilDate: dayjs(untilDate).format("YYYY-MM-DD"),
          adultsCount,
          children,
          selectedLodgingIndex,
          lodgingSelection: encodeURIComponent(
            JSON.stringify(parsedQueryStringLodgingSelection)
          ),
          ...(parsedQueryStringShopToken
            ? { shopToken: selectedAvailability.price?.opaqueShopRequest }
            : {}),
        }),
      });
      parsedQueryStringShopToken =
        selectedAvailability.price?.opaqueShopRequest;
    }

    if (fetchHotelShopAction.options?.fetchLodgingToken) {
      const tokenRequestBody: LodgingTokenRequest = {
        progressiveConfig: {
          pageSize: 2,
        },
        stayDates: {
          from: dayjs(parsedQueryStringFromDate).format("YYYY-MM-DD"),
          until: dayjs(parsedQueryStringUntilDate).format("YYYY-MM-DD"),
        },
        guests: {
          adults: parsedQueryStringAdultsCount,
          children: parsedQueryStringChildren,
        },

        platform: isMobile() ? PlatformEnum.Mobile : PlatformEnum.Desktop,

        lodgingCollection: LodgingCollectionEnum.NoCollection,
        rooms: {
          numberOfRooms: !!parsedQueryStringRoomsCount
            ? parsedQueryStringRoomsCount
            : 1,
        },
        lodgingId: parsedQueryString.lodgingId,
        opaqueShopRequest:
          parsedQueryStringShopToken ??
          selectedAvailability.price?.opaqueShopRequest ??
          "",
      };
      const tokenResponse: LodgingTokenResponse = yield call(
        fetchCacheLodgingToken,
        tokenRequestBody
      );
      lodgingToken = tokenResponse.opaqueShopRequest;

      yield putResolve(actions.setCacheLodgingTokenResponse(tokenResponse));
    } else if (parsedQueryStringShopToken) {
      lodgingToken = localCache.get(parsedQueryStringShopToken);
    }
  }

  return { selectedAvailability, locationSearched, shopToken: lodgingToken };
}
