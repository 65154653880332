import { VacationRentalAmenityKindEnum as VRAmenityKind } from "redmond";

export const VRAmenityDisplayNames: Partial<Record<VRAmenityKind, string>> = {
  [VRAmenityKind.Pool]: "Pool",
  [VRAmenityKind.Dishwasher]: "Dish washer",
  [VRAmenityKind.Parking]: "Parking included",
  [VRAmenityKind.Wifi]: "Free WiFi",
  [VRAmenityKind.AirConditioning]: "Air conditioning",
  [VRAmenityKind.PetFriendly]: "Pet friendly",
  [VRAmenityKind.Kitchen]: "Kitchen",
  [VRAmenityKind.Dryer]: "Dryer",
  [VRAmenityKind.Heating]: "Heating",
  [VRAmenityKind.DedicatedWorkspace]: "Dedicated workspace",
  [VRAmenityKind.Hdtv]: "TV",
  [VRAmenityKind.HairDryer]: "Hair dryer",
  [VRAmenityKind.Iron]: "Iron",
  [VRAmenityKind.HotTub]: "Hot tub",
  [VRAmenityKind.EvCharger]: "EV charger",
  [VRAmenityKind.Crib]: "Crib",
  [VRAmenityKind.Gym]: "Gym",
  [VRAmenityKind.BarbecueGrill]: "BBQ grill",
  [VRAmenityKind.Fireplace]: "Indoor fireplace",
  // Add other amenities as needed, these are just the ones being displayed now.
};
