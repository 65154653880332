export const NO_HOTELS_TITLE_TEXT = "No hotels found";
export const NO_STAYS_TITLE_TEXT = "No stays found";
export const NO_HOTELS_FILTER_SUBTITLE_TEXT =
  "Reset filters or adjust your dates to view more hotels.";
export const NO_STAYS_FILTER_SUBTITLE_TEXT =
  "Reset filters or adjust your dates to view more stays.";
export const NO_HOTELS_RETURNED_SUBTITLE_TEXT =
  "Adjust your dates or search again to view more hotels.";
export const NO_HOTELS_24H_REVIEW_SUBTITLE_TEXT = `There are no hotels matching your search criteria. <br /><br /> Your policy settings restrict out-of-policy bookings to refundable rates only. To see more hotels adjust your filters or talk with an admin about adjusting your policy settings.`;

export const RESET_BUTTON_TEXT = "Reset filters";
export const SEARCH_AGAIN_BUTTON_TEXT = "Search again";
export const ADJUST_DATES_BUTTON_TEXT = "Adjust dates";
