import React from "react";
import {
  AmenityEnum,
  HotelStarRatingEnum,
  VacationRentalAmenityKindEnum,
} from "redmond";
// TODO UPDATE IMPORT TO USE CORRECT PATH
import { HomeRoomsAndBeds as VacationRentalRoomsAndBeds } from "redmond/hotels-module/interfaces";
import { usePrevious } from "../../../../hooks/usePrevious";
import * as textConstants from "./textConstants";
import { mapAmenitiesToTopAmenities, pluralize } from "halifax";
import { VRAmenityDisplayNames } from "../VacationRentalAvailabilityFilter";

/*
  Hook to generate dynamic filter labels
  ratings/phc: '5 Star +2', 'Premier Collection +1', etc
  amenities: 'Free WiFi', 'Swimming Pool +1', etc
*/
export const useFilterLabelValues = ({
  starRatings,
  amenities,
  vacationRentalAmenities,
  vacationRentalRoomsAndBeds,
}: {
  starRatings: HotelStarRatingEnum[];
  amenities: AmenityEnum[];
  vacationRentalAmenities?: VacationRentalAmenityKindEnum[];
  vacationRentalRoomsAndBeds?: VacationRentalRoomsAndBeds;
}) => {
  const [ratingLabelValues, setRatingLabelValues] = React.useState<string[]>(
    []
  );

  const [amenityLabelValues, setAmenityLabelValues] = React.useState<
    AmenityEnum[]
  >([]);

  const previousStarRatings = usePrevious(starRatings);

  const previousAmenities = usePrevious(amenities);

  /* ratings/premier collection */

  // use the first selected filter for label (then use 2nd selected, then 3rd, etc.)
  React.useEffect(() => {
    // filter out duplicates and unselected values
    const filteredRatingLabelValues = ratingLabelValues.reduce(
      (filteredValues, currentValue) => {
        // filter out duplicates
        if (filteredValues.includes(currentValue)) {
          return filteredValues;
        }

        // include star rating if it's selected
        const ratingIsSelected = starRatings
          .map((rating) => textConstants.STAR_RATING_FILTER_LABEL(rating))
          .includes(currentValue);

        if (ratingIsSelected) {
          filteredValues.push(currentValue);
        }

        return filteredValues;
      },
      [] as string[]
    );

    if (starRatings?.length !== previousStarRatings?.length) {
      // if a star rating was selected
      setRatingLabelValues([
        ...filteredRatingLabelValues,
        textConstants.STAR_RATING_FILTER_LABEL(
          starRatings[starRatings.length - 1]
        ),
      ]);
    } else if (ratingLabelValues.length !== filteredRatingLabelValues.length) {
      // if there were duplicates or unselected values filtered out
      setRatingLabelValues(filteredRatingLabelValues);
    }
  }, [ratingLabelValues, starRatings, previousStarRatings]);

  const ratingLabel = React.useMemo(() => {
    const additionalAppliedRatingCount = starRatings.length - 1;
    return `${ratingLabelValues[0]}${
      additionalAppliedRatingCount > 0
        ? ` +${additionalAppliedRatingCount}`
        : ""
    }`;
  }, [ratingLabelValues, starRatings]);

  /* amenities */

  const amenitiesMappedToTopAmenities = React.useMemo(
    () => mapAmenitiesToTopAmenities(amenities),
    [amenities]
  );

  React.useEffect(() => {
    if (amenities?.length > previousAmenities?.length) {
      setAmenityLabelValues([
        ...amenityLabelValues,
        amenities[amenities.length - 1],
      ]);
    } else if (amenities?.length < previousAmenities?.length) {
      setAmenityLabelValues(
        amenityLabelValues.filter(
          (amenity) =>
            amenities.includes(amenity) && previousAmenities.includes(amenity)
        )
      );
    }
  }, [amenities, previousAmenities, amenityLabelValues]);

  const amenitiesLabel = React.useMemo(() => {
    return `${amenitiesMappedToTopAmenities[0]?.label}${
      amenitiesMappedToTopAmenities.length > 1
        ? ` +${amenitiesMappedToTopAmenities.length - 1}`
        : ""
    }`;
  }, [amenitiesMappedToTopAmenities]);

  /* vacation rental amenities */

  const vacationRentalAmenitiesLabel = React.useMemo(() => {
    if (vacationRentalAmenities && vacationRentalAmenities.length > 0) {
      return `${VRAmenityDisplayNames[vacationRentalAmenities[0]]}${
        vacationRentalAmenities.length > 1
          ? ` +${vacationRentalAmenities.length - 1}`
          : ""
      }`;
    }
    return "";
  }, [vacationRentalAmenities]);

  /* vacation rental rooms and beds */

  const vacationRentalRoomsAndBedsLabel = React.useMemo(() => {
    if (vacationRentalRoomsAndBeds) {
      const { numberOfBedrooms, numberOfBeds, numberOfBathrooms } =
        vacationRentalRoomsAndBeds;

      const labelParts = [];
      if (!!numberOfBedrooms) {
        labelParts.push(
          `${numberOfBedrooms} ${pluralize(
            numberOfBedrooms,
            "bedroom",
            "bedrooms"
          )}`
        );
      }
      if (!!numberOfBeds) {
        labelParts.push(
          `${numberOfBeds} ${pluralize(numberOfBeds, "bed", "beds")}`
        );
      }
      if (!!numberOfBathrooms) {
        labelParts.push(
          `${numberOfBathrooms} ${pluralize(
            numberOfBathrooms,
            "bathroom",
            "bathrooms"
          )}`
        );
      }
      if (labelParts.length > 0) {
        return labelParts.join(", ");
      }
    }
    return "";
  }, [vacationRentalRoomsAndBeds]);

  return {
    ratingLabel,
    amenitiesLabel,
    vacationRentalAmenitiesLabel,
    vacationRentalRoomsAndBedsLabel,
  };
};
