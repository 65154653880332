import { connect } from "react-redux";
import { IResult } from "redmond";
import { B2BSearchAutocomplete, IB2BSearchAutocompleteProps } from "halifax";
import { IStoreState } from "../../../../reducers/types";

import { actions } from "../../actions";
import {
  getViewHotelsNearLocation,
  getViewHotelsNearLocationCategories,
  getViewHotelsNearLocationCategoriesLoading,
} from "../../reducer/selectors";

export const mapDispatchToProps = {
  setValue: actions.setViewHotelsNearLocation,
  fetchValueCategories: actions.fetchViewHotelsNearLocationCategories,
};

export const mapStateToProps = (
  state: IStoreState,
  ownProps: { isMobile: boolean; clearValue: boolean }
) => {
  let loading = !!getViewHotelsNearLocationCategoriesLoading(state);

  return {
    value: ownProps.clearValue ? null : getViewHotelsNearLocation(state),
    valueCategories: getViewHotelsNearLocationCategories(state),
    disabled: ownProps.isMobile,
    loading,
  };
};

export const ConnectedViewHotelsNearLocationAutocomplete = connect(
  mapStateToProps,
  mapDispatchToProps
)(
  B2BSearchAutocomplete as (
    props: IB2BSearchAutocompleteProps<IResult | null>
  ) => JSX.Element
);
