import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";
import {
  CorpLodging,
  CorpPriceQuote,
  CorpReservation,
  CorpRoomInfoProducts,
  CorpRoomProduct,
} from "redmond";
import {
  setTripPurpose,
  setUserSelectedTravelersList,
  updateUserPassenger,
  updateUserPassengersMultiple,
} from "../../../actions/actions";

import { IStoreState } from "../../../../../reducers/types";
import { CorpDesktopHotelBookWorkflow } from "./component";
import {
  getUserSelectedTravelerId,
  getUserPassengers,
  getOpaquePayments,
  getPriceQuoteRequest,
  getHotelBookChosenProduct,
  getHotelBookChosenRoomInfo,
  getConfirmationDetails,
  getPriceQuote,
  getHotelBookSelectedAvailability,
  getUserSelectedTravelersList,
} from "../../../reducer";
import {
  getHotelShopCancellationSummary,
  getLoyaltyNumberForChosenProduct,
} from "../../../../shop/reducer/selectors";

import {
  mapStateToProps as baseMapStateToProps,
  mapDispatchToProps as baseMapDispatchToProps,
} from "../../capone/DesktopHotelBookWorkflow/container";
import { hasCfarAttached } from "../../../../ancillary/reducer";

const mapStateToProps = (state: IStoreState) => {
  const travelers = getUserPassengers(state);
  const selectedTravelerId = getUserSelectedTravelerId(state);
  const selectedTraveler = travelers.find(
    (traveler) => traveler.id === selectedTravelerId
  );

  return {
    ...baseMapStateToProps(state),
    chosenProduct: getHotelBookChosenProduct(state) as CorpRoomProduct,
    roomInfoProduct: getHotelBookChosenRoomInfo(state) as CorpRoomInfoProducts,
    reservation: getConfirmationDetails(state) as CorpReservation,
    priceQuote: getPriceQuote(state) as CorpPriceQuote,
    selectedLodging: getHotelBookSelectedAvailability(state) as CorpLodging,
    selectedTravelerId,
    selectedTraveler,
    opaquePayments: getOpaquePayments(state),
    priceQuoteRequest: getPriceQuoteRequest(state),
    selectedTravelerLoyaltyNumber: getLoyaltyNumberForChosenProduct(state),
    selectedTravelersList: getUserSelectedTravelersList(state),
    cancellationSummary: getHotelShopCancellationSummary(state),
    hasCfarAttached: hasCfarAttached(state),
  };
};

const mapDispatchToProps = {
  ...baseMapDispatchToProps,
  updateUserPassenger,
  updateUserPassengersMultiple,
  setUserSelectedTravelersList,
  setTripPurpose,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type CorpDesktopHotelBookWorkflowConnectorProps = ConnectedProps<
  typeof connector
>;

export const CorpConnectedDesktopHotelBookWorkflow = withRouter(
  connector(CorpDesktopHotelBookWorkflow)
);
