export const apiVersionPrefix: string = "/api/v0";

export const locationAutocompleteApiPrefix: string = `${apiVersionPrefix}/autocomplete/lodging`;
export const hotelAvailabilityApiPrefix: string = `${apiVersionPrefix}/hotels/availability`;
export const hotelShopApiPrefix: string = `${apiVersionPrefix}/hotels/shop`;
export const cacheLodgingTokenApiPrefix: string = `${apiVersionPrefix}/cache/lodgingToken`;
export const passengersApiPrefix: string = `${apiVersionPrefix}/passengers`;
export const paymentMethodsApiPrefix: string = `${apiVersionPrefix}/paymentMethods`;
export const rewardsApiPrefix: string = `${apiVersionPrefix}/rewards`;
export const hotelBookApiPrefix: string = `${apiVersionPrefix}/payment`;
export const fetchUserInfoPath: string = `${apiVersionPrefix}/userInfo`;
export const customerDetailsApiPath: string = `${apiVersionPrefix}/customer/details`;
export const analyticsApiPrefix: string = `${apiVersionPrefix}/tracking`;

// Stays
export const staysAvailabilityApiPrefix: string = `${apiVersionPrefix}/stays/availability`;

export const analyticsEventApi: string = `${analyticsApiPrefix}/event`;
export const experimentsApiPrefix: string = `${apiVersionPrefix}/experiments`;

export const userApiPrefix = `${apiVersionPrefix}/user`;
export const travelWalletApiPrefix = `${userApiPrefix}/wallet`;

export const calendarPrefix = `${apiVersionPrefix}/calendar`;
export const calendarHotelsPriceApi = `${calendarPrefix}/hotels/price`;

// Price Freeze
export const hotelPriceFreezePrefix: string = `${apiVersionPrefix}/hotels/pricefreeze`;
export const fetchHotelPriceFreezeDetail = `${hotelPriceFreezePrefix}/getDetail`;
export const refundHotelPriceFreezeApiPath = `${hotelPriceFreezePrefix}/refund`;
export const getHotelPriceFreezeCreditsApiPath = `${hotelPriceFreezePrefix}/getCredits`;

// Corporate travel
export const pendingRequestApiPrefix = `${apiVersionPrefix}/pendingRequests/hotel`;
export const fetchPolicies = `${apiVersionPrefix}/policies/details`;
export const hotelLoyaltyApiPrefix = `${apiVersionPrefix}/corporate/hotelLoyalty`;

// User preferences
export const userPreferenceApiPrefix: string = `${apiVersionPrefix}/user/preference`;
export const userHotelPreferencesPath: string = `${userPreferenceApiPrefix}/hotel`;

// Engagement data stream
export const engagementDataApiPrefix: string = `${apiVersionPrefix}/engagement`;
export const trackEngagementDataPath: string = `${engagementDataApiPrefix}/event`;
