import React, {
  useContext,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useExperimentIsVariant } from "@capone/experiments";
import clsx from "clsx";

// components
import { Box, Button, Typography, Divider } from "@material-ui/core";
import {
  IContactInfo,
  TravelerSelectStep,
  HotelNeedToKnowPanel,
  ContactInfoForm,
  DesktopPopupModal,
  RoomDetails,
  CheckoutStepper,
  getCancellationPolicyInfo,
  ButtonWrap,
  emailRegex,
  phoneRegex,
  ActionButton,
  ActionLink,
  IconName,
  Icon,
  InformationalModal as TreesModal,
  PolicyBanner,
  ApprovalBanner,
  ApprovalReasonTextarea,
  TreesConfirmation,
  VoidWindowNotice,
  TripPurposePanel,
  handleUpdateSelectedTravelersList,
} from "halifax";
import { RouteComponentProps } from "react-router";
import {
  ADD_CONTACT_INFO,
  CallState,
  CheckInPolicy,
  HOTEL_SAVE_AND_CONTINUE_CLICKED,
  ITravelerStepErrors,
  CorpSessionInfo,
  IPerson,
  ViewedCorpRateDescriptorEntryPoints,
  CancellationReason,
  CancellationPolicyEnum,
  HotelBookType,
} from "redmond";
import queryStringParser from "query-string";
import {
  DO_NOT_APPLY_REWARDS_KEY,
  getHotelLoyaltyProgram,
  useShowPolicyBanner,
} from "@capone/common";
import { useSelector } from "react-redux";
import {
  PriceBreakdown,
  HotelBookPassengerSelection,
  HotelBookSummaryPanel,
  AGENT_FEE,
  PaymentCard,
} from "../../index";
import { LoyaltyInformationForm } from "../LoyaltyInformationForm";
import { DesktopShop } from "../../../../shop/components/DesktopShop";
import { TravelOfferSelection } from "../../TravelOfferSelection";

// types, constants
import { CorpDesktopHotelBookWorkflowConnectorProps } from "./container";
import {
  BOOK_BUTTON_TEXT,
  CONTACT_INFO_SUBTITLE,
  CONTACT_INFO_TITLE_NO_STEP,
  CONTACT_INFO_HELPER_TEXT,
  NEED_TO_KNOW_TITLE,
  NEED_TO_KNOW_DETAILS,
  REQUIRED_DETAILS_TEXT,
  getCfarSecondaryText,
  TRAVELER_INFO_TITLE,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
  TREES_BOLDED_MODAL_CTA_TEXT,
  AGENT_DETAILS_MODAL_TEXT,
} from "./textConstants";

// context
import { ClientContext } from "../../../../../App";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
} from "../../../../../context/experiments";
import { submitLoyaltyKustomerTicket } from "../../../../../api/v0/book/book-flow/submitLoyaltyKustomerTicket";

// helpers, utils
import { fetchCustomerDetails } from "../../../../../api/v0/customer/fetchCustomerDetails";
import { PATH_BOOK_CONFIRMATION, PATH_HOME } from "../../../../../utils/paths";
import { trackEvent } from "../../../../../api/v0/analytics/trackEvent";

// styles
import { getHotelShopChosenProductHotelLoyaltyProgramCode } from "../../../../shop/reducer/selectors";
import "./styles.scss";
import {
  CALIFORNIA_BILL_644_CANCELLATION_TEXT,
  CHECK_IN_INSTRUCTIONS_TITLE,
  formattedCheckIn,
  formattedCheckOut,
} from "../../capone/DesktopHotelBookWorkflow/textConstants";
import { HotelTravelerSelectDropdownForm } from "../HotelTravelerSelectDropdownForm";
import { onOpenCompareBarTooltip } from "../../../../../utils/events";
import { CfarCancellationPolicy } from "../../../../ancillary/components/addOnComponents/cfar/CfarCancellationPolicy";
import { HotelShopProgressBar } from "../../../../shop/components";

export interface ICorpDesktopHotelBookWorkflowProps
  extends RouteComponentProps,
    CorpDesktopHotelBookWorkflowConnectorProps {}

export const CorpDesktopHotelBookWorkflow = ({
  setContactInfo,
  priceQuote,
  priceQuoteCallState,
  schedulePriceQuote,
  chosenProduct,
  selectedLodging,
  roomInfoProduct,
  chosenProductIndex,
  combinedBookingSteps,
  reservation,
  history,
  isBookingValid,
  email,
  phoneNumber,
  allStepsCompleted,
  priceDifferenceAcknowledged,
  scheduleBook,
  isTravelerStepComplete,
  isContactStepComplete,
  setSelectedPaymentMethodId,
  setSelectedRewardsAccountReferenceId,
  priceQuoteErrors,
  confirmationDetailsErrors,
  setPriceQuote,
  roomsCount,
  checkIn,
  checkOut,
  selectedTraveler,
  updateUserPassenger,
  selectedTravelerLoyaltyNumber,
  ancillaries,
  selectedTravelersList,
  setUserSelectedTravelersList,
  updateUserPassengersMultiple,
  cancellationSummary,
  hasCfarAttached,
  setTripPurpose,
  hotelBookType,
  isCustomizePageEnabled,
  isTravelWalletPaymentOnly,
}: ICorpDesktopHotelBookWorkflowProps) => {
  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, policies } = clientContext;

  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    phone: false,
    email: false,
    travelerSelect: false,
    corpPrimaryTraveler: false,
  });
  const [loyaltyNumber, setLoyaltyNumber] = useState<string>("");
  const [hasSavedLoyaltyInfo, setHasSavedLoyaltyInfo] =
    useState<boolean>(false);
  const [detailsModalOpen, setDetailsModalOpen] = useState(false);
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: phoneNumber || "",
    email: email || sessionInfo?.userInfo?.email || "",
  });
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    useState<boolean>(true);
  const [disableEditTravelerInfo, setDisableEditTravelerInfo] = useState(false);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [approvalRequestReason, setApprovalRequestReason] = useState<
    string | undefined
  >();

  const showCheckoutBreadcrumbs =
    (hotelBookType === HotelBookType.DEFAULT && isCustomizePageEnabled) ||
    hotelBookType === HotelBookType.PRICE_FREEZE_EXERCISE;
  const showCheckoutBreadcrumbsCustomizeStep =
    hotelBookType === HotelBookType.DEFAULT && isCustomizePageEnabled;

  const isTreesModalExperiment = useExperimentIsVariant(
    TREES_MODAL_EXPERIMENT,
    AVAILABLE
  );

  const isTravelWalletCreditsExperiment = useExperimentIsVariant(
    TRAVEL_WALLET_CREDITS_EXPERIMENT,
    AVAILABLE
  );

  const isCreditAndOfferStackingExperimentV1 = useExperimentIsVariant(
    CREDIT_OFFER_STACKING_V1,
    AVAILABLE
  );

  const isCaliforniaBill644Experiment = useExperimentIsVariant(
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
    AVAILABLE
  );

  const isMultiroomAmadeusEnabled = useExperimentIsVariant(
    "corp-amadeus-multiroom",
    AVAILABLE
  );

  const isTripPurposeXpEnabled = useExperimentIsVariant(
    "corp-trip-purpose",
    AVAILABLE
  );

  const showPolicyBanner = useShowPolicyBanner(policies);

  const isFirstStepCompletedInCheckout =
    isContactStepComplete && isTravelerStepComplete;

  const isLoyaltyEligible = Boolean(chosenProduct?.loyaltyProgramCode);

  const loyaltyProgramCode = useSelector(
    getHotelShopChosenProductHotelLoyaltyProgramCode
  );
  const loyaltyProgram = getHotelLoyaltyProgram(loyaltyProgramCode);

  const updateTravelerLoyaltyProgram = () => {
    if (
      !loyaltyNumber ||
      loyaltyNumber === selectedTravelerLoyaltyNumber ||
      !selectedTraveler
    ) {
      return;
    }

    if (loyaltyProgram) {
      updateUserPassenger({
        person: {
          ...selectedTraveler,
          hotelLoyalty: {
            ...selectedTraveler.hotelLoyalty,
            [loyaltyProgram.loyaltyProgramCode]: { value: loyaltyNumber },
          },
        },
      });

      setHasSavedLoyaltyInfo(true);
    }
  };

  const updateSelectedTravelersInfo = (travelersList: IPerson[]) => {
    if (isMultiroomAmadeusEnabled) {
      updateUserPassengersMultiple(travelersList);
    } else {
      updateTravelerLoyaltyProgram();
    }
  };

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const cancellationPolicyInfo = chosenProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        chosenProduct?.cancellationPolicy,
        getCfarSecondaryText
      )
    : null;

  const onBookHotel = () => {
    const queryString = queryStringParser.parse(history.location.search);
    priceDifferenceAcknowledged || !!priceQuote
      ? scheduleBook({
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          isRecommended: queryString.recommended === "true",
          ancillaries,
          loyaltyNumber,
          approvalRequestReason,
        })
      : schedulePriceQuote({
          history,
          agentFee: isAgentPortal ? AGENT_FEE : 0,
          ancillaries,
          loyaltyNumber,
        });
  };

  const corporateTravel = roomInfoProduct?.roomInfo.corporateTravel;
  const isInPolicy =
    // TODO(replat): comment back in price quote policy compliance after BE work complete
    // priceQuote?.corporateTravel.policyCompliance?.isInPolicy ??
    Boolean(corporateTravel?.policyCompliance?.isInPolicy);

  const mergedPolicies = useMemo(
    () =>
      [
        ...(chosenProduct?.policies ?? []),
        ...(priceQuote?.checkInInstructions.policies ?? []),
      ].reduce((uniquePolicies, currentPolicy) => {
        if (
          !uniquePolicies.some(({ title }) => currentPolicy.title === title)
        ) {
          uniquePolicies.push(currentPolicy);
        }
        return uniquePolicies;
      }, [] as CheckInPolicy[]),
    [chosenProduct?.policies, priceQuote?.checkInInstructions.policies]
  );

  const renderLogo = (
    <ButtonWrap
      className="logo"
      onClick={() => {
        history.push(PATH_HOME);
      }}
    >
      {clientContext.logo}
    </ButtonWrap>
  );

  const handleBookingInfoClick = useCallback(() => {
    setDetailsModalOpen(!detailsModalOpen);
  }, [detailsModalOpen]);

  const handleReasonChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>, isCharLimitError: boolean) => {
      setApprovalRequestReason(e.target.value);
      if (isCharLimitError) {
        trackEvent({
          eventName: "approval_reason_char_limit_error",
          properties: {
            funnel: "hotels",
          },
        });
      }
    },
    []
  );

  useEffect(() => {
    if (reservation) {
      // This whole block is a temporary until this ships: https://hopper-jira.atlassian.net/browse/CA-1519
      const traveller = selectedTraveler || selectedTravelersList[0];
      if (
        loyaltyProgramCode &&
        chosenProduct.providerName == "Budapest" &&
        traveller?.hotelLoyalty[loyaltyProgramCode]
      ) {
        const loyaltyKustomerTicketRequest = {
          loyaltyNumber:
            traveller?.hotelLoyalty[loyaltyProgramCode]?.value ?? "",
          reservation,
        };
        submitLoyaltyKustomerTicket(loyaltyKustomerTicketRequest).finally(
          () => {
            history.push(PATH_BOOK_CONFIRMATION);
          }
        );
      } else {
        history.push(PATH_BOOK_CONFIRMATION);
      }
    }
  }, [reservation]);

  useEffect(() => {
    if (priceQuoteErrors.length > 0 || confirmationDetailsErrors.length > 0) {
      setDisableEditTravelerInfo(false);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
  }, []);

  return (
    <Box
      className={clsx(
        "corp-desktop-hotel-book-workflow-root",
        "home-screen-redesign"
      )}
    >
      {detailsModalOpen && (
        <DesktopPopupModal
          open={detailsModalOpen}
          onClose={handleBookingInfoClick}
          className="corp-desktop-hotel-book-details-modal"
        >
          <DesktopShop hideRightColumn />
        </DesktopPopupModal>
      )}
      <CheckoutStepper
        steps={combinedBookingSteps}
        headerCopy={BOOK_BUTTON_TEXT}
        subHeaderCopy={REQUIRED_DETAILS_TEXT}
        logo={renderLogo}
        className={clsx("b2b", "combined-step")}
      />
      {showCheckoutBreadcrumbs && (
        <Box className="hotel-book-progress-bar-wrapper">
          <HotelShopProgressBar
            useCustomizeStep={showCheckoutBreadcrumbsCustomizeStep}
          />
        </Box>
      )}
      <Box
        className={clsx("desktop-hotel-book-workflow-container", {
          "include-breadcrumbs": showCheckoutBreadcrumbs,
        })}
      >
        <Box className={clsx("checkout-template-column", "left")}>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "hotel"
            )}
          >
            <HotelBookSummaryPanel
              className="b2b"
              actions={
                isAgentPortal ? (
                  <Typography
                    role="button"
                    variant="h6"
                    className="view-hotel-information"
                    onClick={handleBookingInfoClick}
                  >
                    {AGENT_DETAILS_MODAL_TEXT}
                  </Typography>
                ) : null
              }
              chosenProduct={chosenProduct}
              onViewCorpCompareBar={onOpenCompareBarTooltip(
                ViewedCorpRateDescriptorEntryPoints.HOTELS_CHECKOUT
              )}
            />
          </Box>

          {isTripPurposeXpEnabled && (
            <Box
              className={clsx(
                "checkout-template-card-content-container",
                "hotel"
              )}
            >
              <TripPurposePanel setTripPurpose={setTripPurpose} />
            </Box>
          )}

          {isTravelWalletCreditsExperiment &&
            !isCreditAndOfferStackingExperimentV1 && <TravelOfferSelection />}

          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "traveler"
            )}
          >
            <Box className={clsx("checkout-template-description")}>
              <Typography
                variant="h2"
                className={clsx("checkout-template-title")}
              >
                {TRAVELER_INFO_TITLE}
              </Typography>
            </Box>
            <Divider className="traveler-info-container-divider" />

            {isMultiroomAmadeusEnabled && (
              <HotelTravelerSelectDropdownForm
                disableEditTravelerInfo={disableEditTravelerInfo}
                setProgress={setTravelerWorkflowStep}
                selectedTravelers={selectedTravelersList}
                updateSelectedTravelersList={(traveler) =>
                  handleUpdateSelectedTravelersList(
                    traveler,
                    selectedTravelersList,
                    setUserSelectedTravelersList
                  )
                }
                removeSelectedTraveler={(traveler) =>
                  setUserSelectedTravelersList(
                    selectedTravelersList.filter((t) => t.id !== traveler.id)
                  )
                }
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                isLoyaltyEligible={isLoyaltyEligible}
              />
            )}

            {!isMultiroomAmadeusEnabled && (
              <HotelBookPassengerSelection
                disabled={disableEditTravelerInfo}
                progress={travelerWorkflowStep}
                setProgress={setTravelerWorkflowStep}
                combinedStep
                showErrors={showErrors}
                setShowErrors={setShowErrors}
                saveButtonClicked={saveButtonClicked}
                hiddenTravelerIds={
                  (clientContext.sessionInfo as CorpSessionInfo)?.corporateInfo
                    .hiddenTravelerIds ?? []
                }
              />
            )}
            <Divider className="traveler-info-container-divider" />
            <ContactInfoForm
              disabled={disableEditTravelerInfo}
              title={CONTACT_INFO_TITLE_NO_STEP(isMultiroomAmadeusEnabled)}
              subtitle={CONTACT_INFO_SUBTITLE}
              contactInfo={contactInfo}
              onContactInfoChange={(info: IContactInfo) => {
                setContact(info);
                setContactInfo(info.email, info.phoneNumber);
                if (
                  info.email &&
                  info.phoneNumber &&
                  emailRegex.test(info.email) &&
                  phoneRegex.test(info.phoneNumber)
                ) {
                  trackEvent({
                    eventName: ADD_CONTACT_INFO,
                    properties: {},
                  });
                }
              }}
              showErrors={showErrors}
              setShowErrors={setShowErrors}
              loading={customerDetailsLoading}
            />
            {isLoyaltyEligible && !isMultiroomAmadeusEnabled && (
              <>
                <Divider style={{ marginTop: 0 }} />
                <LoyaltyInformationForm
                  hasSaved={hasSavedLoyaltyInfo}
                  onChange={(value: string) => {
                    setHasSavedLoyaltyInfo(false);
                    setLoyaltyNumber(value);
                  }}
                  disabled={disableEditTravelerInfo}
                  traveler={selectedTraveler}
                />
              </>
            )}
            {!disableEditTravelerInfo && (
              <ActionButton
                className={clsx(
                  "traveler-select-workflow-button",
                  "traveler-step-cta"
                )}
                message="Save & Continue"
                onClick={() => {
                  if (
                    isFirstStepCompletedInCheckout &&
                    !showErrors.corpPrimaryTraveler
                  ) {
                    updateSelectedTravelersInfo(selectedTravelersList);
                    schedulePriceQuote({
                      history,
                      agentFee: isAgentPortal ? AGENT_FEE : 0,
                      pollQuoteOnly: true,
                      ancillaries,
                      loyaltyNumber,
                    });
                    setDisableEditTravelerInfo(true);
                    setShowErrors({
                      phone: false,
                      email: false,
                      travelerSelect: false,
                      corpPrimaryTraveler: false,
                    });
                    trackEvent({
                      eventName: HOTEL_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: false,
                      },
                    });
                  } else {
                    trackEvent({
                      eventName: HOTEL_SAVE_AND_CONTINUE_CLICKED,
                      properties: {
                        has_form_errors: true,
                        traveler_error: !isTravelerStepComplete,
                        email_error:
                          !contactInfo || !emailRegex.test(contactInfo.email),
                        phone_error:
                          !contactInfo ||
                          !phoneRegex.test(contactInfo.phoneNumber),
                      },
                    });
                    setShowErrors({
                      phone:
                        !contactInfo ||
                        !phoneRegex.test(contactInfo.phoneNumber),
                      email:
                        !contactInfo || !emailRegex.test(contactInfo.email),
                      travelerSelect: !isTravelerStepComplete,
                      corpPrimaryTraveler:
                        selectedTravelersList.length === 0 ||
                        !selectedTravelersList[0].emailAddress,
                    });
                    if (!saveButtonClicked) {
                      setSaveButtonClicked(true);
                    }
                  }
                }}
                defaultStyle="h4r-primary"
              />
            )}
            {disableEditTravelerInfo && (
              <ActionLink
                className={clsx("traveler-step-cta", "b2b")}
                content={
                  <Box className="edit-traveler-link">
                    <Typography variant="body1">
                      Edit Traveler Information
                    </Typography>
                    <Icon
                      aria-hidden
                      className="icon-end"
                      name={IconName.B2BEditPencil}
                      ariaLabel=""
                    />
                  </Box>
                }
                onClick={() => {
                  setPriceQuote({
                    priceQuote: null,
                    pricingWithAncillaries: null,
                    hotelAncillaryQuotes: null,
                    type: "hotel-checkout",
                  });
                  setDisableEditTravelerInfo(false);
                  setSelectedPaymentMethodId({
                    paymentMethodId: "",
                    accountId: undefined,
                  });
                  setSelectedRewardsAccountReferenceId(
                    DO_NOT_APPLY_REWARDS_KEY
                  );
                }}
              />
            )}
            <Box className={clsx("contact-info-helper-container")}>
              <Typography
                variant="body2"
                className={clsx("contact-info-helper-text")}
              >
                {CONTACT_INFO_HELPER_TEXT}
              </Typography>
            </Box>
          </Box>
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "credit"
            )}
          >
            <PaymentCard
              disabled={
                !isFirstStepCompletedInCheckout ||
                !disableEditTravelerInfo ||
                (!isCreditAndOfferStackingExperimentV1 &&
                  isTravelWalletPaymentOnly)
              }
            />
            {isTreesModalExperiment && (
              <TreesModal
                image={TreesConfirmation}
                header={TREES_MODAL_HEADER}
                title={TREES_MODAL_TITLE}
                onClose={() => setTreeModalOpen(false)}
                subtitle={TREES_MODAL_SUBTITLE}
                icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
                openModal={treeModalOpen}
                setOpenModal={setTreeModalOpen}
                modalLinkCopy={TREES_MODAL_CTA_TEXT}
                modalButtonCopyStartIcon={
                  <Icon className="trees-icon" name={IconName.TreesIcon} />
                }
                boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
              />
            )}
          </Box>
          {hasCfarAttached ? (
            <CfarCancellationPolicy />
          ) : (
            cancellationPolicyInfo && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "cancellation-details"
                )}
              >
                <Typography variant="h4">
                  {cancellationPolicyInfo.primaryText}
                </Typography>
                <Typography variant="body2">
                  {showFree24HourCancel &&
                  chosenProduct?.cancellationPolicy.CancellationPolicy ===
                    CancellationPolicyEnum.NonRefundable
                    ? CALIFORNIA_BILL_644_CANCELLATION_TEXT
                    : cancellationPolicyInfo.secondaryText}
                </Typography>
              </Box>
            )
          )}
          {selectedLodging?.lodging?.checkInInstructions &&
            checkIn &&
            checkOut && (
              <Box
                className={clsx(
                  "checkout-template-card-content-container",
                  "check-in-instructions"
                )}
              >
                <Typography
                  variant="h4"
                  className="hotel-shop-check-in-instructions-heading"
                >
                  {CHECK_IN_INSTRUCTIONS_TITLE}
                </Typography>
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: formattedCheckIn(
                      checkIn,
                      selectedLodging.lodging.checkInInstructions?.checkInTime
                    ),
                  }}
                />
                <Typography
                  variant="body2"
                  dangerouslySetInnerHTML={{
                    __html: formattedCheckOut(
                      checkOut,
                      selectedLodging.lodging.checkInInstructions?.checkOutTime
                    ),
                  }}
                />
              </Box>
            )}
          <Box
            className={clsx(
              "checkout-template-card-content-container",
              "need-to-know"
            )}
          >
            <HotelNeedToKnowPanel
              title={NEED_TO_KNOW_TITLE}
              subtitle={NEED_TO_KNOW_DETAILS}
              policies={mergedPolicies}
              fetchingPriceQuote={priceQuoteCallState === CallState.InProcess}
            />
          </Box>
        </Box>
        <Box className={clsx("checkout-template-column", "right")}>
          <Box className="checkout-template-column-right-wrapper">
            <Box className="hotel-book-right-content">
              {showPolicyBanner && <PolicyBanner isInPolicy={isInPolicy} />}
              <Box className="payment-break-down-container">
                <Box
                  className={clsx(
                    "checkout-template-card-content-container",
                    "payment-break-down"
                  )}
                >
                  {!!roomInfoProduct && chosenProductIndex !== null && (
                    <RoomDetails
                      roomInfoProduct={roomInfoProduct}
                      productIndex={chosenProductIndex}
                      lodging={selectedLodging}
                      hideAmenities
                      hideAllAmenities
                      roomsCount={roomsCount}
                      hideRoomBedDescription
                      hideRoomCapacity
                      showAmenitiesModal
                      showPolicyBanner={showPolicyBanner}
                    />
                  )}
                  <PriceBreakdown />
                </Box>
                {!isInPolicy && (
                  <>
                    <ApprovalReasonTextarea
                      value={approvalRequestReason ?? ""}
                      className="approval-reason"
                      onChange={handleReasonChange}
                      corporateTravel={corporateTravel}
                      productType="hotel"
                      limit={policies?.hotels.policies[0].maxPricePerNight}
                    />
                    <ApprovalBanner
                      isApprovalRequired={
                        policies?.settings &&
                        policies.settings.isApprovalRequired
                      }
                    />
                  </>
                )}
                <Box className="confirm-book-button-container">
                  <Button
                    className="confirm-book-button"
                    disabled={
                      !isBookingValid ||
                      !allStepsCompleted ||
                      (!isInPolicy && !approvalRequestReason)
                    }
                    onClick={onBookHotel}
                  >
                    {BOOK_BUTTON_TEXT}
                  </Button>
                </Box>
              </Box>
            </Box>
            {showFree24HourCancel && <VoidWindowNotice />}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
