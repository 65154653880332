import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router";

import { IStoreState } from "../../../../reducers/types";
import { setApplyUserHotelPreferences } from "../../../search/actions/actions";
import { getApplyUserHotelPreferences } from "../../../search/reducer";
import {
  setAmenitiesFilter,
  setFreeCancelFilter,
  setHotelAvailabilitySortOption,
  setHotelNameFilter,
  setHotelsOnSaleFilter,
  setLifestyleStaysOnly,
  setLoyaltyProgramsFilter,
  setMaxPriceFilter,
  setMealPlanTypeFilter,
  setPolicyFilter,
  setPremiumStaysOnly,
  setStarRatingsFilter,
  setStayTypeFilter,
  setVacationRentalAmenitiesFilter,
  setVacationRentalRoomsAndBedsFilter,
} from "../../actions/actions";
import {
  getAppliedHotelAvailabilityFilterCount,
  getFirstIndexOfHomesInAvailabilityLodgingsResults,
  getHasUserSetHotelPreferences,
  getHotelAvailabilityAmenitiesFilter,
  getHotelAvailabilityVacationRentalAmenitiesFilter,
  getHotelAvailabilityCallState,
  getHotelAvailabilityCurrency,
  getHotelAvailabilityFiltersBannerMessage,
  getHotelAvailabilityFreeCancelFilter,
  getHotelAvailabilityHotelNameFilter,
  getHotelAvailabilityHotelsOnSaleFilter,
  getHotelAvailabilityLodgingsHasHomesAndHotels,
  getHotelAvailabilityLodgingsHasMealplans,
  getHotelAvailabilityLoyaltyProgramsFilter,
  getHotelAvailabilityMaxPriceFilter,
  getHotelAvailabilityMealPlanTypeFilter,
  getHotelAvailabilityMinMaxPriceRange,
  getHotelAvailabilityPolicyFilter,
  getHotelAvailabilitySortOption,
  getHotelAvailabilityStarRatingsFilter,
  getHotelAvailabilityStayTypeFilter,
  getLifestyleStaysLodgingsCount,
  getPremiumStaysLodgingsCount,
  getSearchLocationLabel,
  getShowLifestyleStaysOnlyFilter,
  getShowPremiumStaysOnlyFilter,
  getUserHotelPreferences,
  getUserHotelPreferencesCallState,
  hasChangedHotelAvailabilityAmenitiesFilter,
  hasChangedHotelAvailabilityMaxPriceFilter,
  hasChangedHotelAvailabilityMealPlanTypeFilter,
  hasChangedHotelAvailabilityPolicyFilter,
  hasChangedHotelAvailabilityStarRatingsFilter,
  hasChangedHotelAvailabilityStayTypeFilter,
  hasChangedHotelAvailabilityVacationRentalAmenitiesFilter,
  hasChangedHotelAvailabilityVacationRentalRoomsAndBedsFilter,
  getHotelAvailabilityVacationRentalRoomsAndBedsFilter,
} from "../../reducer";
import { AvailabilityFilter } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    locationLabel: getSearchLocationLabel(state),
    amenities: getHotelAvailabilityAmenitiesFilter(state),
    bannerMessage: getHotelAvailabilityFiltersBannerMessage(state),
    starRatings: getHotelAvailabilityStarRatingsFilter(state),
    maxPrice: getHotelAvailabilityMaxPriceFilter(state),
    minMaxPriceRange: getHotelAvailabilityMinMaxPriceRange(state),
    stayTypes: getHotelAvailabilityStayTypeFilter(state),
    mealPlanTypes: getHotelAvailabilityMealPlanTypeFilter(state),
    currency: getHotelAvailabilityCurrency(state),
    hotelName: getHotelAvailabilityHotelNameFilter(state),
    hasChangedAmenitiesFilter:
      hasChangedHotelAvailabilityAmenitiesFilter(state),
    hasChangedStarRatingsFilter:
      hasChangedHotelAvailabilityStarRatingsFilter(state),
    hasChangedMaxPriceFilter: hasChangedHotelAvailabilityMaxPriceFilter(state),
    hasFreeCancelFilter: getHotelAvailabilityFreeCancelFilter(state),
    hasHotelsOnSaleFilter: getHotelAvailabilityHotelsOnSaleFilter(state),
    sortOption: getHotelAvailabilitySortOption(state),
    isInPolicy: getHotelAvailabilityPolicyFilter(state),
    loyaltyPrograms: getHotelAvailabilityLoyaltyProgramsFilter(state),
    shouldApplyUserHotelPreferences: getApplyUserHotelPreferences(state),
    userHotelPreferences: getUserHotelPreferences(state),
    userHotelPreferencesCallState: getUserHotelPreferencesCallState(state),
    hasUserSetHotelPreferences: getHasUserSetHotelPreferences(state),
    appliedFilterCount: getAppliedHotelAvailabilityFilterCount(state),
    premiumStaysOnly: getShowPremiumStaysOnlyFilter(state),
    premiumStaysLodgingCount: getPremiumStaysLodgingsCount(state),
    lifestyleStaysOnly: getShowLifestyleStaysOnlyFilter(state),
    lifestyleStaysLodgingCount: getLifestyleStaysLodgingsCount(state),
    availabilityResultsHasHomesAndHotels:
      getHotelAvailabilityLodgingsHasHomesAndHotels(state),
    availabilityResultsHasMealplans:
      getHotelAvailabilityLodgingsHasMealplans(state),
    availabilityResultsFirstIndexOfHomeResult:
      getFirstIndexOfHomesInAvailabilityLodgingsResults(state),
    hasChangedPolicyFilter: hasChangedHotelAvailabilityPolicyFilter(state),
    hotelAvailabilityCallState: getHotelAvailabilityCallState(state),
    hasChangedHotelAvailabilityStayTypeFilter:
      hasChangedHotelAvailabilityStayTypeFilter(state),
    hasChangedHotelAvailabilityMealPlanTypeFilter:
      hasChangedHotelAvailabilityMealPlanTypeFilter(state),
    vacationRentalAmenities:
      getHotelAvailabilityVacationRentalAmenitiesFilter(state),
    hasChangedVacationRentalAmenitiesFilter:
      hasChangedHotelAvailabilityVacationRentalAmenitiesFilter(state),
    vacationRentalRoomsAndBeds:
      getHotelAvailabilityVacationRentalRoomsAndBedsFilter(state),
    hasChangedVacationRentalRoomsAndBedsFilter:
      hasChangedHotelAvailabilityVacationRentalRoomsAndBedsFilter(state),
  };
};

const mapDispatchToProps = {
  setAmenitiesFilter,
  setStarRatingsFilter,
  setMaxPriceFilter,
  setHotelNameFilter,
  setFreeCancelFilter,
  setHotelsOnSaleFilter,
  setHotelAvailabilitySortOption,
  setPolicyFilter,
  setLoyaltyProgramsFilter,
  setApplyUserHotelPreferences,
  setPremiumStaysOnly,
  setLifestyleStaysOnly,
  setStayTypeFilter,
  setMealPlanTypeFilter,
  setVacationRentalAmenitiesFilter,
  setVacationRentalRoomsAndBedsFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type AvailabilityFilterConnectorProps = ConnectedProps<typeof connector>;

export const ConnectedAvailabilityFilter = withRouter(
  connector(AvailabilityFilter)
);
