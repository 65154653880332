import { Box, Typography } from "@material-ui/core";
import clsx from "clsx";
import React, { useEffect, useMemo, useRef, useState } from "react";

import {
  B2BSpinner,
  BannerSeverity,
  CarouselGallery,
  HotelAddress,
  HotelAmenities,
  HotelCarousel,
  HotelDescription,
  HotelPriceCompareChart,
  HotelReviewBadge,
  HotelShopChooseRoomSkeleton,
  HotelShopSimilarHotels,
  HotelTripAdvisorReviews,
  Icon,
  IconName,
  NotificationBanner,
  VoidWindowNotice,
  getCancellationPolicyInfo,
  savingsAmountBasedOnCheapestProduct,
} from "halifax";
import queryStringParser from "query-string";
import {
  CallState,
  CancellationPolicyEnum,
  Lodging,
  LodgingCollectionEnum,
  SELECTED_RECOMMENDED_HOTEL,
  VIEWED_HOTEL_SHOP_MAP,
  CancellationReason,
} from "redmond";

import { v4 as uuidv4 } from "uuid";
import { trackEvent } from "../../../../api/v0/analytics/trackEvent";
import {
  AVAILABLE,
  CACHE_HOTEL_TOKEN,
  CONTROL,
  CORP_DEBUGGING_PANEL,
  HOTELS_CALIFORNIA_BILL_644_EXPERIMENT,
  HOTELS_SHOP_IMAGE_MODAL,
  PREMIER_COLLECTION_EXPERIMENT,
  SIMILAR_HOTELS_1A,
  SIMILAR_HOTELS_1B,
  SIMILAR_HOTELS_EXPERIMENT,
  SIMILAR_HOTELS_VARIANTS,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  useExperiments,
} from "../../../../context/experiments";
import {
  PATH_SHOP,
  PREMIER_COLLECTION_PATH_SHOP,
} from "../../../../utils/paths";
import { PriceDropProtectionBanner } from "../../../ancillary/components/addOnComponents";
import { PriceDropProtectionPopup } from "../../../ancillary/components/addOnComponents/PriceDropProtectionBanner/components";
import { DesktopRewardsHeader } from "../../../rewards/components";
import { HotelShopCallState } from "../../reducer/state";
import { goToAvailability } from "../../utils/queryStringHelpers";
import { CorporateDebuggingPanel } from "../CorporateDebuggingPanel";
import { DesktopHotelPhotoModal } from "../DesktopHotelPhotoModal";
import { DesktopHotelShopChooseRoom } from "../DesktopHotelShopChooseRoom";
import { DesktopRoomPicker } from "../DesktopRoomPicker";
import { HotelShopExpandedMap } from "../HotelShopExpandedMap";
import { HotelShopProgressBar } from "../HotelShopProgressBar";
import { HotelShopSmallMap } from "../HotelShopSmallMap/component";
import { ShopErrorModal } from "../ShopErrorModal";
import { IDesktopShopProps } from "./container";
import "./styles.scss";
import * as textConstants from "./textConstants";
import { localCache } from "@capone/common";

export const DesktopShop = (props: IDesktopShopProps) => {
  const {
    lodging,
    history,
    cheapestProduct,
    fromDate,
    hideRightColumn,
    hideProgressbar,
    untilDate,
    adultsCount,
    children,
    hotelShopCallInProgress,
    hasFailed,
    similarHotels,
    similarHotelsAvailabilityCallState,
    selectedAccountReferenceId,
    roomsCount,
    petsCount,
    similarHotelsAvailabilityTrackingProperties,
    largestValueAccount,
    searchDistanceFromShopProperties,
    hotelShopCallState,
    isCustomizePageEnabled,
    nightCount,
    roomInfoProductsType,
    handleReadyToRedirect,
    userPriceDropAmount,
    setRoomInfoProduct,
    setRoomProductIndex,
    cancellationSummary,
  } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [showExpandedHotelMap, setShowExpandedHotelMap] = useState(false);
  const [successShopSearchFromDate, setSuccessShopSearchFromDate] =
    useState<Date | null>(null);
  const [successShopSearchUntilDate, setSuccessShopSearchUntilDate] =
    useState<Date | null>(null);

  const [
    priceDropProtectionBannerModal,
    setOpenPriceDropProtectionBannerModal,
  ] = useState<boolean>(false);

  const expState = useExperiments();
  const hotelShopImageModal = getExperimentVariant(
    expState.experiments,
    HOTELS_SHOP_IMAGE_MODAL
  );
  const isHotelShopImageModal = useMemo(
    () => hotelShopImageModal === AVAILABLE,
    [hotelShopImageModal]
  );
  const similarHotelsVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    SIMILAR_HOTELS_EXPERIMENT,
    SIMILAR_HOTELS_VARIANTS
  );

  const premierCollectionEnabled = getExperimentVariant(
    expState.experiments,
    PREMIER_COLLECTION_EXPERIMENT
  );
  const isPremierCollectionEnabled = useMemo(
    () => premierCollectionEnabled === AVAILABLE,
    [premierCollectionEnabled]
  );

  const isCorporateDebuggingPanelEnabled =
    getExperimentVariant(expState.experiments, CORP_DEBUGGING_PANEL) ===
    AVAILABLE;

  const californiaBill644Variant = getExperimentVariant(
    expState.experiments,
    HOTELS_CALIFORNIA_BILL_644_EXPERIMENT
  );
  const isCaliforniaBill644Experiment = useMemo(
    () => californiaBill644Variant === AVAILABLE,
    [californiaBill644Variant]
  );

  const showFree24HourCancel =
    isCaliforniaBill644Experiment &&
    cancellationSummary?.reasons.includes(CancellationReason.CaliforniaBill644);

  const showEarnEnhancement =
    !!largestValueAccount && !!largestValueAccount.earn.hotelsMultiplier;
  const cancellationPolicyInfo = cheapestProduct?.cancellationPolicy
    ? getCancellationPolicyInfo(
        cheapestProduct?.cancellationPolicy,
        textConstants.getCfarSecondaryText
      )
    : null;

  const cacheHotelTokenEnabled =
    getExperimentVariant(expState.experiments, CACHE_HOTEL_TOKEN) === AVAILABLE;

  useEffect(() => {
    if (hotelShopCallState === HotelShopCallState.Success) {
      setSuccessShopSearchFromDate(fromDate);
      setSuccessShopSearchUntilDate(untilDate);
    }
  }, [hotelShopCallState]);

  const returnToAvailability = (
    prevSuccessHotelFromDate?: Date | null,
    prevSuccessHotelUntilDate?: Date | null
  ) => {
    goToAvailability({
      history,
      lodging,
      fromDate,
      untilDate,
      adultsCount,
      children,
      roomsCount,
      petsCount,
      prevSuccessHotelFromDate,
      prevSuccessHotelUntilDate,
    });
  };

  const renderSkeleton = () => (
    <Box className="desktop-hotel-shop-skeleton">
      <HotelCarousel isSkeleton />
      <Box className="content-container">
        <Box className="section-wrapper-container">
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <HotelAddress isSkeleton />
            <HotelAmenities isSkeleton />
            <HotelReviewBadge isSkeleton />
          </Box>
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <HotelReviewBadge isSkeleton />
            <HotelReviewBadge isSkeleton />
            <HotelAmenities isSkeleton />
          </Box>
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <HotelAmenities isSkeleton />
            <HotelAddress isSkeleton />
            <HotelAmenities isSkeleton />
          </Box>
        </Box>
        <HotelShopChooseRoomSkeleton />
      </Box>
    </Box>
  );

  const showSimilarHotels =
    similarHotelsAvailabilityCallState === CallState.InProcess ||
    similarHotels.length > 1;

  const renderSimilarHotels = (lodging: Lodging) =>
    showSimilarHotels ? (
      <Box
        className={clsx("similar-hotels", {
          "variant-1a": similarHotelsVariant === SIMILAR_HOTELS_1A,
          "variant-1b": similarHotelsVariant === SIMILAR_HOTELS_1B,
        })}
      >
        {similarHotelsAvailabilityCallState === CallState.InProcess ? (
          <B2BSpinner />
        ) : (
          <>
            <Typography className="similar-hotels-header" variant="h2">
              Other similar Hotels in <strong>{lodging.lodging.city}</strong>
            </Typography>
            <HotelShopSimilarHotels
              similarHotels={
                isPremierCollectionEnabled
                  ? similarHotels
                  : similarHotels.filter(
                      (s) =>
                        s.lodgingCollection !== LodgingCollectionEnum.Premier
                    )
              }
              nightCount={nightCount || 1}
              rewardsKey={selectedAccountReferenceId ?? ""}
              onCTAClick={(lodgingId: string) => {
                const lodging = similarHotels.find(
                  (similarHotel) => similarHotel.lodging.id === lodgingId
                );

                trackEvent({
                  eventName: SELECTED_RECOMMENDED_HOTEL,
                  properties: {
                    ...lodging?.trackingPropertiesV2?.properties,
                    ...similarHotelsAvailabilityTrackingProperties,
                  },
                  encryptedProperties: [
                    lodging?.trackingPropertiesV2?.encryptedProperties ?? "",
                  ],
                });

                const queryString = history.location.search;
                const parsedQueryStringPrimitive =
                  queryStringParser.parse(queryString);

                const childrenArray = Array.isArray(
                  parsedQueryStringPrimitive.children
                )
                  ? parsedQueryStringPrimitive.children.map((age) =>
                      parseInt(age, 10)
                    )
                  : parsedQueryStringPrimitive.children
                  ? [parseInt(parsedQueryStringPrimitive.children, 10)]
                  : [];

                const hotelQueryParams = {
                  fromDate: parsedQueryStringPrimitive.fromDate as string,
                  untilDate: parsedQueryStringPrimitive.untilDate as string,
                  adultsCount: Number(parsedQueryStringPrimitive.adultsCount),
                  children: childrenArray,
                  selectedLodgingIndex: Number(
                    parsedQueryStringPrimitive.selectedLodgingIndex
                  ),
                  selectedAccountIndex: Number(
                    parsedQueryStringPrimitive.selectedAccountIndex
                  ),
                  lodgingSelection:
                    parsedQueryStringPrimitive.lodgingSelection as string,
                  recommended: true,
                  fromHotelAvailability:
                    lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Premier ||
                    lodging?.lodgingCollection ===
                      LodgingCollectionEnum.Lifestyle
                      ? true
                      : undefined,
                  shopToken: cacheHotelTokenEnabled
                    ? lodging?.price?.opaqueShopRequest
                    : localCache.set(
                        uuidv4(),
                        lodging?.price?.opaqueShopRequest || ""
                      ).key,
                };

                const path =
                  lodging?.lodgingCollection === LodgingCollectionEnum.Premier
                    ? PREMIER_COLLECTION_PATH_SHOP
                    : PATH_SHOP;
                window.open(
                  `${path}?${queryStringParser.stringify({
                    lodgingId,
                    ...hotelQueryParams,
                  })}`,
                  "_blank"
                );
              }}
            />
          </>
        )}
      </Box>
    ) : null;

  const renderAvailability = () => {
    const { lodging: lodgingData, price } = lodging || {};
    const savings =
      price && cheapestProduct
        ? savingsAmountBasedOnCheapestProduct(price, cheapestProduct)
        : null;

    if (!lodging) {
      return null;
    }
    return (
      <Box
        className={clsx("desktop-hotel-shop-container", {
          redesign: true,
        })}
      >
        {isCorporateDebuggingPanelEnabled && (
          <CorporateDebuggingPanel
            lodging={lodging}
            roomInfoProductsType={roomInfoProductsType}
          />
        )}
        {isHotelShopImageModal ? (
          <DesktopHotelPhotoModal />
        ) : (
          <CarouselGallery
            modalTitle={lodging!.lodging.name}
            media={lodging!.lodging.media}
            className="b2b"
          />
        )}
        <Box
          className={clsx("hotel-shop-right-column", {
            floatCTA: true,
          })}
        >
          {!hideRightColumn && <DesktopHotelShopChooseRoom />}
          {showFree24HourCancel && <VoidWindowNotice />}
          {isPremierCollectionEnabled &&
          lodging.minNightOfStay &&
          nightCount &&
          lodging.minNightOfStay > nightCount ? (
            <NotificationBanner
              severity={BannerSeverity.WARNING}
              content={
                <Box className="pc-minimum-night-stay-warning-banner-content">
                  <Icon name={IconName.WarningAlert} />
                  <Typography
                    variant="body1"
                    className="warning-banner-text"
                    dangerouslySetInnerHTML={{
                      __html: textConstants.getMinNightStayBannerText(
                        lodging.minNightOfStay
                      ),
                    }}
                  />
                </Box>
              }
              className="pc-minimum-stay-night-warning-banner"
            />
          ) : null}
        </Box>
        <PriceDropProtectionBanner
          bannerVersion="large"
          // Hotels module won't show premier collection hotels
          showPremierCollectionText={false}
          setOpenModal={setOpenPriceDropProtectionBannerModal}
        />
        <PriceDropProtectionPopup
          amount={userPriceDropAmount}
          openModal={priceDropProtectionBannerModal}
          onClose={() => setOpenPriceDropProtectionBannerModal(false)}
        />
        {lodgingData?.amenities && lodgingData?.amenities.length > 0 && (
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <Typography variant="h2" className="hotel-amenities-title">
              Hotel Amenities
            </Typography>
            <HotelAmenities
              amenities={lodgingData?.amenities}
              displayCount={8}
              showAmenitiesText={`More Amenities (${
                (lodgingData?.amenities.length ?? 8) - 8
              })`}
              hideAmenityIconsInTooltip
            />
          </Box>
        )}
        {price &&
          price.comparePrices.length > 0 &&
          !!price.comparePrices.find((price) => price.source !== "Expedia") &&
          cheapestProduct &&
          savings && (
            <Box className={clsx("section-wrapper", { hideRightColumn })}>
              <Typography variant="h2">Compare Prices</Typography>
              <HotelPriceCompareChart
                lodgingPrice={price}
                siteName="Capital One Travel"
                cheapestProduct={cheapestProduct}
                earnText={
                  showEarnEnhancement
                    ? textConstants.getEarnText(
                        largestValueAccount.earn.hotelsMultiplier,
                        largestValueAccount.productDisplayName,
                        largestValueAccount.rewardsBalance
                          .currencyDescription ??
                          largestValueAccount.rewardsBalance.currency
                      )
                    : undefined
                }
              />
            </Box>
          )}

        <Box className={clsx("section-wrapper", { hideRightColumn })}>
          <Typography variant="h2">About the Property</Typography>
          <HotelDescription
            description={lodging?.lodging?.description}
            onMapClick={() => {
              setShowExpandedHotelMap(true);
              trackEvent({
                eventName: VIEWED_HOTEL_SHOP_MAP,
                ...searchDistanceFromShopProperties,
              });
            }}
            map={
              <HotelShopSmallMap
                lodging={lodging}
                setShowExpandedHotelMap={setShowExpandedHotelMap}
                searchDistanceFromShopProperties={
                  searchDistanceFromShopProperties
                }
              />
            }
          />
          <HotelShopExpandedMap
            lodging={lodging}
            showExpandedHotelMap={showExpandedHotelMap}
            setShowExpandedHotelMap={setShowExpandedHotelMap}
          />
        </Box>
        {lodgingData?.tripAdvisorReviews &&
          lodgingData?.tripAdvisorReviews?.comments?.length > 0 && (
            <Box className={clsx("section-wrapper", { hideRightColumn })}>
              <Typography variant="h2">Customer Reviews</Typography>
              <HotelTripAdvisorReviews lodgingData={lodgingData} trim={1000} />
            </Box>
          )}
        {cancellationPolicyInfo && (
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <Typography
              variant="h4"
              className="hotel-shop-cancellation-policy-heading"
            >
              {cancellationPolicyInfo.primaryText}
            </Typography>
            <Typography variant="subtitle2">
              {cheapestProduct?.cancellationPolicy.CancellationPolicy ===
                CancellationPolicyEnum.NonRefundable && showFree24HourCancel
                ? textConstants.CALIFORNIA_BILL_644_CANCELLATION_TEXT
                : cancellationPolicyInfo.secondaryText}
            </Typography>
          </Box>
        )}
        {lodgingData?.checkInInstructions && fromDate && untilDate && (
          <Box className={clsx("section-wrapper", { hideRightColumn })}>
            <Typography
              variant="h4"
              className="hotel-shop-check-in-instructions-heading"
            >
              {textConstants.CHECK_IN_INSTRUCTIONS_TITLE}
            </Typography>
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckIn(
                  fromDate,
                  lodgingData.checkInInstructions?.checkInTime
                ),
              }}
            />
            <Typography
              variant="body2"
              dangerouslySetInnerHTML={{
                __html: textConstants.formattedCheckOut(
                  untilDate,
                  lodgingData.checkInInstructions?.checkOutTime
                ),
              }}
            />
          </Box>
        )}
      </Box>
    );
  };

  const renderShop = () => {
    if (!lodging) {
      return null;
    }
    return (
      <>
        {similarHotelsVariant === SIMILAR_HOTELS_1B &&
          renderSimilarHotels(lodging)}

        <div ref={ref}>
          <Box
            id="hotel-shop-room-type-section"
            className={clsx("hotel-shop-room-type-section-wrapper-redesign", {
              "white-bg":
                [CONTROL, SIMILAR_HOTELS_1A].includes(similarHotelsVariant) ||
                !showSimilarHotels,
            })}
          >
            <Typography className="choose-bed-type-header" variant="h2">
              Choose Room
            </Typography>
            <DesktopRoomPicker
              handleReadyToRedirect={handleReadyToRedirect}
              roomInfoProductsType={roomInfoProductsType}
              setRoomInfoProduct={setRoomInfoProduct}
              setRoomProductIndex={setRoomProductIndex}
              nonRefundablePolicyOverrideText={
                showFree24HourCancel
                  ? textConstants.CALIFORNIA_BILL_644_CANCELLATION_TEXT
                  : undefined
              }
            />
          </Box>
        </div>

        {similarHotelsVariant === SIMILAR_HOTELS_1A &&
          renderSimilarHotels(lodging)}
      </>
    );
  };

  return hasFailed ? (
    <ShopErrorModal
      returnToAvailability={() =>
        returnToAvailability(
          successShopSearchFromDate,
          successShopSearchUntilDate
        )
      }
    />
  ) : (
    <Box className="desktop-hotel-shop-wrapper">
      {!hideProgressbar && isCustomizePageEnabled && (
        <Box className="progress-bar-container">
          <HotelShopProgressBar useCustomizeStep />
        </Box>
      )}
      {!hideRightColumn && <DesktopRewardsHeader />}
      {renderAvailability()}
      {hotelShopCallInProgress ? renderSkeleton() : renderShop()}
    </Box>
  );
};
