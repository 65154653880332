import { connect, ConnectedProps } from "react-redux";
import { withRouter } from "react-router-dom";
import { VacationRentalAwarenessModal } from "./component";
import { IStoreState } from "../../../../reducers/types";
import { getRewardsAccountWithLargestEarnForBanner } from "../../../rewards/reducer/selectors";

const mapStateToProps = (state: IStoreState) => ({
  largestValueAccount: getRewardsAccountWithLargestEarnForBanner(state),
});

const connector = connect(mapStateToProps);

export type VacationRentalAwarenessModalConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedVacationRentalAwarenessModal = withRouter(
  connector(VacationRentalAwarenessModal)
);
