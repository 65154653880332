import { connect, ConnectedProps } from "react-redux";
import { IStoreState } from "../../../../reducers/types";
import { setStayTypeFilter } from "../../actions/actions";
import { getHotelAvailabilityStayTypeFilter } from "../../reducer/selectors";
import { withRouter } from "react-router-dom";
import { StayTypeFilterControl } from "./component";

const mapStateToProps = (state: IStoreState) => {
  return {
    stayTypes: getHotelAvailabilityStayTypeFilter(state),
  };
};

const mapDispatchToProps = {
  setStayTypeFilter,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type StayTypeFilterControlConnectorProps = ConnectedProps<
  typeof connector
>;

export const ConnectedStayTypeFilterControl = withRouter(
  connector(StayTypeFilterControl)
);
