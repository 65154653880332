import { Experiment } from "redmond";
import {
  AVAILABLE,
  CONTROL,
  LC_GATING_EXPERIMENT,
  PREMIER_COLLECTION_EXPERIMENT,
  STAYS_STAY_TYPE_FILTER,
  STAYS_STAY_TYPE_FILTER_VARIANTS,
  boostPremierCollectionCustomSortExperiment,
  boostPremierCollectionExperiment,
  getExperimentDefVariant,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
} from "../../../../context/experiments";
import { mapExperimentVariants } from "../../../../context/experiment-def";
import { StayType } from "redmond/hotels-module/interfaces";

/**
 * We show the Premium stays filter if there are more lodgings than what is shown in the top of the list,
 * and if the Premium stays is enabled with the proper boost experiment.
 */
export function shouldShowPremiumStaysFilter(
  collectionSpecificLodgingCounts: {
    all: number;
    top: number;
  },
  experiments: Array<Experiment>
): boolean {
  const isPremierCollectionEnabled =
    getExperimentVariant(experiments, PREMIER_COLLECTION_EXPERIMENT) ===
    AVAILABLE;
  const isLegacyBoostForPremierCollectionEnabled = mapExperimentVariants(
    boostPremierCollectionExperiment,
    (variant) => {
      switch (variant) {
        case "control":
        case "available":
          return false;
        case "2-boost":
        case "2-boost-randomized":
        case "3-boost":
        case "3-boost-randomized":
          return true;
      }
    }
  );
  const isBoostForPremierCollectionEnabled =
    isLegacyBoostForPremierCollectionEnabled ||
    getExperimentDefVariant(
      experiments,
      boostPremierCollectionCustomSortExperiment
    ) !== CONTROL;

  return (
    isPremierCollectionEnabled &&
    isBoostForPremierCollectionEnabled &&
    collectionSpecificLodgingCounts.all > collectionSpecificLodgingCounts.top
  );
}

/**
 * We show the Lifestyle Collection filter if there are more lodgings than what is shown in the top of the list,
 * and if the Lifestyle Collection is enabled with the proper boost experiment.
 * Don't show the filter if the Premium Collection is enabled.
 */
export function shouldShowLifestyleStaysFilter(
  collectionSpecificLodgingCounts: {
    all: number;
    top: number;
  },
  experiments: Array<Experiment>
): boolean {
  const isPremierCollectionEnabled =
    getExperimentVariant(experiments, PREMIER_COLLECTION_EXPERIMENT) ===
    AVAILABLE;
  const isLifestyleCollectionEnabled =
    getExperimentVariant(experiments, LC_GATING_EXPERIMENT) === AVAILABLE;
  const isBoostForLifestyleCollectionEnabled =
    getExperimentDefVariant(
      experiments,
      boostPremierCollectionCustomSortExperiment
    ) !== CONTROL;

  return (
    !isPremierCollectionEnabled &&
    isLifestyleCollectionEnabled &&
    isBoostForLifestyleCollectionEnabled &&
    collectionSpecificLodgingCounts.all > collectionSpecificLodgingCounts.top
  );
}

export const stayTypeFilterSettings = ({
  experiments,
  availabilityResultsHasHomesAndHotels,
  stayTypes,
}: {
  experiments: Experiment[];
  availabilityResultsHasHomesAndHotels: boolean;
  stayTypes: StayType[];
}) => {
  const dedicatedStayTypeFilterVariant = getExperimentVariantCustomVariants(
    experiments,
    STAYS_STAY_TYPE_FILTER,
    STAYS_STAY_TYPE_FILTER_VARIANTS
  );

  const dedicatedStayTypeFilterEnabled =
    dedicatedStayTypeFilterVariant === "AvailableButtonGroup" ||
    dedicatedStayTypeFilterVariant === "AvailableRadioGroup";

  const showStayTypeFilterWithinAllFilters =
    !dedicatedStayTypeFilterEnabled && availabilityResultsHasHomesAndHotels;

  const stayTypesSet = new Set(stayTypes || []);

  const isVacationRentalOnlyStayTypeSelected =
    stayTypesSet.size === 1 && stayTypes.includes(StayType.Homes);

  const showVacationRentalSpecificFilters =
    dedicatedStayTypeFilterEnabled && isVacationRentalOnlyStayTypeSelected;

  return {
    dedicatedStayTypeFilterEnabled,
    showStayTypeFilterWithinAllFilters,
    showVacationRentalSpecificFilters,
  };
};
