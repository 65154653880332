import * as actionTypes from "./constants";
import * as H from "history";
import {
  CallState,
  HotelEntryTypeEnum,
  ICategorizedResponse,
  IResult,
  Lodging,
  IShopResponseAvailable,
  TrackingProperties,
  DateRange,
  HotelDetailsEntrySourceEnum,
  LodgingTokenResponse,
} from "redmond";
import {
  HotelShopCallState,
  HotelShopCallError,
  HotelShopStep,
} from "../reducer/state";

export interface IFetchHotelShop {
  type: actionTypes.FETCH_HOTEL_SHOP;
  history: H.History;
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallHotelAvailability?: boolean;
    fetchSimilarHotels?: boolean;
    includeLocationSearchTerm?: boolean;
    fetchLodgingToken?: boolean;
  };
}

export const fetchHotelShop = (
  history: H.History,
  options?: {
    overrideStateByQueryParams?: boolean;
    forceCallHotelAvailability?: boolean;
    fetchSimilarHotels?: boolean;
    includeLocationSearchTerm?: boolean;
    fetchLodgingToken?: boolean;
  }
): IFetchHotelShop => ({
  type: actionTypes.FETCH_HOTEL_SHOP,
  history,
  options,
});

export interface ISetHotelShopResults {
  type: actionTypes.SET_HOTEL_SHOP_RESULTS;
  hotelShopCallState: HotelShopCallState;
  hotelShopCallError: HotelShopCallError | null;
  payload?: IShopResponseAvailable;
}

export const setHotelShopResults = (args: {
  hotelShopCallState: HotelShopCallState;
  hotelShopCallError?: HotelShopCallError;
  payload?: IShopResponseAvailable;
}): ISetHotelShopResults => ({
  type: actionTypes.SET_HOTEL_SHOP_RESULTS,
  ...{ hotelShopCallError: null, ...args },
});

interface IISetHotelShopCallStateFailedPayload {
  hotelShopCallError: HotelShopCallError;
}
export interface ISetHotelShopCallStateFailed
  extends IISetHotelShopCallStateFailedPayload {
  type: actionTypes.SET_HOTEL_SHOP_CALL_STATE_FAILED;
}

export const setHotelShopCallStateFailed = ({
  hotelShopCallError,
}: IISetHotelShopCallStateFailedPayload): ISetHotelShopCallStateFailed => ({
  type: actionTypes.SET_HOTEL_SHOP_CALL_STATE_FAILED,
  hotelShopCallError,
});

export interface ISelectRoomType {
  type: actionTypes.SELECT_ROOM_TYPE;
  roomInfoIndex: number | null;
  productIndex: number | null;
  rateId?: string;
}

export const selectRoomType = (
  roomInfoIndex: number | null,
  productIndex: number | null,
  rateId?: string
): ISelectRoomType => {
  return {
    type: actionTypes.SELECT_ROOM_TYPE,
    roomInfoIndex,
    productIndex,
    rateId,
  };
};

export interface ISelectLodging {
  type: actionTypes.SELECT_LODGING;
  lodging: Lodging;
}

export const selectLodging = (lodging: Lodging): ISelectLodging => ({
  type: actionTypes.SELECT_LODGING,
  lodging,
});

export interface IResetLodging {
  type: actionTypes.RESET_LODGING;
}

export const resetLodging = (): IResetLodging => ({
  type: actionTypes.RESET_LODGING,
});

export interface ISetDateRange {
  type: actionTypes.SET_DATE_RANGE;
  dateRange: DateRange;
}

export const setDateRange = (dateRange: DateRange): ISetDateRange => ({
  type: actionTypes.SET_DATE_RANGE,
  dateRange,
});

export interface IAcknowledgeHotelShopFailure {
  type: actionTypes.ACKNOWLEDGE_HOTEL_SHOP_FAILURE;
}

export const acknowledgeHotelShopFailure =
  (): IAcknowledgeHotelShopFailure => ({
    type: actionTypes.ACKNOWLEDGE_HOTEL_SHOP_FAILURE,
  });

export interface ISetHotelShopProgress {
  type: actionTypes.SET_HOTEL_SHOP_PROGRESS;
  progress: HotelShopStep;
}

export const setHotelShopProgress = (
  progress: HotelShopStep
): ISetHotelShopProgress => ({
  type: actionTypes.SET_HOTEL_SHOP_PROGRESS,
  progress,
});

export interface ISetSimilarHotels {
  type: actionTypes.SET_SIMILAR_HOTELS;
  hotels: Lodging[];
  trackingProperties: TrackingProperties | null;
}

export const setSimilarHotels = (
  hotels: Lodging[],
  trackingProperties: TrackingProperties | null
): ISetSimilarHotels => ({
  type: actionTypes.SET_SIMILAR_HOTELS,
  hotels,
  trackingProperties,
});

export interface ISetSimilarHotelsAvailabilityCallState {
  type: actionTypes.SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE;
  callState: CallState;
}

export const setSimilarHotelsAvailabilityCallState = (
  callState: CallState
): ISetSimilarHotelsAvailabilityCallState => ({
  type: actionTypes.SET_SIMILAR_HOTELS_AVAILABILITY_CALL_STATE,
  callState,
});

export interface IFetchNearbyLodgings {
  type: actionTypes.FETCH_NEARBY_LODGINGS;
}

export const fetchNearbyLodgings = (): IFetchNearbyLodgings => ({
  type: actionTypes.FETCH_NEARBY_LODGINGS,
});

export interface ISetNearbyLodgings {
  type: actionTypes.SET_NEARBY_LODGINGS;
  hotels: Lodging[];
}

export const setNearbyLodgings = (hotels: Lodging[]): ISetNearbyLodgings => ({
  type: actionTypes.SET_NEARBY_LODGINGS,
  hotels,
});

export interface ISetNearbyLodgingsAvailabilityCallState {
  type: actionTypes.SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE;
  callState: CallState;
}

export const setNearbyLodgingsAvailabilityCallState = (
  callState: CallState
): ISetNearbyLodgingsAvailabilityCallState => ({
  type: actionTypes.SET_NEARBY_LODGINGS_AVAILABILTIY_CALL_STATE,
  callState,
});

export interface IFetchDistanceFromLocationCategories {
  type: actionTypes.FETCH_DISTANCE_FROM_LOCATION_CATEGORIES;
  queryString: string;
}

export const fetchDistanceFromLocationCategories = (
  queryString: string
): IFetchDistanceFromLocationCategories => ({
  type: actionTypes.FETCH_DISTANCE_FROM_LOCATION_CATEGORIES,
  queryString,
});

export interface ISetDistanceFromLocationCategories {
  type: actionTypes.SET_DISTANCE_FROM_LOCATION_CATEGORIES;
  categories: ICategorizedResponse[];
}

export const setDistanceFromLocationCategories = (
  categories: ICategorizedResponse[]
): ISetDistanceFromLocationCategories => ({
  type: actionTypes.SET_DISTANCE_FROM_LOCATION_CATEGORIES,
  categories,
});

export interface ISetDistanceFromLocation {
  type: actionTypes.SET_DISTANCE_FROM_LOCATION;
  location: IResult | null;
}

export const setDistanceFromLocation = (
  location: IResult | null
): ISetDistanceFromLocation => ({
  type: actionTypes.SET_DISTANCE_FROM_LOCATION,
  location,
});

export interface ISetHotelShopEntryPoint {
  type: actionTypes.SET_HOTEL_SHOP_ENTRY_POINT;
  entryPoint: HotelEntryTypeEnum;
}

export const setHotelShopEntryPoint = (
  entryPoint: HotelEntryTypeEnum
): ISetHotelShopEntryPoint => ({
  type: actionTypes.SET_HOTEL_SHOP_ENTRY_POINT,
  entryPoint,
});

export interface ISetHotelDetailsEntrySource {
  type: actionTypes.SET_HOTEL_DETAILS_ENTRY_SOURCE;
  hotelDetailsEntrySource: HotelDetailsEntrySourceEnum;
}

export const setHotelDetailsEntrySource = (
  hotelDetailsEntrySource: HotelDetailsEntrySourceEnum
): ISetHotelDetailsEntrySource => ({
  type: actionTypes.SET_HOTEL_DETAILS_ENTRY_SOURCE,
  hotelDetailsEntrySource,
});

export interface ISetSelectedRoomId {
  type: actionTypes.SET_SELECTED_ROOM_ID;
  selectedRoomId: string;
}

export const setSelectedRoomId = (
  selectedRoomId: string
): ISetSelectedRoomId => ({
  type: actionTypes.SET_SELECTED_ROOM_ID,
  selectedRoomId,
});

export interface ISetHotelShopRecommendedBasedOnPreferences {
  type: actionTypes.SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES;
  recommendedBasedOnPreferences: boolean;
}

export const setHotelShopRecommendedBasedOnPreferences = (
  recommendedBasedOnPreferences: boolean
): ISetHotelShopRecommendedBasedOnPreferences => ({
  type: actionTypes.SET_HOTEL_SHOP_RECOMMENDED_BASED_ON_PREFERENCES,
  recommendedBasedOnPreferences,
});

export interface ISetCacheLodgingTokenResponse {
  type: actionTypes.SET_CACHE_LODGING_TOKEN_RESPONSE;
  lodgingTokenResponse: LodgingTokenResponse;
}

export const setCacheLodgingTokenResponse = (
  lodgingTokenResponse: LodgingTokenResponse
): ISetCacheLodgingTokenResponse => ({
  type: actionTypes.SET_CACHE_LODGING_TOKEN_RESPONSE,
  lodgingTokenResponse,
});

export interface ISetHotelDetailsSortOrder {
  type: actionTypes.SET_HOTEL_DETAILS_SORT_ORDER;
  sortOrder: string | undefined;
}

export const setHotelDetailsSortOrder = (
  sortOrder: string | undefined
): ISetHotelDetailsSortOrder => ({
  type: actionTypes.SET_HOTEL_DETAILS_SORT_ORDER,
  sortOrder,
});

export type HotelShopActions =
  | IFetchHotelShop
  | ISetHotelShopResults
  | ISetHotelShopCallStateFailed
  | ISelectRoomType
  | ISelectLodging
  | IResetLodging
  | ISetDateRange
  | IAcknowledgeHotelShopFailure
  | ISetHotelShopProgress
  | ISetSimilarHotels
  | ISetSimilarHotelsAvailabilityCallState
  | IFetchNearbyLodgings
  | ISetNearbyLodgings
  | ISetNearbyLodgingsAvailabilityCallState
  | IFetchDistanceFromLocationCategories
  | ISetDistanceFromLocationCategories
  | ISetDistanceFromLocation
  | ISetHotelShopEntryPoint
  | ISetSelectedRoomId
  | ISetHotelShopRecommendedBasedOnPreferences
  | ISetHotelDetailsEntrySource
  | ISetCacheLodgingTokenResponse
  | ISetHotelDetailsSortOrder;
