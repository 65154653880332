import React from "react";
import { StayType } from "redmond/hotels-module/interfaces";
import { Box } from "@material-ui/core";
import styles from "./styles.module.scss";
import { StayTypeButtonGroup } from "./components/StayTypeButtonGroup";
import { StayTypeFilterValue } from "./common";

export interface StayTypeFilterControlProps {
  stayTypes: StayType[];
  setStayTypeFilter: (stayTypes: StayType[]) => void;
}

export const StayTypeFilterControl = ({
  stayTypes,
  setStayTypeFilter,
}: StayTypeFilterControlProps) => {
  const selectedStayTypesSet = new Set(stayTypes);

  const selectedStayTypeFilterValue: StayTypeFilterValue =
    selectedStayTypesSet.size === 0 || selectedStayTypesSet.size == 2
      ? "All"
      : stayTypes[0];

  const handleChange = (stayType: StayTypeFilterValue) => {
    const newStayTypes = stayType === "All" ? [] : [stayType];
    setStayTypeFilter(newStayTypes);
  };

  return (
    <Box className={styles["container"]}>
      <StayTypeButtonGroup
        selectedStayType={selectedStayTypeFilterValue}
        onChange={handleChange}
      />
    </Box>
  );
};
