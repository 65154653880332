// Getters
export const FETCH_USER_PASSENGERS = "hotelBook/FETCH_USER_PASSENGERS";
export type FETCH_USER_PASSENGERS = typeof FETCH_USER_PASSENGERS;

export const LIST_PAYMENT_METHODS = "hotelBook/LIST_PAYMENT_METHODS";
export type LIST_PAYMENT_METHODS = typeof LIST_PAYMENT_METHODS;

export const FETCH_PAYMENT_METHOD = "hotelBook/FETCH_PAYMENT_METHOD";
export type FETCH_PAYMENT_METHOD = typeof FETCH_PAYMENT_METHOD;

// Setters
export const UPDATE_USER_PASSENGER = "hotelBook/UPDATE_USER_PASSENGER";
export type UPDATE_USER_PASSENGER = typeof UPDATE_USER_PASSENGER;

export const DELETE_USER_PASSENGER = "hotelBook/DELETE_USER_PASSENGER";
export type DELETE_USER_PASSENGER = typeof DELETE_USER_PASSENGER;

export const SET_USER_PASSENGERS = "hotelBook/SET_USER_PASSENGERS";
export type SET_USER_PASSENGERS = typeof SET_USER_PASSENGERS;

export const SET_USER_SELECTED_PASSENGER_IDS =
  "hotelBook/SET_USER_SELECTED_PASSENGER_IDS";
export type SET_USER_SELECTED_PASSENGER_IDS =
  typeof SET_USER_SELECTED_PASSENGER_IDS;

export const SET_USER_PASSENGERS_CALL_STATE_FAILED =
  "hotelBook/SET_USER_PASSENGERS_CALL_STATE_FAILED";
export type SET_USER_PASSENGERS_CALL_STATE_FAILED =
  typeof SET_USER_PASSENGERS_CALL_STATE_FAILED;

export const ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  "hotelBook/ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE";
export type ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE =
  typeof ACKNOWLEDGE_UPDATE_USER_PASSENGER_FAILURE;

export const SET_CONTACT_INFO = "hotelBook/SET_CONTACT_INFO";
export type SET_CONTACT_INFO = typeof SET_CONTACT_INFO;

export const SET_PAYMENT_METHODS = "hotelBook/SET_PAYMENT_METHODS";
export type SET_PAYMENT_METHODS = typeof SET_PAYMENT_METHODS;

export const SET_PAYMENT_METHOD = "hotelBook/SET_PAYMENT_METHOD";
export type SET_PAYMENT_METHOD = typeof SET_PAYMENT_METHOD;

export const SET_SELECTED_PAYMENT_METHOD_ID =
  "hotelBook/SET_SELECTED_PAYMENT_METHOD_ID";
export type SET_SELECTED_PAYMENT_METHOD_ID =
  typeof SET_SELECTED_PAYMENT_METHOD_ID;

export const VERIFY_PAYMENT_METHOD = "hotelBook/VERIFY_PAYMENT_METHOD";
export type VERIFY_PAYMENT_METHOD = typeof VERIFY_PAYMENT_METHOD;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  "hotelBook/VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_FAILED;

export const VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "hotelBook/VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof VERIFY_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const DELETE_PAYMENT_METHOD = "hotelBook/DELETE_PAYMENT_METHOD";
export type DELETE_PAYMENT_METHOD = typeof DELETE_PAYMENT_METHOD;

export const DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  "hotelBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type DELETE_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_FAILED;

export const DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  "hotelBook/DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS";
export type DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS =
  typeof DELETE_PAYMENT_METHOD_CALL_STATE_SUCCESS;

export const SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  "hotelBook/SET_PAYMENT_METHOD_CALL_STATE_FAILED";
export type SET_PAYMENT_METHOD_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHOD_CALL_STATE_FAILED;

export const SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  "hotelBook/SET_PAYMENT_METHODS_CALL_STATE_FAILED";
export type SET_PAYMENT_METHODS_CALL_STATE_FAILED =
  typeof SET_PAYMENT_METHODS_CALL_STATE_FAILED;

export const CHECK_SESSION = "hotelBook/CHECK_SESSION";
export type CHECK_SESSION = typeof CHECK_SESSION;

export const CHECK_SESSION_CALL_STATE_FAILED =
  "hotelBook/CHECK_SESSION_CALL_STATE_FAILED";
export type CHECK_SESSION_CALL_STATE_FAILED =
  typeof CHECK_SESSION_CALL_STATE_FAILED;

export const SET_SESSION = "hotelBook/SET_SESSION";
export type SET_SESSION = typeof SET_SESSION;

export const CLEAR_SESSION = "hotelBook/CLEAR_SESSION";
export type CLEAR_SESSION = typeof CLEAR_SESSION;

export const CLOSE_SESSION = "hotelBook/CLOSE_SESSION";
export type CLOSE_SESSION = typeof CLOSE_SESSION;

export const SCHEDULE_PRICE_QUOTE = "hotelBook/SCHEDULE_PRICE_QUOTE";
export type SCHEDULE_PRICE_QUOTE = typeof SCHEDULE_PRICE_QUOTE;

export const SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "hotelBook/SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SCHEDULE_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED =
  "hotelBook/SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED";
export type SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SCHEDULE_PRICE_QUOTE_CALL_STATE_FAILED;

export const POLL_PRICE_QUOTE = "hotelBook/POLL_PRICE_QUOTE";
export type POLL_PRICE_QUOTE = typeof POLL_PRICE_QUOTE;

export const SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  "hotelBook/SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS";
export type SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS =
  typeof SET_POLL_PRICE_QUOTE_CALL_STATE_SUCCESS;

export const SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED =
  "hotelBook/SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED";
export type SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED =
  typeof SET_POLL_PRICE_QUOTE_CALL_STATE_FAILED;

export const SET_PRICE_QUOTE = "hotelBook/SET_PRICE_QUOTE";
export type SET_PRICE_QUOTE = typeof SET_PRICE_QUOTE;

export const POLL_PASSENGER_VALIDATION = "hotelBook/POLL_PASSENGER_VALIDATION";
export type POLL_PASSENGER_VALIDATION = typeof POLL_PASSENGER_VALIDATION;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  "hotelBook/POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_SUCCESS;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED =
  "hotelBook/POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_FAILED;

export const POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING =
  "hotelBook/POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING";
export type POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING =
  typeof POLL_PASSENGER_VALIDATION_CALL_STATE_PENDING;

export const SET_PASSENGERS_VALID = "hotelBook/SET_PASSENGERS_VALID";
export type SET_PASSENGERS_VALID = typeof SET_PASSENGERS_VALID;

export const SET_PASSENGERS_INVALID = "hotelBook/SET_PASSENGERS_INVALID";
export type SET_PASSENGERS_INVALID = typeof SET_PASSENGERS_INVALID;

export const SCHEDULE_PURCHASE = "hotelBook/SCHEDULE_PURCHASE";
export type SCHEDULE_PURCHASE = typeof SCHEDULE_PURCHASE;

export const SET_SCHEDULE_PURCHASE_SUCCESS =
  "hotelBook/SET_SCHEDULE_PURCHASE_SUCCESS";
export type SET_SCHEDULE_PURCHASE_SUCCESS =
  typeof SET_SCHEDULE_PURCHASE_SUCCESS;

export const SET_SCHEDULE_PURCHASE_FAILED =
  "hotelBook/SET_SCHEDULE_PURCHASE_FAILED";
export type SET_SCHEDULE_PURCHASE_FAILED = typeof SET_SCHEDULE_PURCHASE_FAILED;

export const SCHEDULE_BOOK = "hotelBook/SCHEDULE_BOOK";
export type SCHEDULE_BOOK = typeof SCHEDULE_BOOK;

export const SET_SCHEDULE_BOOK_SUCCESS = "hotelBook/SET_SCHEDULE_BOOK_SUCCESS";
export type SET_SCHEDULE_BOOK_SUCCESS = typeof SET_SCHEDULE_BOOK_SUCCESS;

export const SET_SCHEDULE_BOOK_FAILED = "hotelBook/SET_SCHEDULE_BOOK_FAILED";
export type SET_SCHEDULE_BOOK_FAILED = typeof SET_SCHEDULE_BOOK_FAILED;

export const POLL_CONFIRMATION_DETAILS = "hotelBook/POLL_CONFIRMATION_DETAILS";
export type POLL_CONFIRMATION_DETAILS = typeof POLL_CONFIRMATION_DETAILS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  "hotelBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_SUCCESS;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  "hotelBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_FAILED;

export const POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  "hotelBook/POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING";
export type POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING =
  typeof POLL_CONFIRMATION_DETAILS_CALL_STATE_PENDING;

export const SET_CONFIRMATION_DETAILS = "hotelBook/SET_CONFIRMATION_DETAILS";
export type SET_CONFIRMATION_DETAILS = typeof SET_CONFIRMATION_DETAILS;

export const POLL_FINALIZED = "hotelBook/POLL_FINALIZED";
export type POLL_FINALIZED = typeof POLL_FINALIZED;

export const POLL_FINALIZED_CALL_STATE_SUCCESS =
  "hotelBook/POLL_FINALIZED_CALL_STATE_SUCCESS";
export type POLL_FINALIZED_CALL_STATE_SUCCESS =
  typeof POLL_FINALIZED_CALL_STATE_SUCCESS;

export const POLL_FINALIZED_CALL_STATE_FAILED =
  "hotelBook/POLL_FINALIZED_CALL_STATE_FAILED";
export type POLL_FINALIZED_CALL_STATE_FAILED =
  typeof POLL_FINALIZED_CALL_STATE_FAILED;

export const POLL_FINALIZED_CALL_STATE_PENDING =
  "hotelBook/POLL_FINALIZED_CALL_STATE_PENDING";
export type POLL_FINALIZED_CALL_STATE_PENDING =
  typeof POLL_FINALIZED_CALL_STATE_PENDING;

export const SET_FINALIZED = "hotelBook/SET_FINALIZED";
export type SET_FINALIZED = typeof SET_FINALIZED;

export const RESET_BOOK = "hotelBook/RESET_BOOK";
export type RESET_BOOK = typeof RESET_BOOK;

export const ACKNOWLEDGE_PRICE_DIFFERENCE =
  "hotelBook/ACKNOWLEDGE_PRICE_DIFFERENCE";
export type ACKNOWLEDGE_PRICE_DIFFERENCE = typeof ACKNOWLEDGE_PRICE_DIFFERENCE;

export const POPULATE_HOTEL_BOOK_QUERY_PARAMS =
  "hotelBook/POPULATE_HOTEL_BOOK_QUERY_PARAMS";
export type POPULATE_HOTEL_BOOK_QUERY_PARAMS =
  typeof POPULATE_HOTEL_BOOK_QUERY_PARAMS;

export const SET_HOTEL_BOOK_QUERY_PARAMS =
  "hotelBook/SET_HOTEL_BOOK_QUERY_PARAMS";
export type SET_HOTEL_BOOK_QUERY_PARAMS = typeof SET_HOTEL_BOOK_QUERY_PARAMS;

export const SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  "hotelBook/SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID";
export type SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID =
  typeof SET_SELECTED_REWARDS_ACCOUNT_REFERENCE_ID;

export const SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  "hotelBook/SET_SELECTED_REWARDS_PAYMENT_TOTAL";
export type SET_SELECTED_REWARDS_PAYMENT_TOTAL =
  typeof SET_SELECTED_REWARDS_PAYMENT_TOTAL;

export const FETCH_PRODUCT_TO_EARN = "hotelBook/FETCH_PRODUCT_TO_EARN";
export type FETCH_PRODUCT_TO_EARN = typeof FETCH_PRODUCT_TO_EARN;

export const SET_PRODUCT_EARN_VALUE = "hotelBook/SET_PRODUCT_EARN_VALUE";
export type SET_PRODUCT_EARN_VALUE = typeof SET_PRODUCT_EARN_VALUE;

export const FETCH_ALL_EARN_FOR_PRODUCT =
  "hotelBook/FETCH_ALL_EARN_FOR_PRODUCT";
export type FETCH_ALL_EARN_FOR_PRODUCT = typeof FETCH_ALL_EARN_FOR_PRODUCT;

export const SET_ALL_EARN_VALUES = "hotelBook/SET_ALL_EARN_VALUES";
export type SET_ALL_EARN_VALUES = typeof SET_ALL_EARN_VALUES;

export const REWARDS_CONVERSION_FAILED = "hotelBook/REWARDS_CONVERSION_FAILED";
export type REWARDS_CONVERSION_FAILED = typeof REWARDS_CONVERSION_FAILED;

export const RESET_BOOK_ERRORS = "hotelBook/RESET_BOOK_ERRORS";
export type RESET_BOOK_ERRORS = typeof RESET_BOOK_ERRORS;

export const SET_OFFER_TO_APPLY = "hotelBook/SET_OFFER_TO_APPLY";
export type SET_OFFER_TO_APPLY = typeof SET_OFFER_TO_APPLY;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  "hotelBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  "hotelBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_SUCCESS;

export const FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  "hotelBook/FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE";
export type FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE =
  typeof FETCH_APPLICABLE_TRAVEL_WALLET_ITEMS_CALL_STATE_FAILURE;

export const SET_TRAVEL_WALLET_OFFERS = "hotelBook/SET_TRAVEL_WALLET_OFFERS";
export type SET_TRAVEL_WALLET_OFFERS = typeof SET_TRAVEL_WALLET_OFFERS;

export const SET_BEST_OFFER_OVERALL = "hotelBook/SET_BEST_OFFER_OVERALL";
export type SET_BEST_OFFER_OVERALL = typeof SET_BEST_OFFER_OVERALL;

export const SET_TRAVEL_WALLET_CREDIT = "hotelBook/SET_TRAVEL_WALLET_CREDIT";
export type SET_TRAVEL_WALLET_CREDIT = typeof SET_TRAVEL_WALLET_CREDIT;

export const SET_CREDIT_TO_APPLY = "hotelBook/SET_CREDIT_TO_APPLY";
export type SET_CREDIT_TO_APPLY = typeof SET_CREDIT_TO_APPLY;

export const RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  "hotelBook/RESET_PAYMENT_CARD_SELECTED_ACCOUNTS";
export type RESET_PAYMENT_CARD_SELECTED_ACCOUNTS =
  typeof RESET_PAYMENT_CARD_SELECTED_ACCOUNTS;

export const SET_HOTEL_BOOK_TYPE = "hotelBook/SET_HOTEL_BOOK_TYPE";
export type SET_HOTEL_BOOK_TYPE = typeof SET_HOTEL_BOOK_TYPE;

export const SET_PRICE_QUOTE_REQUEST = "hotelBook/SET_PRICE_QUOTE_REQUEST";
export type SET_PRICE_QUOTE_REQUEST = typeof SET_PRICE_QUOTE_REQUEST;

export const FETCH_CORP_USER_PASSENGERS =
  "hotelBook/FETCH_CORP_USER_PASSENGERS";
export type FETCH_CORP_USER_PASSENGERS = typeof FETCH_CORP_USER_PASSENGERS;

export const SET_USER_SELECTED_TRAVELERS_LIST =
  "hotelBook/SET_USER_SELECTED_TRAVELERS_LIST";
export type SET_USER_SELECTED_TRAVELERS_LIST =
  typeof SET_USER_SELECTED_TRAVELERS_LIST;

export const UPDATE_USER_PASSENGERS_MULTIPLE =
  "hotelBook/UPDATE_USER_PASSENGERS_MULTIPLE";
export type UPDATE_USER_PASSENGERS_MULTIPLE =
  typeof UPDATE_USER_PASSENGERS_MULTIPLE;

export const SET_USER_SELECTED_PASSENGER_IDS_MULTIPLE =
  "hotelBook/SET_USER_SELECTED_PASSENGER_IDS_MULTIPLE";
export type SET_USER_SELECTED_PASSENGER_IDS_MULTIPLE =
  typeof SET_USER_SELECTED_PASSENGER_IDS_MULTIPLE;

export const SET_TRIP_PURPOSE = "hotelBook/SET_TRIP_PURPOSE";
export type SET_TRIP_PURPOSE = typeof SET_TRIP_PURPOSE;
